<div class="width-100" [class.additional-margin]="isExpandable && lines > 1">
  <sh-check [checked]="value" [disabled]="disabled" (click)="checkbox.click()"></sh-check>
  <label>
    <input
      type="checkbox"
      class="form-checkbox"
      [name]="id"
      [disabled]="disabled"
      [(ngModel)]="checkboxValue"
      [required]="required"
      #checkbox
    />
    <span
      #textElement
      class="form-checkbox-text"
      [innerHTML]="text"
      [ngClass]="{ required: required, collapsed: isCollapsed && isExpandable && lines > 2 }"
    ></span>
    <span class="required-info" *ngIf="isCollapsed && required && lines > 2">*</span>
  </label>
  <span *ngIf="isExpandable && lines > 2" class="toggle-link" (click)="onExpandClick()">
    {{
      isCollapsed
        ? (translationPrefix + 'CHECKBOX.EXPAND' | translate)
        : (translationPrefix + 'CHECKBOX.COLLAPSE' | translate)
    }}
  </span>
</div>
