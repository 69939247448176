import { IGuest } from '@smarttypes/hotel';
import { get } from 'lodash';

export function isGuestFromPMS(guest?: IGuest, pms?: string): boolean {
  if (!guest?.isFromPMS) {
    return false;
  }
  const source = get(guest, 'initialValueFrom', get(guest, 'initialValue.initialValueFrom', ''));
  return source === pms;
}
