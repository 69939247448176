import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { DropdownPosition } from '@ng-select/ng-select/lib/ng-select.types';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { get } from 'lodash';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';
import { Subject } from 'rxjs';

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, AngularSvgIconModule, TranslateModule, NgxTippyModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, OnChanges {
  @Input() items: unknown[] = [];
  @Input() bindValue!: string;
  @Input() bindLabel!: string;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() trackByFn!: () => void;
  @Input() change!: () => void;
  @Input() loading = false;
  @Input() searchable = false;
  @Input() searchInList = false;
  @Input() typeToSearchText!: string;
  @Input() typeahead!: Subject<string>;
  @Input() groupBy = '';
  @Input() placeholder!: string;
  @Input() maxSelectedItems!: number;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() readonly = false;
  @Input() dropdownPosition: DropdownPosition = 'bottom';
  @Input() icon = '';
  @Input() type = 'ng-select-pill';
  @Input() arrow = true;
  @HostBinding('class.disabled') @Input() disabled = false;
  @Input() locked = false;
  @Input() addTag: boolean | ((term: string) => any | Promise<any>) = false;
  @Input() clearSelected = false;
  @Input() virtualScroll = false;
  @Input() minTermLength = 1;
  @Input() differentDisplayName: string | undefined;
  @Input() useImgInsteadSvgIcon = false;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;
  @ViewChild('select', { static: true }) select!: NgSelectComponent;
  @Output() loadMore: EventEmitter<void> = new EventEmitter();
  private isRegister = false;
  tooltipSettings: NgxTippyProps = {
    duration: [500, 500],
    offset: [0, 8],
    animation: 'scale',
    placement: 'bottom-start',
    theme: 'light',
  };

  constructor(private injector: Injector) {}

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locked']) {
      this.setDisabledState(changes['locked']?.currentValue);
    }
  }

  onItemClick(item: string | unknown) {
    if (item === this.formControlDirective.value || get(item, this.bindValue, '') === this.formControlDirective.value) {
      setTimeout(() => {
        this.select.handleClearClick();
      }, 100);
    }
  }

  onFilter(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (this.typeahead) {
      this.typeahead.next(value);
    } else {
      this.select.filter(value);
    }
  }

  registerOnTouched(fn: unknown): void {
    this.formControlDirective.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: unknown): void {
    this.isRegister = true;
    this.formControlDirective.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: unknown): void {
    if (this.isRegister) {
      this.formControlDirective.valueAccessor?.writeValue(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
    this.disabled = isDisabled;
  }

  onScrollEnd() {
    this.loadMore.emit();
  }

  openSelect() {
    this.select.open();
  }
}
