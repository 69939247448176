<sh-form>
  <label>{{ isProperty ? ('SH.FORM_AVATAR.PROPERTY_LABEL' | translate) : ('SH.FORM_AVATAR.LABEL' | translate) }}</label>
  <div class="picture">
    <ui-avatar size="xl" initials="S" [object]="avatar" type="initialsUser" [rounded]="avatarRounded"></ui-avatar>
    <div class="picture-text">
      <div class="actions" *ngIf="avatar; else noAvatar">
        <span class="link" uiFileLibrary [library]="library" (fileChange)="onChange($event)">{{
            isProperty ? ('SH.FORM_AVATAR.CHANGE_LOGO' | translate) : ('SH.FORM_AVATAR.CHANGE_PHOTO' | translate)
          }}</span>
        <span class="separator">•</span>
        <span class="link" (click)="onRemove()">{{
            isProperty ? ('SH.FORM_AVATAR.REMOVE_LOGO' | translate) : ('SH.FORM_AVATAR.REMOVE_PHOTO' | translate)
          }}</span>
      </div>
      <ng-template #noAvatar>
        <h6 uiFileLibrary folder="Avatars" [library]="library" (fileChange)="onChange($event)">
          {{ isProperty ? ('SH.FORM_AVATAR.ADD_LOGO' | translate) : ('SH.FORM_AVATAR.ADD_PHOTO' | translate) }}
        </h6>
      </ng-template>
      <p *ngIf="!isProperty">{{ 'SH.FORM_AVATAR.DESCRIPTION' | translate }}.</p>
    </div>
  </div>
</sh-form>
