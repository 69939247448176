import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ApiValidatorService } from '@smarthotel/angular-services';
import { showErrorAnimation } from 'angular-v2-animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sh-error-message',
  templateUrl: './error-message.component.html',
  animations: [showErrorAnimation],
})
export class ErrorMessageComponent implements OnChanges, OnInit, OnDestroy {
  @Input() control?: AbstractControl | null;
  @Input() apiError?: any;
  @Input() required!: string;
  @Input() pattern!: string;
  @Input() message?: string;
  @Input() prefix: 'GA' | 'SH' = 'SH';
  private controlSubscribe!: Subscription;

  constructor() {}

  get showError() {
    return this.control?.dirty;
  }

  get errorMessage() {
    if (this.message) {
      return this.message;
    }
    if (this.control) {
      for (const key in this.control.errors) {
        if (this.control.errors[key] && this.showError && key !== 'apiError') {
          return ApiValidatorService.getValidationMessage(key, this.control.errors[key], this.prefix) || key;
        }
      }
    }
    if (Array.isArray(this.apiError)) {
      return this.apiError.join(`\n`);
    }
    if (this.apiError) {
      return this.apiError?.message || this.apiError;
    }
    return null;
  }

  ngOnDestroy() {
    if (this.controlSubscribe) {
      this.controlSubscribe.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.control) {
      this.controlSubscribe = this.control.valueChanges.subscribe(() => {
        if (this.apiError) {
          delete this.apiError;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['apiError'] && changes['apiError'].currentValue && this.control) {
      this.control.setErrors({
        apiError: true,
      });
      this.control.markAsDirty();
    }
  }
}
