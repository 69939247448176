import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'wg-qr-materials-bar',
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
  templateUrl: './qr-materials-bar.component.html',
  styleUrls: ['./qr-materials-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrMaterialsBarComponent {
  constructor(private router: Router) {}

  @HostListener('click') onClick() {
    this.router.navigate(['/qr-materials']);
    return false;
  }
}
