import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGuest, IReservation } from '@smarttypes/hotel';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsModalService } from 'ngx-bootstrap/modal';

import { MyProfileModalComponent } from './my-profile-modal/my-profile-modal.component';

@Component({
  selector: 'ui-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
})
export class MyProfileComponent {
  @Input() guest?: IGuest;
  @Output() buttonClick = new EventEmitter();
  reservations?: IReservation[];

  constructor(private modalService: BsModalService) {}

  onButtonClick(): void {
    this.buttonClick.emit();
    this.modalService.show(MyProfileModalComponent, {
      initialState: {
        guestName: this.guest?.person?.firstName,
        reservations: this.guest?.reservations,
      },
      class: 'modal-dialog-slideout',
    });
  }
}
