import { animate, style, transition, trigger } from '@angular/animations';

export const showNotificationAnimation = trigger('showNotification', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-52px)',
      overflow: 'hidden',
    }),
    animate(
      `.3s ease`,
      style({
        overflow: 'hidden',
        opacity: 1,
        transform: 'translateY(0px)',
      }),
    ),
  ]),
]);
