<div
  [class.checked]="option.value === value"
  [class.disabled]="disabled"
  class="radio-item"
  *ngFor="let option of values"
>
  <label>
    <input
      type="radio"
      [name]="name"
      [value]="option.value"
      [ngModel]="value"
      (ngModelChange)="valueChange($event)"
      class="sh-radio"
    />
    <span>{{ option.label | translate }}</span>
  </label>
</div>
