<div
  class="d-flex justify-content-center drag-file"
  [ngClass]="{ 'file-over': fileOver }"
  (dragover)="dragLeaveOver($event, true)"
  (dragleave)="dragLeaveOver($event, false)"
  (drop)="drop($event)"
  [ngStyle]="{ height: height }"
>
  <div class="drap-file-placeholder">
    <svg-icon src="/assets/icons/icons-upload.svg"></svg-icon>
    <h5>{{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.DRAG_N_DROP' | translate }}</h5>
    <p>
      {{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.OR' | translate }}
      <span (click)="fileInput.click()">{{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.BROWSE' | translate }}</span>
      {{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.TO_CHOOSE_A_FILE' | translate }}
    </p>
    <span
      >({{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.MAX_FILE_SIZE' | translate }} {{ maxFileSize | filesize }} ,
      {{ 'SH.FRONTEND_LIB.FILE_DROP_DOWN.ALLOWED_FILES' | translate }}: {{ allowedFiles }} )</span
    >
  </div>
  <input
    [accept]="acceptFiles.join(',')"
    #fileInput
    type="file"
    (change)="fileChangeEvent($event)"
    style="display: none"
  />
</div>
