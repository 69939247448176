<div class="toast" [class.is-expanded]="isExpanded" (click)="onClick()">
  <span>
    <svg-icon [src]="'/assets/icons/' + icon + '.svg'"></svg-icon>
    <span class="label" *ngIf="label" [innerHTML]="label"></span>
    <div class="labels" [style.max-height.px]="maxHeight" *ngIf="labels?.length">
      <span *ngFor="let label of labels" [innerHTML]="label"></span>
    </div>
  </span>
  <ui-button-pill *ngIf="buttonText" (click)="onButtonClick()" [type]="buttonType" [text]="buttonText"></ui-button-pill>
</div>
