import { Component, HostBinding, HostListener, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { SmartLoggerService, WindowService } from '@smarthotel/angular-services';
import { getDisplayNameSignature } from '@smarthotel/utils';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { fadeAnimation } from 'angular-v2-animations';
import { untilDestroyed } from 'angular-v2-utils';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, map, Subscription, tap, throwError } from 'rxjs';
import { catchError, delay, filter, finalize, switchMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AccountService } from './core/services/account.service';
import { AuthService } from './core/services/auth.service';
import { CompanyService } from './core/services/company.service';
import { PushNotificationsService } from './core/services/push-notifications.service';

@Component({
  selector: 'sh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  private gtmService?: GoogleTagManagerService;
  private subscriptions: Subscription = new Subscription();
  private innerHeight!: number;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerHeight = this.windowService.innerHeight ?? 0;
    this.appHeight();
  }

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly toast: ToastrService,
    private readonly accountService: AccountService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly injector: Injector,
    private readonly windowService: WindowService,
    private readonly logger: SmartLoggerService,
    @Optional() private readonly push: PushNotificationsService,
  ) {}

  @HostBinding('class')
  get releaseName(): string {
    return environment.releaseName;
  }

  public getRouterOutletState(outlet: RouterOutlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public ngOnInit(): void {
    this.innerHeight = this.windowService.innerHeight ?? 0;
    this.appHeight();
    if (this.push) {
      setTimeout(() => {
        try {
          this.push.init();
        } catch (err) {
          this.logger.error(err);
        }
      }, 20000);
    }

    if (environment.sentryDns) {
      this.authService.$loggedIn
        .pipe(
          filter(e => e),
          switchMap(() => forkJoin([this.accountService.$currentUser(), this.companyService.$currentUser()])),
        )
        .subscribe(([user, company]) => {
          Sentry.setUser({
            id: user._id as string,
            email: user.email,
            username: getDisplayNameSignature(user),
          });
          Sentry.setContext('company', {
            id: company._id as string,
            name: company.name,
          });
        });
    }

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute.snapshot.queryParamMap.get('token') as string),
          filter(t => !!t),
          switchMap(token => {
            return this.accountService.verifyTokenEmail(token).pipe(
              finalize(() => {
                sessionStorage.removeItem('token-verify');
                this.toast.success(`${this.translateService.instant('SH.ACCOUNT.ACCOUNT_ACTIVATED')}`);
              }),
              delay(1000),
              tap(() => {
                window.location.assign('/');
              }),
              catchError(err => {
                this.toast.error(`${this.translateService.instant('SH.ACCOUNT.INVALID_VERIFICATION_CODE')}`);
                return throwError(() => err);
              }),
            );
          }),
          untilDestroyed(this),
        )
        .subscribe(),
    );

    if (environment?.gtmId) {
      this.loadGtmService();
      this.gtmService?.addGtmToDom().catch(() => {});
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private loadGtmService() {
    this.gtmService = this.injector.get(GoogleTagManagerService);
  }

  private appHeight(): void {
    const doc = this.windowService.document?.documentElement;
    doc?.style.setProperty('--app-height', `${this.innerHeight}px`);
  }
}
