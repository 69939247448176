<div class="percentage-bar" *ngIf="hasPercentages && !isLoading">
  <ng-container *ngFor="let item of data">
    <div
      [attr.data-channel]="item.channel"
      #percentItem
      class="item"
      *ngIf="(item?.percent || 0) > 0"
      [style.width.%]="item.percent"
      [style.background-color]="item.color"
    >
      <span> {{ item.percent }}% </span>
    </div>
  </ng-container>
</div>
<ngx-skeleton-loader
  *ngIf="isLoading"
  count="1"
  appearance="line"
  [theme]="{ width: '606px', height: '20px', marginBottom: '0', borderRadius: '10px' }"
></ngx-skeleton-loader>
