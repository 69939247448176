import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ConfirmModelText {
  header?: string;
  body?: string;
  submit: string;
  submit2?: string;
  cancel?: string;
  image?: string;
  btnClass?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  hint?: string;
}

@Component({
  selector: 'sh-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  @HostBinding('class.modal-info') modalInfo = true;
  @Input() text!: ConfirmModelText;
  @Input() confirmFunc!: () => void;
  @Input() confirmFunc2nd!: () => void;
  @Input() cancelFunc!: () => void;
  private cfgDefault: ConfirmModelText = {
    header: 'SH.MODAL_CONFIRM.HEADER',
    body: 'SH.MODAL_CONFIRM.TEXT',
    submit: '',
    submit2: '',
    cancel: 'SH.MODAL_CONFIRM.CANCEL',
    image: '',
    hint: '',
    btnClass: 'primary',
  };
  cfg!: ConfirmModelText;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.cfg = Object.assign({}, this.cfgDefault, this.text);
  }

  onConfirmClick() {
    if (this.confirmFunc) {
      this.confirmFunc();
      this.bsModalRef.hide();
    }
  }

  onConfirm2Click() {
    if (this.confirmFunc2nd) {
      this.confirmFunc2nd();
      this.bsModalRef.hide();
    }
  }

  onCancelClick() {
    if (this.cancelFunc) {
      this.cancelFunc();
      this.bsModalRef.hide();
    } else {
      this.bsModalRef.hide();
    }
  }
}
