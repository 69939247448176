import { CommunicationChannelEnum, ICommunicationChannelId } from '@smarttypes/core';
import { get } from 'lodash';

export function getCommunicationChannel(
  obj: unknown,
  channel: CommunicationChannelEnum,
  path = 'communication.channelIds',
): ICommunicationChannelId | undefined {
  return (get(obj, path, []) as ICommunicationChannelId[]).find(c => c.channel === channel);
}
