import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ILanguage } from '@smarttypes/core';
import { FlagIconComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    AngularSvgIconModule,
    FlagIconComponent,
    TranslateModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLanguageComponent),
      multi: true,
    },
  ],
})
export class SelectLanguageComponent implements ControlValueAccessor, OnInit {
  @Input() languages: ILanguage[] = [];
  @Input() disabled = false;
  @Input() newLanguage = false;
  @Input() formControl?: FormControl;
  @Input() side: 'left' | 'right' = 'left';
  @Input() color: 'white' | 'gray' = 'white';
  @Input() formControlName?: string;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective;
  @Output() addLang = new EventEmitter();
  value: string | null = null;
  private isRegister = false;

  constructor(private injector: Injector) {}

  get control(): FormControl {
    return (this.formControl as FormControl) || this.controlContainer.control?.get(this.formControlName as string);
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  @HostBinding('class')
  get classes(): string {
    return `${this.side} ${this.color}`;
  }

  ngOnInit() {
    if (this.disabled) {
      this.setDisabledState(true);
    }
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.isRegister = true;
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    if (this.isRegister) {
      this.formControlDirective?.valueAccessor?.writeValue(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
  }
}
