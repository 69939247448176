import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export type AvatarSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type AvatarType = 'initials' | 'photo' | 'initialsUser' | 'white';

@Component({
  selector: 'ui-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() size: AvatarSize = 'm';
  @Input() type: AvatarType = 'initials';
  @Input() object?: any;
  @Input() key = 'avatar';
  @Input() shadow = false;
  @Input() rounded = false;
  @Input() initials?: string;
  @Input() color?: string;

  get sizeClass(): string {
    return `size-${this.size}`;
  }

  get backgroundClass(): string {
    return `background-${this.type}`;
  }

  get hasShadow(): string {
    return this.shadow ? 'has-shadow' : '';
  }

  get isRounded(): string {
    return this.rounded ? 'circle' : '';
  }

  get isSmart(): boolean {
    // @TODO move isSmart flag to backend
    return (
      !!this.object?.reservation?.registrationCardId ||
      !!this.object?.communication?.lastIncomingMessage ||
      (this.object?.parentId && !!this.object?.communication?.lastIncomingMessage)
    );
  }

  get avatarClass(): string {
    if (this.color) {
      return this.color;
    } else {
      return this.isSmart ? this.object?.avatar?.color || '' : '';
    }
  }

  getImagePath(): string | undefined {
    let path!: string;

    if (typeof this.object === 'string' && this.isUrl()) {
      path = this.object;
    } else if (this.object?.avatar?.avatar) {
      path = this.object?.avatar?.avatar;
    }
    // workaround for guests and chat guests - DO not show Avatars
    if (this.object?.room || this.object?.channels || this.object?.direction) {
      // this.initials = 2;
      return;
    }

    return path;
  }

  getName(): string {
    let name!: string;

    if (typeof this.object === 'string') {
      name = this.object;
    } else if (this.object?.guest?.person?.firstName) {
      name = this.object?.guest?.person?.firstName.trim();
      if (this.object?.guest?.person?.lastName) {
        name = `${name} ${this.object?.guest?.person?.lastName.trim()}`;
      }
    } else if (this.object?.guest?.person?.lastName) {
      name = this.object?.guest?.person?.lastName.trim();
    } else if (this.object?.firstName) {
      name = this.object?.firstName.trim();
      if (this.object?.lastName) {
        name = `${name} ${this.object?.lastName.trim()}`;
      }
    } else if (this.object?.lastName) {
      name = this.object?.lastName.trim();
    } else if (this.object?.name) {
      name = this.object?.name.trim();
    } else if (this.object?.person?.firstName) {
      name = this.object?.person?.firstName.trim();
      if (this.object?.person?.lastName) {
        name = `${name} ${this.object?.person?.lastName.trim()}`;
      }
    } else if (this.object?.person?.lastName) {
      name = this.object?.person?.lastName.trim();
    } else if (this.object?.displayName) {
      name = this.object?.displayName.trim();
    }
    return name;
  }

  getInitials(): string {
    let initialsLength = 1;

    if (this.type === 'initials' && !(this.size === 'xs' || this.size === 's')) {
      initialsLength = 2;
    }

    if (this.initials) {
      return this.initials;
    }

    if (this.getName()?.split(' ')?.length) {
      return this.getName()
        .split(' ')
        .slice(0, initialsLength)
        .map(s => s.slice(0, 1))
        .join('');
    }

    return this.getName();
  }

  private isUrl(): boolean {
    return this.object?.startsWith('https://') || this.object?.startsWith('http://');
  }
}
