export enum MessageItemMessageTypeEnum {
  text = 'text',
  video = 'video',
  image = 'image',
  audio = 'audio',
  location = 'location',
  file = 'file',
  template = 'template',
  fallback = 'fallback',
  custom = 'custom',
  unknown = 'unknown',
  airbnbGuestReviewForm = 'airbnbGuestReviewForm',
  airbnbHostReviewForm = 'airbnbHostReviewForm',
  bookingComGuestReview = 'bookingComGuestReview',
  reviewResponse = 'reviewResponse',
  payment = 'payment',
  assignment = 'assignment',
  request = 'request',
  CTA = 'CTA',
  interactive = 'interactive',
}
