<div class="checkbox" *ngFor="let item of values | slice : 0 : (expanded ? 999 : 4)">
  <label [class.disabled]="!!item.disabled">
    <ui-check class="small-gap" [checked]="isChecked(item.value)" [disabled]="!!item.disabled"></ui-check>
    <input
      type="checkbox"
      [disabled]="item.disabled ?? false"
      [checked]="isChecked(item.value)"
      (change)="onCheckboxChange(item.value, $event)"
    />
    <span>{{ item.label }}</span>
  </label>
  <div class="locked" *ngIf="item.isLocked && item.disabled">
    <span [ngxTippy]="lockedTooltip" tippyClassName="locked-tooltip" [tippyProps]="tooltipSettings"></span>
    <svg-icon class="icon-lock" src="/assets/icons/icons-lock-small.svg"></svg-icon>
  </div>
  <ng-template #lockedTooltip>
    <span class="tooltip-content">{{ item.isLocked }}</span>
  </ng-template>
</div>
<div class="toggle" *ngIf="canExpand" (click)="toggle()" [ngClass]="{ expanded: expanded }">
  <svg-icon src="/assets/icons/{{ expanded ? 'icons-minus' : 'icons-plus' }}.svg"></svg-icon>
  <p>{{ toggleButtonText | translate }}</p>
</div>
