import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IGuest, IReservation } from '@smarttypes/hotel';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { sortBy } from 'lodash';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PastsVisitsVerticalItemComponent } from '../pasts-visits-vertical-item/pasts-visits-vertical-item.component';

export enum ReservationClickActionEnum {
  edit,
  remove,
  changeContext,
  goToChat,
  showSecrets,
  importInfo,
}

enum NavEnum {
  top,
  bottom,
}

@Component({
  selector: 'ui-pasts-visits-vertical',
  templateUrl: './pasts-visits-vertical.component.html',
  styleUrls: ['./pasts-visits-vertical.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    ButtonCircleComponent,
    BsDropdownModule,
    TranslateModule,
    AngularSvgIconModule,
    PastsVisitsVerticalItemComponent,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastsVisitsVerticalComponent implements OnInit {
  private _reservations?: IReservation[];

  get reservations(): IReservation[] {
    return this._reservations || [];
  }

  @Input() set reservations(reservations: IReservation[]) {
    this._reservations = sortBy(reservations, 'from').reverse();
    this.init();
    this.cdr.detectChanges();
  }

  @Input() visitor?: IGuest;

  @Input() maxItems = 6;
  @Output() reservationClick: EventEmitter<{
    reservation: IReservation;
    action: ReservationClickActionEnum;
  }> = new EventEmitter<{
    reservation: IReservation;
    action: ReservationClickActionEnum;
  }>();
  navEnum = NavEnum;
  @ViewChild('content') contentElement?: ElementRef;
  @ViewChildren('item') items?: QueryList<ElementRef>;
  scrollItemPosition = 0;
  itemsToShow: IReservation[] = [];
  currentReservationId = '';

  constructor(private cdr: ChangeDetectorRef) {}

  get showArrows(): boolean {
    return this.reservations?.length > this.maxItems;
  }

  get futureReservations(): IReservation[] {
    const today = new Date();
    return this.reservations.filter(reservation => new Date(reservation.from || '') > today);
  }

  ngOnInit() {
    this.init(true);
  }

  private init(initial = false) {
    this.currentReservationId = this.visitor?.reservation?.reservationId || '';

    if (initial && this.futureReservations?.length > 1 && this.showArrows) {
      this.scrollItemPosition = this.futureReservations?.length - 1;
    }
    this.itemsToShow = this.reservations.slice(this.scrollItemPosition, this.scrollItemPosition + 6);
  }

  onScrollClick(direction: NavEnum): void {
    if (direction === NavEnum.bottom) {
      if (this.scrollItemPosition + 6 > this.reservations?.length) {
        return;
      }

      this.scrollItemPosition += 6;
      this.itemsToShow = this.reservations.slice(this.scrollItemPosition, this.scrollItemPosition + 6);
    } else {
      if (this.scrollItemPosition - 6 < 0) {
        this.scrollItemPosition = 0;
      } else {
        this.scrollItemPosition -= 6;
      }

      this.itemsToShow = this.reservations.slice(this.scrollItemPosition, this.scrollItemPosition + 6);
    }
  }

  onReservationClick(event: {
    reservation: IReservation;
    action: ReservationClickActionEnum;
    isActive: boolean;
  }): void {
    if (event.action === ReservationClickActionEnum.changeContext && !event.isActive) {
      this.currentReservationId = event.reservation?.reservationId || '';
    } else if (event.isActive) {
      this.currentReservationId = '';
    }

    this.reservationClick.emit(event);
  }
}
