<div class="left">
  <ui-button-circle size="m" type="circle" icon="icons-close" (click)="bsModalRef.hide()"></ui-button-circle>

  <header>
    <div class="title">
      <svg-icon src="assets/icons/icons-upgrade.svg"></svg-icon>
      <h1>{{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.UNLOCK' | translate }}</h1>
    </div>
    <p class="description">
      {{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.DESCRIPTION' | translate }}
    </p>
  </header>
  <section class="price">
    <!--    <span class="value" translate>$99 <small translate>/ month</small></span>-->
    <!--    <p class="description" translate>-->
    <!--      Counted from the nearest billing cycle<br />-->
    <!--      Implementation <b>0 zł</b><br />-->
    <!--      <b>100 SMS/month free of charge</b> ($7 for each additional 100 SMS)-->
    <!--    </p>-->

    <a href="mailto:sales@mysmarthotel.com">
      <ui-button-rectangle
        size="l"
        label="{{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.CONTACT_SALES' | translate }}"
      ></ui-button-rectangle>
    </a>
  </section>
  <section class="benefits">
    <h2>{{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.BENEFITS' | translate }}:</h2>

    <ul>
      <li>
        <svg-icon src="assets/icons/icons-checkmark.svg"></svg-icon>
        <p>
          {{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.BENEFIT1' | translate }}
        </p>
      </li>

      <li>
        <svg-icon src="assets/icons/icons-checkmark.svg"></svg-icon>
        <p>
          {{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.BENEFIT2' | translate }}
        </p>
      </li>

      <li>
        <svg-icon src="assets/icons/icons-checkmark.svg"></svg-icon>
        <p>
          {{ 'SH.FRONTEND_LIB.JOIN_SMARTCHAT_MODAL.BENEFIT3' | translate }}
        </p>
      </li>

      <!--      <li>-->
      <!--        <svg-icon src="assets/icons/icons-checkmark.svg"></svg-icon>-->
      <!--        <p translate>-->
      <!--          Mobile app-->
      <!--        </p>-->
      <!--      </li>-->
    </ul>

    <!--    <a href="https://mysmarthotel.com/" target="_blank" translate>See all benefits</a>-->
  </section>
</div>
<div class="right">
  <img
    srcset="
      /assets/images/smartchat-phone.jpg    1x,
      /assets/images/smartchat-phone@2x.jpg 2x,
      /assets/images/smartchat-phone@3x.jpg 3x
    "
    src="/assets/images/smartchat-phone.jpg"
    alt="iPhone"
    class="phone"
  />
</div>
