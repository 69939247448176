<ng-select
  class="ng-select-sh"
  [ngClass]="type"
  [clearable]="clearable"
  [searchable]="searchable"
  [multiple]="multiple"
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [formControl]="control"
  [groupBy]="groupBy"
  [trackByFn]="trackByFn"
  [typeToSearchText]="typeToSearchText"
  [typeahead]="typeahead"
  [readonly]="readonly"
  [placeholder]="placeholder"
  [maxSelectedItems]="maxSelectedItems"
  #select
  [addTag]="addTag"
  [minTermLength]="0"
  (scrollToEnd)="onScroll()"
  [loading]="loading"
>
  <ng-template ng-header-tmp *ngIf="searchInList">
    <!--    <input class="search-input" type="search" autocomplete="off" [placeholder]="typeToSearchText"-->
    <!--           (input)="select.filter($event?.target?.value)"/>-->
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ng-container *ngIf="icon">
      <svg-icon [src]="iconPath" class="select-icon"></svg-icon>
    </ng-container>
    <ng-container *ngIf="bindLabel && item">
      <svg-icon *ngIf="item.icon" [src]="'/assets/icons/' + item.icon + '.svg'" class="option-icon"></svg-icon>
      <span class="ng-value-label">
        {{ (differentDisplayName ? item[differentDisplayName] ?? item[bindLabel] : item[bindLabel]) | translate }}
      </span>
    </ng-container>
    <ng-container *ngIf="!bindLabel">
      <span class="ng-value-label">{{ item | translate }}</span>
    </ng-container>
    <div class="arrow" *ngIf="arrow && !multiple">
      <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow-icon"></svg-icon>
    </div>
    <ng-container *ngIf="!arrow && multiple">
      <span class="ng-value-clear" (click)="clear(item)" aria-hidden="true">
        <svg-icon src="/icons/icons-close-small.svg" class="arrow-icon"></svg-icon>
      </span>
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="option" [class.is-locked]="item?.isLocked && item?.disabled">
      <img *ngIf="item?.icon && useImgInsteadSvgIcon" alt="icon" [src]="'/assets/icons/' + item.icon + '.svg'" />
      <svg-icon
        *ngIf="item?.icon && !useImgInsteadSvgIcon"
        [src]="'/assets/icons/' + item.icon + '.svg'"
        class="option-icon"
      ></svg-icon>
      <div class="ng-option-label" *ngIf="bindLabel && item">
        <span class="option-label">{{ item[bindLabel] | translate }}</span>
        <sh-communication-limit-tip
          *ngIf="item?.isLocked && item?.disabled"
          [content]="item?.isLocked"
        ></sh-communication-limit-tip>
      </div>
      <div class="ng-option-label" *ngIf="!bindLabel">
        <span>{{ item | translate }}</span>
      </div>
      <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
      <div class="clear" *ngIf="clearSelected" (click)="onItemClick(item)"></div>
    </div>
  </ng-template>
  <ng-template ng-tag-tmp let-item="item" let-tag="searchTerm">
    <div class="ng-option-label">
      <span>{{ tag }}</span>
    </div>
    <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
  </ng-template>
</ng-select>
