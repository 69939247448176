import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WindowService } from '@smarthotel/angular-services';
import { ITab } from '@smarttypes/core';
import { IReservation, ReservationStatusEnum } from '@smarttypes/hotel';
import { NavigationComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReservationsService as SharedReservationsService } from 'angular-v2-services';
import { endOfDay, startOfDay } from 'date-fns';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { EmptyComponent } from '../../empty';
import { MyProfileReservationsItemComponent } from '../my-profile-reservations-item/my-profile-reservations-item.component';

enum ReservationTypeEnum {
  active = 'active',
  past = 'past',
}

@Component({
  selector: 'ui-my-profile-reservations',
  templateUrl: './my-profile-reservations.component.html',
  styleUrls: ['./my-profile-reservations.component.scss'],
  imports: [
    AngularSvgIconModule,
    EmptyComponent,
    MyProfileReservationsItemComponent,
    TranslateModule,
    NavigationComponent,
    CommonModule,
  ],
  standalone: true,
})
export class MyProfileReservationsComponent implements OnInit {
  tabs: ITab[] = [];
  activeTab = ReservationTypeEnum.active;
  reservations?: IReservation[];
  activeReservations: IReservation[] = [];
  pastReservations: IReservation[] = [];
  reservationTypeEnum = ReservationTypeEnum;

  constructor(
    public bsModalRef: BsModalRef,
    private windowService: WindowService,
    private translateService: TranslateService,
    private router: Router,
    private sharedReservationsService: SharedReservationsService,
  ) {}

  onCloseClick(): void {
    setTimeout(() => {
      this.dissmisBackground();
    }, 300);

    this.bsModalRef.hide();
  }

  private dissmisBackground(): void {
    const elements = this.windowService.document?.getElementsByClassName('modal-backdrop');

    if (elements?.length) {
      elements[0].classList.add('modal-backdrop-transition');
    }
  }

  ngOnInit() {
    this.tabs = [
      {
        label: this.translateService.instant('GA.PROFILE.MY_RESERVATIONS.TAB.ACTIVE'),
        key: ReservationTypeEnum.active,
      },
      {
        label: this.translateService.instant('GA.PROFILE.MY_RESERVATIONS.TAB.PAST'),
        key: ReservationTypeEnum.past,
      },
    ];
    if (this.reservations?.length) {
      const { futureReservations, onGoingReservations, pastReservations } = this.getDividedReservations(
        this.reservations,
      );

      this.activeReservations = this.sortReservations(
        this.activeReservations.concat(futureReservations, onGoingReservations),
      );
      this.pastReservations = this.sortReservations(this.pastReservations.concat(pastReservations));
    }
  }

  private sortReservations(reservations: IReservation[]): IReservation[] {
    return reservations.sort(a => (a.reservationId === localStorage.getItem('reservationId') ? -1 : 0));
  }

  tabChange(tab: ITab | any): void {
    this.activeTab = tab.key as ReservationTypeEnum;
  }

  private getDividedReservations(reservations: IReservation[]): {
    pastReservations: IReservation[];
    onGoingReservations: IReservation[];
    futureReservations: IReservation[];
  } {
    let onGoingReservations: IReservation[] = [];
    let pastReservations: IReservation[] = [];
    let futureReservations: IReservation[] = [];

    reservations.forEach(reservation => {
      if (reservation?.status !== ReservationStatusEnum.cancelled) {
        if (this.isBetween(endOfDay(new Date(reservation.from ?? '')), endOfDay(new Date(reservation.to ?? '')))) {
          onGoingReservations.push(reservation);
        } else if (endOfDay(new Date(reservation.to ?? '')) <= startOfDay(new Date())) {
          pastReservations.push(reservation);
        } else if (reservation?.from && reservation?.to) {
          futureReservations.push(reservation);
        }
      }
    });

    onGoingReservations = this.sortReservationsByFrom(onGoingReservations);
    pastReservations = this.sortReservationsByFrom(pastReservations);
    futureReservations = this.sortReservationsByFrom(futureReservations);

    return {
      pastReservations,
      onGoingReservations,
      futureReservations,
    };
  }

  private sortReservationsByFrom(reservations: IReservation[]): IReservation[] {
    return reservations.sort((d1, d2) => new Date(d1.from || '').getTime() - new Date(d2.from || '').getTime());
  }

  private isBetween(from: Date, to: Date) {
    const today = endOfDay(new Date());
    return today >= from && today <= to;
  }

  onReservationClick(reservation: IReservation) {
    if (reservation.guestId) {
      this.sharedReservationsService.setReservation(reservation.guestId as string);
      this.bsModalRef.hide();
    }
  }

  onCheckInClick(guestId: string) {
    this.router.navigate(['checkin', guestId]);
    this.bsModalRef.hide();
  }
}
