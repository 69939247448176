import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-button-square',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './button-square.component.html',
  styleUrls: ['./button-square.component.scss'],
})
export class ButtonSquareComponent {
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() class = '';
  @Input() size: 's' | 'm' = 's';
  @Input() iconSmall = false;
  @Input() type: 'primary' | 'secondary' | 'tertiary' = 'secondary';

  get iconSize(): string {
    return this.iconSmall ? 'button-icon-small' : '';
  }

  get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get typeClass(): string {
    return `button-type-${this.type}`;
  }

  get sizeClass(): string {
    return `button-size-${this.size}`;
  }
}
