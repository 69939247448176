import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ITab } from '@smarttypes/core';
import { TabsComponent, UploaderComponent } from '@ui/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { toggleAnimation } from 'angular-v2-animations';
import { isEmoji } from 'angular-v2-utils';

@Component({
  selector: 'wg-form-icon',
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, PickerModule, TabsComponent, UploaderComponent],
  templateUrl: './form-icon.component.html',
  styleUrls: ['./form-icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleAnimation],
})
export class FormIconComponent implements OnChanges, OnInit {
  @Input() icon?: string | null;
  @Input() isInvalid = false;
  @Input() isLogo = true;
  @Input() onlyEmoji = false;
  @Input() readOnly = false;
  @ViewChild('emojiWrapper', { static: true })
  emojiWrapper?: ElementRef<HTMLElement>;
  @Output() iconChange = new EventEmitter<string>();
  emojiPickerOpen = false;
  activeTab = 'image';
  tabs: ITab[] = [];

  constructor(private translateService: TranslateService) {}

  get isEmoji(): boolean {
    return isEmoji(this.icon);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLElement;
    const clickedOnEmoji = this.emojiWrapper?.nativeElement?.contains(clickTarget);

    if (!clickedOnEmoji && this.emojiPickerOpen) {
      this.emojiPickerOpen = false;
    }
  }

  ngOnInit() {
    this.tabs = [
      {
        key: 'image',
        label: this.translateService.instant(this.isLogo ? 'SH.UPLOAD_IMAGE' : 'SH.UPLOAD_LOGO'),
        items: 0,
      },
      {
        key: 'emoji',
        label: this.translateService.instant('SH.EMOJI'),
        items: 0,
      },
    ];
  }

  toggleEmoji(): void {
    if (this.readOnly) {
      return;
    }
    this.emojiPickerOpen = !this.emojiPickerOpen;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['icon']?.currentValue) {
      this.icon = changes['icon']?.currentValue;
    }
    if (this.onlyEmoji) {
      this.activeTab = 'emoji';
    }
  }

  onSelectEmoji(event: { emoji: EmojiData; $event: PointerEvent }) {
    this.icon = event.emoji.native;
    this.iconChange.emit(this.icon);
    this.emojiPickerOpen = false;
  }

  onFileAdded(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.iconChange.emit(e.target?.result as string);
      this.emojiPickerOpen = false;
    };
  }

  tabChange(tab: ITab): void {
    this.activeTab = tab.key;
  }
}
