<sh-button-circle size="m" (click)="onCloseClick()" icon="icons-close"></sh-button-circle>

<h1 *ngIf="!uploadFinished">
  {{ (isImportGuests ? 'SH.VISITORS_IMPORT.IMPORT_GUESTS' : 'SH.VISITORS_IMPORT.IMPORT_PROPERTIES') | translate }}
</h1>
<h1 *ngIf="uploadFinished">
  {{ (isImportGuests ? 'SH.VISITORS_IMPORT.IMPORTED_GUESTS' : 'SH.VISITORS_IMPORT.IMPORTED_PROPERTIES') | translate }}
</h1>
<p>
  {{ 'SH.VISITORS_IMPORT.FROM' | translate }} {{ fileName ? fileName : ('SH.VISITORS_IMPORT.FORMATS' | translate) }}
</p>

<sh-visitors-import-apartment-default
  *ngIf="!isImportedFinished"
  [uploadInProgress]="uploadInProgress"
  [file]="file"
  (importClick)="onImportClick()"
  (fileAdded)="onFileAdded($event)"
  [importType]="importType"
  (fileRemoved)="onFileRemoved()"
></sh-visitors-import-apartment-default>
<sh-visitors-import-apartment-finish
  (closeClick)="onCloseClick()"
  *ngIf="isImportedFinished && isImportedSuccess && !isStatusAway"
  [response]="response"
  [importedSuccess]="importedSuccess"
  [isFailed]="isStatusFailed"
  [isCompleted]="isStatusCompleted"
  [updatedSuccess]="updatedSuccess"
  [removedSuccess]="removedSuccess"
  [importedFailed]="importedFailed"
  [addedRooms]="addedRooms"
></sh-visitors-import-apartment-finish>
<sh-visitors-import-apartment-errors
  *ngIf="!isImportedSuccess && isImportedFinished && !isStatusAway"
  [uploadInProgress]="uploadInProgress"
  [response]="response"
  [file]="file"
  (importClick)="onImportClick()"
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
></sh-visitors-import-apartment-errors>
<sh-visitors-import-apartment-warnings
  *ngIf="isImportedFinished && isStatusAway"
  [uploadInProgress]="uploadInProgress"
  [response]="response"
  [file]="file"
  (importClick)="onImportClick($event)"
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
></sh-visitors-import-apartment-warnings>
