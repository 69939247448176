import { animate, state, style, transition, trigger } from '@angular/animations';

export const toggleContentAnimation = trigger('toggleContent', [
  state(
    'open',
    style({
      overflow: '*',
      opacity: 1,
      height: '*',
      marginTop: '*',
      paddingBottom: '*',
    }),
  ),
  state(
    'close',
    style({
      opacity: 0,
      height: '0px',
      overflow: 'hidden',
      marginTop: '0px',
      paddingBottom: '0px',
    }),
  ),
  transition('open <=> close', [animate('.3s cubic-bezier(.25, .8, .25, 1)')]),
]);
