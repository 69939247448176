import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ui-powered-by',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './powered-by.component.html',
  styleUrls: ['./powered-by.component.scss'],
})
export class PoweredByComponent {
  get lang(): string {
    return this.translateService.currentLang ?? 'en';
  }

  get url(): string {
    const baseUrl = 'https://mysmarthotel.com';
    const placeUrl = window.location.hostname;
    if (this.lang === 'pl') {
      return `${baseUrl}/pl/formularz-powered-by-smarthotel?place=${placeUrl}`;
    }
    if (this.lang === 'es') {
      return `${baseUrl}/es/es-form-powered-by-smarthotel?place=${placeUrl}`;
    }
    return `${baseUrl}/form-powered-by-smarthotel?place=${placeUrl}`;
  }

  constructor(private readonly translateService: TranslateService) {}
}
