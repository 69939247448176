<ui-button-circle
  icon="icons-minus"
  class="minus"
  [disabled]="isDisabledDec"
  (click)="onDecrease($event)"
></ui-button-circle>
<input [min]="minValue" [max]="maxValue" class="form-input" [(ngModel)]="numberValue" type="number" />
<ui-button-circle
  icon="icons-plus"
  class="plus"
  [disabled]="isDisabledInc"
  (click)="onIncrease($event)"
></ui-button-circle>
