<img [src]="cfg.image" alt="" *ngIf="cfg.image" />
<div class="content" *ngIf="cfg?.header || cfg?.body">
  <h3 translate *ngIf="cfg?.header">{{ cfg.header }}</h3>
  <p *ngIf="cfg.body" [innerHTML]="cfg.body | translate | nlToBr"></p>
</div>
<sh-button-rectangle
  type="secondary"
  size="l"
  (click)="onCancelClick()"
  label="{{ cfg.cancel ?? '' | translate }}"
></sh-button-rectangle>
<sh-button-rectangle
  [type]="cfg.btnClass ?? 'primary'"
  size="l"
  (click)="onConfirmClick()"
  label="{{ cfg.submit | translate }}"
  *ngIf="cfg?.submit"
></sh-button-rectangle>
<sh-button-rectangle
  [type]="cfg.btnClass ?? 'primary'"
  size="l"
  (click)="onConfirm2Click()"
  label="{{ cfg.submit2 ?? '' | translate }}"
  *ngIf="cfg?.submit2 && confirmFunc2nd"
></sh-button-rectangle>
<small class="hint" *ngIf="cfg?.hint">{{ cfg.hint }}</small>
