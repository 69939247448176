<form *ngIf="form" [formGroup]="form">
  <div class="room" *ngIf="guest?.reservation && !surveyMode">
    <ng-container *ngIf="room">
      <img *ngIf="roomImage" [src]="roomImage" [alt]="room.roomNo">
      <ui-room-badge *ngIf="!roomImage" [roomNumber]="room.roomNo"></ui-room-badge>
    </ng-container>
    <div>
      <h6 *ngIf="room">{{ room.roomNo }}</h6>
      <p>{{ 'GA.RESERVATION' | translate }} {{ guest?.reservation?.reservationId }}</p>
    </div>
  </div>
  <sh-stay-timeline-lib *ngIf="guest && guest?.reservation && !surveyMode" [guest]="guest"></sh-stay-timeline-lib>
  <div class="questions row">
    <ui-form
      [class.is-checkin]="isCheckinOrCheckout(control) && guest"
      [class.col-6]="
        (isFirstnameAndLastname &&
          ($any(control)?.value?.type === questionType.collectFirstName ||
            $any(control)?.value?.type === questionType.collectLastName)) ||
        (isCheckInAndCheckOut &&
          ($any(control)?.value?.type === questionType.checkIn || $any(control)?.value?.type === questionType.checkOut))
      "
      class="consents"
      *ngFor="let control of $any(form?.get('questions'))?.controls; let i = index"
      [formGroup]="$any(control)"
    >
      <sh-checkin-form-question
        [rooms]="rooms"
        [roomLoading]="roomLoading"
        (loadMoreRooms)="onLoadMoreRooms($event)"
        #question
        [form]="$any(control)"
        [translatePrefix]="translatePrefix"
        [preview]="preview"
        [countries]="countries"
        [languages]="languages"
        [question]="$any(control?.value)"
      ></sh-checkin-form-question>
    </ui-form>
  </div>
  <div class="files-section">
    <div class="file" *ngFor="let item of $any(form?.get('registrationAttachments'))?.controls; let i = index">
      <ui-form class="files-sh-form">
        <label>
          <p [innerHTML]="item?.value?.title"></p>
          <em *ngIf="item?.value?.required">*</em>
        </label>
        <sh-file-uploader
          #uploader
          [uploaderId]="'uploader-' + i"
          (fileRemove)="onFileRemove($event, item, item)"
          (fileAdded)="onFileAdd($event, item, i)"
        ></sh-file-uploader>
        <ui-error-message
          class="margin-top-4"
          [prefix]="translatePrefix"
          [control]="item?.get('name')"
        ></ui-error-message>
      </ui-form>
    </div>
  </div>
  <div class="consents-section" [class.disabled]="disableSignatureAndConsentsEdit">
    <div
      class="consents"
      *ngFor="let control of $any(form.get('consents'))?.controls; let i = index"
      [formGroup]="$any(control)"
    >
      <ui-form>
        <sh-checkbox
          [isGA]="translatePrefix === 'GA'"
          [isExpandable]="true"
          [required]="control?.get('required')?.value"
          [class.disabled]="preview"
          [value]="control?.get('answer')?.value"
          [formControlName]="'answer'"
          text="{{ control?.get('title')?.value }}"
        ></sh-checkbox>
        <ui-error-message [prefix]="translatePrefix" [control]="control?.get('answer')"></ui-error-message>
      </ui-form>
    </div>
  </div>
  <div class="roommates-section" *ngIf="showRoommates">
    <h2>{{ 'GA.CHECK_IN-FORM.ROOMMATES' | translate }}</h2>
    <p>{{ 'GA.CHECK_IN-FORM.ROOMMATES.DESCRIPTION' | translate }}</p>
    <ui-add-roommate (roommateChange)="onRoommateChange($event)" [countries]="countries"></ui-add-roommate>
  </div>
  <div
    *ngIf="isDigitalSignatureEnabled || preview"
    (click)="onSignatureClick()"
    class="signature"
    [class.disabled]="preview"
    [class.is-not-preview]="!preview"
  >
    <div class="signature-info" *ngIf="!preview">
      <ng-container *ngIf="showSignaturePadInfo">
        <svg-icon
          class="signature-icon"
          [class.opacity-0]="isSigned"
          *ngIf="!preview"
          src="/assets/icons/icons-phone-rotation.svg"
        ></svg-icon>
        <p [class.opacity-0]="isSigned" class="signature-info-title">{{ signatureTranslation }}</p>
      </ng-container>
      <div class="hr"></div>
    </div>
    <ui-form [class.disabled]="disableSignatureAndConsentsEdit">
      <signature-pad #signature [options]="signaturePadOptions" (drawEnd)="onDrawEnd()"></signature-pad>
      <ui-error-message [prefix]="translatePrefix" [control]="form.get('digitalSignature')"></ui-error-message>
    </ui-form>
    <a class="clear-signature" href="javascript:" *ngIf="showSignatureClearButton" (click)="clearSignature()">{{
        translateWithPrefix('CHECK_IN-FORM.SIGNATURE.CLEAR')
      }}</a>
  </div>
  <div class="buttons" *ngIf="!preview">
    <ui-button-rectangle
      *ngIf="!disableSignatureAndConsentsEdit"
      [pending]="pending"
      [disabled]="pending"
      (click)="onSaveClick()"
      type="primary"
      size="l"
      [label]="submitText"
    ></ui-button-rectangle>
    <ui-button-rectangle
      *ngIf="alreadySigned"
      [disabled]="pending"
      (click)="onReturn()"
      type="white"
      size="l"
      class="return"
      [label]="backText"
    ></ui-button-rectangle>
  </div>
  <p class="footer" *ngIf="form.get('footer')?.value" [innerHTML]="form.get('footer')?.value"></p>
</form>
