<td>
  <div class="channel-name">
    <svg-icon class="channel-icon" [src]="icon"></svg-icon>
    {{ name | translate }}
    <ui-tip *ngIf="isSms" placement="right-end">
      {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.TIP' | translate }}
    </ui-tip>
  </div>
</td>
<td class="bold td-margin">
  <span [class.positive]="(stats?.change || 0) > 0" [class.negative]="(stats?.change || 0) < 0">
    {{ stats?.change === null ? '-' : change + '%' }}
  </span>
</td>
<td class="bold td-margin">
  <span>{{ stats?.count }}</span>
</td>
