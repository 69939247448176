import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastTypes } from '@ui/features';

import { VisitorsImportModalApartmentErrorsClass } from '../visitors-import-modal-apartment-errors.class';

@Component({
  selector: 'sh-visitors-import-apartment-errors',
  templateUrl: './visitors-import-apartment-errors.component.html',
  styleUrls: ['./visitors-import-apartment-errors.component.scss'],
})
export class VisitorsImportApartmentErrorsComponent implements OnInit {
  @Input() file: File | undefined;
  @Input() uploadInProgress = false;
  @Input() public response?: any;
  @Output() fileRemoved: EventEmitter<void> = new EventEmitter<void>();
  @Output() fileAdded: EventEmitter<File> = new EventEmitter<File>();
  @Output() importClick: EventEmitter<void> = new EventEmitter<void>();
  disabled = true;
  errors?: string[];
  toastTypes = ToastTypes;

  ngOnInit() {
    if (this.response) {
      this.errors = VisitorsImportModalApartmentErrorsClass.getErrors(this.response);
    }
  }

  onFileRemoved(): void {
    this.disabled = true;
    this.fileRemoved.emit();
  }

  onFileAdded(file: File): void {
    this.disabled = false;
    this.fileAdded.emit(file);
  }

  onImportClick(): void {
    this.importClick.emit();
  }
}
