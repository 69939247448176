<ui-modal-base [coverImage]="coverImage" [header]="cfg?.header ?? ''" [subtitle]="cfg?.body ?? ''"
               [canClose]="canClose" [subtitleHtml]="true" [icon]="cfg?.icon ?? ''" [large]="cfg?.large ?? false">
  <div content>
    <div class="warning" *ngIf="warning?.text">
      <svg-icon *ngIf="warning?.icon" src="/assets/icons/{{ warning?.icon }}.svg"></svg-icon>
      <p *ngIf="warning?.text">{{ warning?.text ?? '' | translate }}</p>
    </div>
    <div class="buttons" [class.block]="cfg?.blockButtons">
      <ui-button-rectangle
        [type]="cfg?.btnCancelClass ?? 'tertiary'"
        size="l"
        (click)="onCancelClick()"
        label="{{ cfg?.cancel ?? '' | translate }}"
        *ngIf="cfg?.cancel"
      ></ui-button-rectangle>
      <ui-button-rectangle
        [type]="cfg?.btnClass ?? 'tertiary'"
        size="l"
        (click)="onConfirmClick()"
        label="{{ cfg?.submit ?? '' | translate }}"
        *ngIf="cfg?.submit"
      ></ui-button-rectangle>
      <ui-button-rectangle
        [type]="cfg?.btnClass2 ?? 'tertiary'"
        size="l"
        (click)="onConfirm2Click()"
        label="{{ cfg?.submit2 ?? '' | translate }}"
        *ngIf="cfg?.submit2"
      ></ui-button-rectangle>
    </div>
    <small class="hint" *ngIf="cfg?.hint">{{ cfg?.hint }}</small>
  </div>
</ui-modal-base>
