import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '../../services/storage.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class AtV1Interceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let shouldInstall = false;
    if (request.urlWithParams.includes('/media-library') && !request.urlWithParams.includes('/v2/media-library')) {
      shouldInstall = true;
    }
    if (shouldInstall) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${StorageService.getItem('sh-t-v1')}`,
        },
      });
    }
    return next.handle(request);
  }
}
