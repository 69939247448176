import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonPillComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ToastTypes } from './toast.types';

@Component({
  selector: 'ui-inline-toast',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, ButtonRectangleComponent, ButtonPillComponent],
  templateUrl: './inline-toast.component.html',
  styleUrls: ['./inline-toast.component.scss'],
})
export class InlineToastComponent {
  @Input() label: string | SafeHtml = '';
  @Input() labels?: string[];
  @Input() buttonText = '';
  @Input() icon = '';
  @Input() toastInput: ToastTypes = ToastTypes.primary;
  @Input() maxHeight = 114;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
  isExpanded = false;

  @HostBinding('class.primary') get primary(): boolean {
    return this.toastInput === ToastTypes.primary;
  }

  @HostBinding('class.warning') get warning(): boolean {
    return this.toastInput === ToastTypes.warning;
  }

  @HostBinding('class.error') get error(): boolean {
    return this.toastInput === ToastTypes.error;
  }

  @HostBinding('class.darkYellow') get darkYellow(): boolean {
    return this.toastInput === ToastTypes.darkYellow;
  }

  get buttonType(): 'quaternary' | 'secondary' | 'primary' | 'black' | 'white' | 'yellow' {
    if (this.darkYellow) {
      return 'yellow';
    }
    return 'secondary';
  }

  onButtonClick(): void {
    this.buttonClick.emit();
  }

  onClick(): void {
    if (this.labels?.length) {
      this.isExpanded = true;
      this.maxHeight = this.maxHeight * 2;
    }
  }
}
