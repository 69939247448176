import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { INote } from '@smarttypes/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ngIfDropdownOpenTrigger } from 'angular-v2-animations';
import { format, isToday, isYesterday } from 'date-fns';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'ui-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ngIfDropdownOpenTrigger],
  imports: [BsDropdownModule, AngularSvgIconModule, TranslateModule],
  standalone: true,
})
export class NoteComponent {
  @Input() note?: INote;
  @Output() removeNote = new EventEmitter<INote>();
  @Output() editNote = new EventEmitter<INote>();

  constructor(private translateService: TranslateService) {}

  get date(): string | null {
    if (this?.note?.createdAt) {
      const date = new Date(this.note.createdAt);

      const parts = [];
      if (isToday(date)) {
        parts.push(this.translateService.instant('SH.TODAY'));
      } else if (isYesterday(date)) {
        parts.push(this.translateService.instant('SH.YESTERDAY'));
      } else {
        parts.push(format(date, 'yyyy-MM-dd'));
      }
      parts.push(format(date, 'h:mm a'));

      return parts.join(' ');
    }
    return null;
  }

  get author(): string | null {
    return this.note?.author?.displayName ?? null;
  }
}
