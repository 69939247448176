<ui-button-circle icon="icons-arrow-left" [iconSmall]="true" (click)="return.emit()"></ui-button-circle>
<div class="title">
  <ng-container *ngIf="!loading; else skeleton">
    <p *ngIf="title">{{ title | translate }}</p>
  </ng-container>
  <ng-template #skeleton>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ width: '200px', height: '24px', marginBottom: '0px', borderRadius: '8px' }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>
<div class="language-wrapper">
  <ui-select-language
    *ngIf="languages"
    side="right"
    [color]="isLanguageSticky ? 'gray' : 'white'"
    [languages]="languages"
    [formControl]="langControl"
    [class.sticky]="isLanguageSticky"
  ></ui-select-language>
</div>
