<div>
  @if (showLogo) {
    <sh-logo-desktop *shIsBreakpoint="'desktop'"></sh-logo-desktop>
  }
  <sh-hamburger *shIsBreakpoint="'mobile'"></sh-hamburger>
  @if (showLogo) {
    <sh-logo-mobile *shIsBreakpoint="'mobile'"></sh-logo-mobile>
  }
</div>
@if (showAccount) {
  <sh-user></sh-user>
}
<sh-sidebar-backdrop *shIsBreakpoint="'mobile'"></sh-sidebar-backdrop>
