<div class="box" [ngClass]="{ 'menu-opened': isMenuOpen, clickable: clickable || hasActions }">
  <div class="icon">
    <svg-icon src="/assets/icons/{{ sectionIcon }}.svg"></svg-icon>
    <div
      dropdown
      class="btn-group"
      [isAnimated]="false"
      (click)="onOpenMenu($event)"
      (isOpenChange)="isOpenChange($event)"
      *ngIf="hasActions"
    >
      <ui-button-circle type="tertiary" size="m" icon="icons-dots-vertical" dropdownToggle></ui-button-circle>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-inverted">
        <li *ngIf="canRename">
          <a class="dropdown-item" (click)="onEdit($event)">{{ 'SH.VISITORS_PLACE.EDIT_NAME' | translate }}</a>
        </li>
        <li *ngIf="canRemove">
          <a class="dropdown-item text-danger" (click)="onRemove($event)">{{
              'SH.VISITORS_PLACE.DELETE_SECTION' | translate
            }}</a>
        </li>
      </ul>
    </div>
  </div>
  <h6 *ngIf="title">{{ title }}</h6>
  <div class="actions">
    <!--    <ui-linked-objects-button [counter]="60" (click)="openLinkObjectedModal($event)"></ui-linked-objects-button>-->
    <div class="counter" *ngIf="counter">{{ counter }}</div>
    <ui-tip *ngIf="!!tooltip">{{ tooltip }}</ui-tip>
    <svg-icon
      class="arrow"
      *ngIf="clickable"
      src="/assets/icons/{{ section.externalConfiguration ? 'icons-external-small' : 'icons-arrow-right-small' }}.svg"
    ></svg-icon>
    <ui-grabber *ngIf="movable"></ui-grabber>
  </div>
</div>
<hr *ngIf="divider" />
