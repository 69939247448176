import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastTypes } from '@ui/features';

import { VisitorsImportModalApartmentErrorsClass } from '../visitors-import-modal-apartment-errors.class';

@Component({
  selector: 'sh-visitors-import-apartment-finish',
  templateUrl: './visitors-import-apartment-finish.component.html',
  styleUrls: ['./visitors-import-apartment-finish.component.scss'],
})
export class VisitorsImportApartmentFinishComponent implements OnInit {
  @Input() importedSuccess = 0;
  @Input() updatedSuccess = 0;
  @Input() importedFailed = 0;
  @Input() addedRooms = 0;
  @Input() removedSuccess = 0;
  @Output() closeClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() public response?: any;
  @Input() public isCompleted = false;
  @Input() public isFailed = false;
  toastTypes = ToastTypes;
  errors?: string[];

  ngOnInit() {
    if (this.response) {
      this.errors = VisitorsImportModalApartmentErrorsClass.getErrors(this.response);
    }
  }

  onCloseClick(): void {
    this.closeClick.emit();
  }
}
