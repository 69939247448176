<div class="content">
  <h3>{{ txt.header ?? '' | translate }}</h3>
  <p>{{ txt.body ?? '' | translate }}</p>
</div>
<ui-error-message [apiError]="formErrors"></ui-error-message>
<ui-button-rectangle
  type="danger"
  size="l"
  [disabled]="pending"
  [pending]="pending"
  (click)="onDeleteClick()"
  label="{{ 'SH.DELETE' | translate }}"
></ui-button-rectangle>
<ui-button-rectangle
  type="secondary"
  size="l"
  (click)="bsModalRef.hide()"
  label="{{ 'SH.CANCEL' | translate }}"
></ui-button-rectangle>
