<td>
  <div class="channel-name">
    <svg-icon *ngIf="icon" class="channel-icon" [src]="icon"></svg-icon>
    <div *ngIf="!icon" class="no-icon">-</div>
    <span>{{ name | translate }}</span>
    <ui-tip placement="right-end" *ngIf="tooltip" [offset]="[0, 10]">
      {{ tooltip | translate }}
    </ui-tip>
  </div>
</td>
<td class="td-margin margin-24">{{ sms }} {{ currency }}</td>
<td class="td-margin margin-24">{{ whatsApp }} {{ currency }}</td>
<td class="bold td-margin">{{ total }} {{ currency }}</td>
