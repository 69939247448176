import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map, take } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CompanyService } from '../services/company.service';
import { ResponsiveService } from '../services/responsive.service';

@Injectable({
  providedIn: 'root',
})
export class MobileNotSupportedGuard implements CanActivate {
  constructor(
    private readonly companyService: CompanyService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
  ) {}

  canActivate() {
    return this.companyService.$currentUser().pipe(
      filter(e => !!e),
      take(1),
      timeout(10000),
      map(company => {
        if (company.plan === 'SmartGuest' && this.responsiveService.isMobile) {
          return true;
        } else {
          this.router.navigate([environment.homepage]);
          return false;
        }
      }),
    );
  }
}
