import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../core/services/navigation.service';

@Component({
  selector: 'sh-sidebar-backdrop',
  templateUrl: './sidebar-backdrop.component.html',
  styleUrls: ['./sidebar-backdrop.component.scss'],
  standalone: true,
  imports: [TranslateModule, AngularSvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarBackdropComponent implements OnInit, OnDestroy {
  @HostBinding('class.visible') isVisible = false;

  @HostListener('click') toggleNavigationBackdrop() {
    this.isVisible = false;
    this.navigationService.toggleNavigation(false);
    this.cdr.detectChanges();
  }

  private subscriptions: Subscription = new Subscription();

  constructor(
    private readonly navigationService: NavigationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.navigationService.$toggleNavigation.subscribe(open => {
        this.isVisible = open;
        this.cdr.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
