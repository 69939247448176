<div class="wrapper" [class.on-the-bottom]="isBottom">
  <div class="update-on" *ngIf="date">
    <p>
      {{ 'SH.VISITORS_PLACE.LAST_PUBLISHED' | translate }}:<br />
      {{ date | date : 'd MMM, HH:mm' }}
      <ng-container *ngIf="author"> {{ 'SH.VISITORS_PLACE.PUBLISHED_BY' | translate }} {{ author }} </ng-container>
    </p>
  </div>
  <ui-button-rectangle
    type="primary"
    size="m"
    label="{{ 'SH.VISITORS_PLACE.PUBLISH' | translate }}"
    [pending]="pending"
    [disabled]="pending || disabled"
    (click)="onPublish()"
  ></ui-button-rectangle>
</div>
