<div class="header" *ngIf="canClose">
  <ui-button-circle size="m" type="circle" icon="icons-close" (click)="onClose()"></ui-button-circle>
</div>
<img *ngIf="coverImage" class="cover-image" [src]="coverImage" alt="" />
<header>
  <svg-icon *ngIf="icon" class="icon" src="/assets/icons/{{ icon }}.svg"></svg-icon>
  <h1>{{ header ?? '' | translate }}</h1>
  <ng-container *ngIf="subtitle">
    <ng-container *ngIf="subtitleHtml; else subtitleTpl">
      <p [innerHTML]="subtitle | translate | nlToBr"></p>
    </ng-container>
    <ng-template #subtitleTpl>
      <p>{{ subtitle | translate }}</p>
    </ng-template>
  </ng-container>
  <p *ngIf="!subtitle">
    <ng-content select="[subtitle]"></ng-content>
  </p>
</header>
<main>
  <ng-content select="[content]"></ng-content>
</main>
