import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IAirbnbGuestReview, IMessageItem } from '@smarttypes/messages';

import { ReplyToReviewComponent } from '../reply-to-review/reply-to-review.component';
import { ReviewStarsComponent } from '../review-stars/review-stars.component';

@Component({
  selector: 'ui-airbnb-guest-review-form',
  standalone: true,
  templateUrl: './airbnb-guest-review-form.component.html',
  styleUrls: ['./airbnb-guest-review-form.component.scss'],
  imports: [CommonModule, ReviewStarsComponent, TranslateModule, ReplyToReviewComponent],
})
export class AirbnbGuestReviewFormComponent {
  @Input() public message?: IMessageItem;
  @Output() public review: EventEmitter<string> = new EventEmitter<string>();

  get rating(): IAirbnbGuestReview | undefined {
    return (this.message?.data as any)?.Message as IAirbnbGuestReview | undefined;
  }
}
