<ng-container *ngIf="isSent; else content">
  <header class="email-sent">
    <h1>{{ 'SH.WELCOME_MODAL.CONFIRM_EMAIL' | translate }}</h1>
    <p>{{ 'SH.WELCOME_MODAL.SEND_MESSAGE' | translate }}.</p>
    <span (click)="sendEmail()">{{ 'SH.WELCOME_MODAL.RESEND_MESSAGE' | translate }}</span>
    <span (click)="correctInfo()" class="edit">{{ 'SH.WELCOME_MODAL.EDIT_MY_DATA' | translate }}</span>
  </header>
</ng-container>
<ng-template #content>
  <svg width="48" height="48" viewBox="0 0 48 48">
    <path
      d="m4.512 21.02 2.934 2.88.16.158 12.986 12.744.233.228a3.93 3.93 0 0 0 5.484 0 3.755 3.755 0 0 0 .048-5.334l-.048-.048-.449-.44-.032-.033a3.755 3.755 0 0 1 0-5.285l.048-.049a3.93 3.93 0 0 1 5.43-.052l.034.033 6.081 5.968a3.163 3.163 0 0 1 0 4.532L26.598 46.944a3.723 3.723 0 0 1-5.196 0L5.24 31.082A9.885 9.885 0 0 1 2.35 22.59c.14-.975.434-3.264 2.161-1.57zM26.598 1.057 42.76 16.919a9.887 9.887 0 0 1 2.977 7.574c-.065 1.29-.38 4.322-2.106 2.628L27.175 10.97a3.93 3.93 0 0 0-5.484 0 3.755 3.755 0 0 0-.048 5.333l.048.048.433.425.048.048a3.755 3.755 0 0 1-.048 5.334 3.931 3.931 0 0 1-5.379.1l-.056-.053-.05-.047-6.06-5.949a3.165 3.165 0 0 1-.955-2.14l-.001-.05v-.139.015l-.001.08a3.164 3.164 0 0 1 .911-2.253l.045-.045L21.402 1.056a3.723 3.723 0 0 1 5.196 0z"
      fill="#18CFF7"
      fill-rule="evenodd"
    />
  </svg>
  <header>
    <h1>{{ 'SH.WELCOME_MODAL.WELCOME_TO' | translate }} SmartHotel!</h1>
    <p>
      {{ 'SH.WELCOME_MODAL.ACCOUNT_READY' | translate }}<br />
      {{ 'SH.WELCOME_MODAL.FILL_INFORMATION' | translate }}.
    </p>
  </header>
  <form action="" [formGroup]="form" (ngSubmit)="submit()">
    <sh-form-avatar
      [avatar]="avatar"
      [library]="false"
      (removeAvatar)="avatar = ''"
      (changeAvatar)="avatarUpdate($event)"
    ></sh-form-avatar>
    <div class="row">
      <div class="col-6">
        <sh-form>
          <label for="firstName">{{ 'SH.FIRST_NAME' | translate }}<em>*</em></label>
          <input
            type="text"
            id="firstName"
            name="name"
            autocomplete="given-name"
            formInput
            formControlName="firstName"
          />
          <sh-error-message [control]="form.get('firstName')"></sh-error-message>
        </sh-form>
      </div>
      <div class="col-6">
        <sh-form>
          <label for="lastName">{{ 'SH.LAST_NAME' | translate }}<em>*</em></label>
          <input
            type="text"
            id="lastName"
            name="last-name"
            autocomplete="family-name"
            formInput
            formControlName="lastName"
          />
          <sh-error-message [control]="form.get('lastName')"></sh-error-message>
        </sh-form>
      </div>
    </div>
    <sh-form>
      <label for="email">{{ 'SH.EMAIL' | translate }}<em>*</em></label>
      <input type="email" id="email" name="email" autocomplete="email" formInput formControlName="email" />
      <sh-error-message [control]="form.get('email')"></sh-error-message>
      <small class="form-text text-muted">{{ 'SH.WELCOME_MODAL.CONFIRM_INFORMATION' | translate }}.</small>
    </sh-form>
    <sh-button-rectangle
      size="l"
      type="primary"
      label="{{ 'SH.CONTINUE' | translate }}"
      [pending]="pending"
      [disabled]="pending"
    ></sh-button-rectangle>
  </form>
</ng-template>
