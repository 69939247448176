<div class="messages-container">
  <header>
    <h1>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.TITLE' | translate }}</h1>
    <ui-button-circle
      size="s"
      type="quaternary"
      icon="icons-export-medium"
      [loading]="downloading"
      [disabled]="downloading"
      (click)="export.emit()"
    ></ui-button-circle>
  </header>

  <div class="buttons">
    <ui-button-pill
      (click)="onChannelClick(activeChannelEnum.massMessages)"
      [active]="activeChannel === activeChannelEnum.massMessages"
      type="secondary"
      text="{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.MASS_MESS' | translate }}"
    ></ui-button-pill>
    <ui-button-pill
      (click)="onChannelClick(activeChannelEnum.chat)"
      [active]="activeChannel === activeChannelEnum.chat"
      type="secondary"
      text="{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHAT' | translate }}"
    ></ui-button-pill>
    <ui-button-pill
      (click)="onChannelClick(activeChannelEnum.automation)"
      [active]="activeChannel === activeChannelEnum.automation"
      type="secondary"
      text="{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.AUTOMATION' | translate }}"
    ></ui-button-pill>
    <ui-button-pill
      (click)="onChannelClick(activeChannelEnum.other)"
      [active]="activeChannel === activeChannelEnum.other"
      type="secondary"
      text="{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.OTHER' | translate }}"
    ></ui-button-pill>
  </div>

  <div class="summary">
    <p>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.TOTAL_MESSAGES' | translate }}</p>

    <div class="messages-info">
      <span class="messages">
        {{ isLoading ? '' : allMessages }}
        <ngx-skeleton-loader
          *ngIf="isLoading"
          count="1"
          appearance="line"
          [theme]="{ width: '90px', height: '36px', marginBottom: '0', borderRadius: '8px' }"
        ></ngx-skeleton-loader>
      </span>
      <span
        *ngIf="percentChange !== 0 && !isLoading"
        class="messages-count"
        [class.positive]="isPercentChangePositive"
        [class.negative]="isPercentChangeNegative"
      >
        <svg-icon *ngIf="isPercentChangeNegative || isPercentChangePositive" class="icon" [src]="iconPath"></svg-icon>
        <span> {{ percentChange }}%</span>
      </span>
    </div>
  </div>
  <ui-percentage-bar [isLoading]="isLoading" [data]="chartData"></ui-percentage-bar>
  <table *ngIf="allMessages">
    <thead>
      <tr>
        <th>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHANNEL' | translate }}</th>
        <th>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHANNEL.CHANGE' | translate }}</th>
        <th>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHANNEL.MESSAGES' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of statsForActiveTab">
        <ui-messages-sent-by-channel-item
          (mouseenter)="onMouseEnter(item.channel)"
          (mouseleave)="onMouseLeave()"
          [stats]="item"
        ></ui-messages-sent-by-channel-item>
      </tr>
    </tbody>
  </table>

  <div class="empty" *ngIf="!allMessages && !isLoading">
    {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.EMPTY' | translate }}
  </div>
</div>
