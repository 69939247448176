<form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off" *ngIf="form && canSendReview">
  <ui-form>
    <textarea
      autosize
      [minRows]="3"
      [maxRows]="15"
      placeholder="{{ 'SH.RESPOND_REVIEW.PLACEHOLDER' | translate }}"
      formControlName="respond"
      formInput
      required
    ></textarea>
    <ui-error-message [control]="form.get('respond')"></ui-error-message>
  </ui-form>
  <ui-button-rectangle
    type="primary"
    size="s"
    [pending]="pending"
    [disabled]="this.form.invalid"
    label="{{ 'SH.RESPOND_REVIEW.SUBMIT' | translate }}"
  ></ui-button-rectangle>
</form>
