<div class="box">
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{ width: '40px', height: '40px', marginBottom: '0px', borderRadius: '50%' }"
    class="icon"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{ width: '200px', height: '24px', marginBottom: '0px' }"
  ></ngx-skeleton-loader>
  <div class="actions">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ width: '20px', height: '20px', marginBottom: '0px', borderRadius: '4px' }"
    ></ngx-skeleton-loader>
  </div>
</div>
