<div class="wrapper">
  <header>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ width: '200px', height: '24px', marginBottom: '0px', borderRadius: '4px' }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ width: '44px', height: '24px', marginBottom: '0px', borderRadius: '4px' }"
    ></ngx-skeleton-loader>
  </header>
</div>
