import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageService } from '../../services/storage.service';

@Injectable()
export class jwtOptionsFactory {
  skipWhenExpired = false;
  throwNoTokenError = false;
  allowedDomains: RegExp[] | string[] = environment?.jwt?.allowedDomains ?? [];
  disallowedRoutes: RegExp[] | string[] = environment?.jwt?.disallowedRoutes ?? [];

  tokenGetter = () => {
    return StorageService.getItem('sh-t-p');
  };
}
