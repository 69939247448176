import { IAddress } from '@smarttypes/core';
import { get, isObject } from 'lodash';

export function addressString(address: IAddress): string {
  return !isObject(address)
    ? ''
    : Object.keys(address)
        .reduce((aggr: string[], item) => {
          if (item !== 'geo') {
            aggr.push(get(address, item) as string);
          }
          return aggr;
        }, [])
        .filter(f => !!f)
        .join(', ');
}
