<div #emojiWrapper>
  <div
    class="icon emoji-picker-button"
    (click)="toggleEmoji()"
    [ngClass]="{ active: emojiPickerOpen, invalid: isInvalid, readOnly: readOnly }"
  >
    <svg-icon src="/assets/icons/icons-emoticon.svg" *ngIf="!this.icon; else emoji"></svg-icon>
    <ng-template #emoji>
      <ng-container *ngIf="isEmoji">
        {{ icon }}
      </ng-container>
      <ng-container *ngIf="!isEmoji">
        <img [src]="icon" alt="" />
      </ng-container>
    </ng-template>
  </div>
  <div class="emoji-picker" [@toggle]="emojiPickerOpen" *ngIf="emojiPickerOpen">
    <ui-tabs *ngIf="!onlyEmoji" [tabs]="tabs" [counter]="false" [activeTab]="activeTab"
             (tabClick)="tabChange($event)"></ui-tabs>
    <ng-container [ngSwitch]="activeTab">
      <ng-container *ngSwitchCase="'image'">
        <ui-uploader
          [border]="false"
          formats=".png,.jpg,.svg"
          [maxSize]="26214400"
          [description]="'Recommended .svg, .png or .jpg format.\n The maximum size is 5 MB.'"
          (fileAdded)="onFileAdded($event)"
        ></ui-uploader>
      </ng-container>
      <ng-container *ngSwitchCase="'emoji'">
        <emoji-mart
          [darkMode]="false"
          (emojiSelect)="onSelectEmoji($event)"
          [emojiSize]="28"
          [i18n]="{ search: ('SH.VISITORS_PLACE.SEARCH_EMOJI' | translate) }"
        ></emoji-mart>
      </ng-container>
    </ng-container>
  </div>
</div>
