import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ButtonPillComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-banner',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, ButtonPillComponent],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() header?: string;
  @Input() text?: string;
  @Input() html?: SafeHtml;
  @Input() type: 'info' | 'positive' | 'warning' | 'negative' = 'info';
  @Input() hierarchy: 'low' | 'high' = 'high';
  @Input() iconLeft?: string;
  @Input() iconRight?: string;
  @Input() iconRightAction = false;
  @Input() buttonText?: string;
  @Input() maxHeight = 114;
  @Input() @HostBinding('class.clickable') clickable = false;
  @Input() @HostBinding('class.no-border-radius') noRadius = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter();
  @Output() iconClick: EventEmitter<void> = new EventEmitter();

  @HostBinding('class') get classes(): string {
    const classes: string[] = [];
    classes.push(`type-${this.type}`);
    classes.push(`hierarchy-${this.hierarchy}`);
    return classes.join(' ');
  }

  get iconLeftPath(): string {
    return `/assets/icons/${this.iconLeft}.svg`;
  }

  get iconRightPath(): string {
    return `/assets/icons/${this.iconRight}.svg`;
  }

  onIconClick() {
    if (!this.iconRightAction) {
      return;
    }
    this.iconClick.emit();
  }
}
