<div
  class="search-wrapper"
  [ngClass]="{ active: searchActive }"
  (click)="setActive()"
  [ngxTippy]="!searchActive ? ('SH.SEARCH' | translate) : null"
  [tippyProps]="{ duration: [500, 500], offset: [0, 7], delay: [200, 0], animation: 'scale', placement: 'bottom' }"
>
  <svg-icon class="search-icon" src="/assets/icons/icons-search.svg"></svg-icon>
  <input
    type="search"
    class="form-input"
    [ngModel]="value"
    (ngModelChange)="changeValue($event)"
    [placeholder]="placeholder"
    (blur)="inputBlur()"
    #input
  />
  <ui-loader [showSpinner]="searchActive && pending" color="dark" size="s"></ui-loader>
  <svg-icon
    class="clear-icon"
    src="/assets/icons/icons-close.svg"
    (click)="onClear()"
    *ngIf="searchActive && !!value && !pending"
  ></svg-icon>
</div>
