import { animate, state, style, transition, trigger } from '@angular/animations';

export const showErrorAnimation = trigger('showError', [
  state(
    'in',
    style({
      transform: 'translateY(0)',
      opacity: 1,
      height: '*',
      marginTop: '*',
      marginBottom: '*',
    }),
  ),
  state(
    'void',
    style({
      transform: 'translateY(-50%)',
      opacity: 0,
      height: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    }),
  ),
  transition('* <=> *', [animate('0.5s cubic-bezier(.25, .8, .25, 1)')]),
]);
