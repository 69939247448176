export enum CommunicationChannelEnum {
  WhatsApp = 'WhatsApp',
  Sms = 'Sms',
  Phone = 'Phone',
  Alpha = 'Alpha',
  Email = 'Email',
  /**
   * @deprecated
   * @todo Should be replaced by SmartChat value
   */
  WebChat = 'WebChat',
  FacebookMessenger = 'FacebookMessenger',
  SmartChat = 'SmartChat',
  Telegram = 'Telegram',
  Signal = 'Signal',
  Skype = 'Skype',
  BookingCom = 'BookingCom',
  AirBnB = 'AirBnB',
  Vrbo = 'Vrbo',
  Internal = 'Internal',
  Push = 'Push',
}
