<p class="note-text">{{ note?.text }}</p>
<span class="note-date">{{ date }} {{ 'SH.BY' | translate }} {{ author }}</span>

<div dropdown class="note-actions btn-group" [isAnimated]="false">
  <svg-icon dropdownToggle src="/assets/icons/icons-dots-vertical.svg"></svg-icon>
  <ul *dropdownMenu [@ngIfDropdownOpen] class="dropdown-menu">
    <!--    <li><a class="dropdown-item" (click)="editNote.emit(note)">{{'SH.GUEST.NOTE.EDIT'|translate}}</a></li>-->
    <li>
      <a class="dropdown-item text-danger" (click)="removeNote.emit(note)">{{
        'SH.VISITORS_VIEW.VISITOR_NOTES.GUEST_NOTE.REMOVE_NOTE' | translate
      }}</a>
    </li>
  </ul>
</div>
