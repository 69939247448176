import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IFileItem } from '@smarttypes/core';
import { GrabberComponent } from '@ui/common';
import { ButtonRectangleComponent, ButtonSquareComponent } from '@ui/common/buttons';
import { FormComponent } from '@ui/common/forms';

@Component({
  selector: 'ui-file-item',
  standalone: true,
  imports: [
    CommonModule,
    FormComponent,
    TranslateModule,
    ButtonSquareComponent,
    ButtonRectangleComponent,
    GrabberComponent,
  ],
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
})
export class FileItemComponent implements OnInit {
  @Input() file?: IFileItem;
  @Input() index = 0;
  @Input() add = false;
  @Input() sortable = false;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() onlyOne = false;
  @Input() required = false;
  @Input() attachmentVersion = false;
  @Input() allowRenameFile = true;
  @Output() fileAdded = new EventEmitter<IFileItem>();
  @Output() fileRemove = new EventEmitter<IFileItem>();
  @Output() fileUpdate = new EventEmitter<IFileItem>();

  ngOnInit(): void {
    if (this.add) {
      this.file = {
        fileName: '',
      };
    }
  }

  onFileAdd(file: IFileItem) {
    this.fileAdded.emit(file);
  }

  onFileRemove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileRemove.emit(this.file);
  }

  openFileLibrary(event: MouseEvent) {
    event.preventDefault();
  }

  renameFileName(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.add || !this.allowRenameFile) {
      return;
    }
    this.fileUpdate.emit(this.file);
  }
}
