export enum QuestionTypeEnum {
  text = 'Text',
  yesNo = 'Yes/No',
  email = 'Email',
  phone = 'Phone',
  number = 'Number',
  nationality = 'Nationality',
  language = 'Language',
  collectFirstName = 'First Name',
  collectLastName = 'Last Name',
  town = 'Town',
  ZIPCode = 'ZIPCode',
  street = 'Street',
  houseNumber = 'House number',
  apartmentNumber = 'Apartment number',
  fullAddress = 'Full address',
  checkIn = 'Check-In Time',
  checkOut = 'Check-Out Time',
  date = 'Date',
}
