<div class="interactions">
  <header>
    <h1>{{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.TITLE' | translate }}</h1>
    <ui-button-circle
      size="s"
      type="quaternary"
      icon="icons-export-medium"
      [loading]="downloading"
      [disabled]="downloading"
      (click)="export.emit()"
    ></ui-button-circle>
  </header>

  <div class="summary">
    <p>{{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.TOTAL_CLICKS' | translate }}</p>

    <div class="clicks-info">
      <span class="clicks">
        {{ allClicks }}
      </span>
      <span
        *ngIf="allClicks && isPreviousPeriod"
        class="clicks-count"
        [class.positive]="isPercentChangePositive"
        [class.negative]="isPercentChangeNegative"
      >
        <svg-icon *ngIf="isPercentChangeNegative || isPercentChangePositive" class="icon" [src]="iconPath"></svg-icon>
        <span>{{ percentChange }}%</span>
      </span>
    </div>
  </div>
  <table>
    <thead>
    <tr>
      <th class="th-tip">
        <span>{{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.CHANNEL' | translate }}</span>
        <ui-tip placement="right-end">
          {{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.TIP' | translate }}
        </ui-tip>
      </th>
      <th>{{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.CHANGES' | translate }}</th>
      <th>{{ 'SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.CLICKS' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngIf="items && (items?.length ?? 0) > 0">
      <tr *ngFor="let widget of items">
        <ui-widgets-interaction-item
          [vaWidget]="getWidgetById(widget.name)"
          [widget]="widget"
          [isApartment]="isApartment"
        ></ui-widgets-interaction-item>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ui-button-rectangle
    (click)="onLoadClick()"
    *ngIf="showLoadButton"
    type="secondary"
    size="s"
    [label]="
      !isAllItemsLoaded
        ? ('SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.SHOW_ME_ALL' | translate : { count: allItems })
        : ('SH.ANALYTICS_VIEW.WIDGET_INTERACTIONS.HIDE' | translate)
    "
  ></ui-button-rectangle>
</div>
