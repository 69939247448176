import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ICountry } from '@smarttypes/core';
import { FormControlDirective } from 'angular-v2-directives';
import { findPhoneNumbersInText, isValidPhoneNumber, NumberFound } from 'libphonenumber-js/mobile';
import { from, Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

import { FormComponent } from '../form/form.component';
import { SelectCountryComponent } from '../select-country/select-country.component';

export function createPhoneValidator(form: FormGroup) {
  return function validatePhoneNumber() {
    const phoneNumber = form?.value?.number;
    const countryCode = form?.value?.country?.alpha2Code;
    if (phoneNumber && countryCode && !isValidPhoneNumber(phoneNumber, countryCode)) {
      return { validatePhone: { valid: false } };
    }
    return null;
  };
}

@Component({
  selector: 'ui-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectCountryComponent,
    FormComponent,
    TranslateModule,
    FormControlDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  @Input() countries: ICountry[] = [];
  @Input() formControl!: FormControl;
  @Input() formControlName = '';
  @Input() label?: string;
  @Input() fieldId = '';
  @Input() required = false;
  @Input() guestArea = false;
  @Input() disableLangSwitch = false;
  @Input() readonly = false;
  @Output() inputBlur = new EventEmitter<boolean>();
  value = '';
  prefix = '';
  form: FormGroup = new FormGroup({
    country: new FormControl(null, Validators.required),
    number: new FormControl(''),
  });
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;
  private onTouch!: () => void;
  private onChange!: (value: any) => void;
  private isRegister = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (!this.fieldId) {
      this.fieldId = `${this.formControlName}-number`;
    }
    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          map((form: { country: ICountry; number: string }) => {
            this.prefix = `+${form?.country?.callingCodes[0]}`;
            const number = this.removeNonNumericChars(form.number);
            if (this.prefix && number) {
              return `${this.prefix}${number}`;
            } else {
              return '';
            }
          }),
          tap((phoneNumber: string) => {
            if (this.value !== phoneNumber) {
              this.writeValue(phoneNumber, true);
              if (this.isRegister) {
                this.onChange(phoneNumber);
              }
            }
          }),
          delay(50),
          tap(() => {
            this.onTouch();
            this.validateFn = createPhoneValidator(this.form);
          }),
        )
        .subscribe(() => {
          this.cd.detectChanges();
        }),
    );
  }

  onInputBlur() {
    this.inputBlur.emit(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  registerOnChange(fn: any): void {
    this.isRegister = true;
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: unknown, formChanged = false): void {
    this.value = value ? `${value}` : '';
    if (!formChanged) {
      if (!this.value) {
        this.form.patchValue(
          {
            number: '',
          },
          { emitEvent: false, onlySelf: true },
        );
      } else {
        this.parseInitialNumber();
      }
    }
  }

  onSanitizeInput() {
    const v = this.form.get('number')?.value ?? '';
    this.form.get('number')?.setValue(this.removeNonNumericChars(v), {
      emitEvent: false,
      onlySelf: true,
    });
  }

  focus() {
    this.input.nativeElement.focus();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({
        emitEvent: false,
      });
    } else {
      this.form.enable({
        emitEvent: false,
      });
    }
  }

  private validateFn = (_: any) => {
    console.log();
  };

  private parseInitialNumber() {
    let phoneNumber = this.value;
    if (!this.value?.startsWith('+')) {
      phoneNumber = `+${this.value}`;
    }
    return from(findPhoneNumbersInText(phoneNumber))
      .pipe(
        filter(res => !!res),
        delay(150),
        tap((res: NumberFound) => {
          this.form.patchValue({
            country: this.countries.filter(c => c.alpha2Code === res.number.country)[0],
            number: res.number.nationalNumber,
          });
        }),
      )
      .subscribe(() => {
        this.cd.detectChanges();
      });
  }

  private removeNonNumericChars(input: string): string {
    return input.trim()?.replace(/[^0-9]/gi, '');
  }
}
