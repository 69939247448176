import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sh-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent {
  @HostBinding('class.checked') @Input() checked!: boolean;
  @HostBinding('class.disabled') @Input() disabled = false;
}
