<div
  #uploader
  [class.file-added]="file"
  (dragover)="onFileDragOver()"
  (dragleave)="onFileDragLeave()"
  (drop)="onFileDragDrop()"
  class="uploader"
  shDragNDrop
  (fileDropped)="onFileDropped($event)"
>
  <ng-container *ngIf="!file">
    <svg-icon src="/assets/icons/icons-color-excel.svg"></svg-icon>
    <p class="title">{{ 'SH.FRONTEND_LIB.UPLOADER.TITLE' | translate }}</p>
    <span class="description">{{
      'SH.FRONTEND_LIB.UPLOADER.DESCRIPTION' + 'SH.FRONTEND_LIB.UPLOADER.MAX_SIZE' | translate
    }}</span>
    <input #fileDropRef [accept]="formats" type="file" (change)="fileBrowseHandler($any($event.target).files)" />
  </ng-container>
  <ng-container *ngIf="file">
    <div class="file-info">
      <svg-icon class="file-ico" src="/assets/icons/icons-color-excel.svg"></svg-icon>
      <span class="file-name">{{ file.name | maxChars : 30 }}</span>
      <button *ngIf="!pending" type="button" class="btn trash" (click)="onFileRemove()">
        <svg-icon src="/assets/icons/icons-trash.svg"></svg-icon>
      </button>
    </div>
  </ng-container>
</div>
