export enum BlueMallCategoriesEnum {
  accessories = 'accessories',
  clothing = 'clothing',
  healthAndBeauty = 'health-and-beauty',
  homeAndLifestyle = 'home-and-lifestyle',
  publishing = 'publishing',
  services = 'services',
  shoes = 'shoes',
  sport = 'sport',
  underwear = 'underwear',
  multimedia = 'multimedia',
}
