import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkProtocol',
  standalone: true,
})
export class LinkProtocolPipe implements PipeTransform {
  transform(url?: string, protocol = 'https://'): string {
    if (!url?.startsWith('https://') && !url?.startsWith('http://')) {
      return `${protocol}${url}`;
    }
    return url;
  }
}
