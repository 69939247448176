<ui-select
  type="ng-select-input-big ng-select-100 ng-select-text-nowrap"
  [items]="rooms"
  bindLabel="label"
  bindValue="value"
  [searchable]="true"
  [arrow]="true"
  (loadMore)="loadMoreRooms()"
  [formControl]="$any(control)"
  [loading]="roomLoading"
  [typeahead]="roomInput$"
  [placeholder]="placeholder"
  [typeToSearchText]="'SH.SEARCH_ROOM' | translate"
  [clearSelected]="true"
></ui-select>
