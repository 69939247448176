<ul class="tabs">
  <li *ngFor="let tab of allowedTabs" #tabElement>
    <div (click)="onItemClick(tab)" [class.active]="tab.key === activeTab">
      <span *ngIf="counter">{{ tab.items }}</span>
      <p>{{ tab.label | translate }}</p>
    </div>
  </li>
</ul>
<div class="slider" [class.animate]="enableAnimation">
  <div [style.left.px]="leftPosition" [style.width.px]="tabWidth" *ngIf="!!activeTab"></div>
</div>
