<h3>{{ cfg?.header | translate }}</h3>
<ui-form>
  <input
    type="text"
    formInput
    [ngClass]="{ 'input-error': hasError }"
    [(ngModel)]="value"
    placeholder="{{ 'SH.INPUT_MODAL.PLACEHOLDER' | translate }}"
    #input
  />
</ui-form>
<div class="actions">
  <ui-button-rectangle
    size="m"
    type="tertiary"
    [label]="cfg?.submit ?? '' | translate"
    (click)="onCancelClick()"
  ></ui-button-rectangle>
  <ui-button-rectangle
    size="m"
    type="primary"
    [label]="cfg?.submit2 ?? '' | translate"
    (click)="onConfirmClick()"
  ></ui-button-rectangle>
</div>
