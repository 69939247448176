<ui-form [label]="label ?? '' | translate" [required]="onlyOne && required">
  <div class="file-wrapper">
    <div [class.editable]="allowRenameFile" class="file-inner" (click)="renameFileName($event)">
      <div class="file-text" *ngIf="file?.displayName; else noFiles">
        <span>{{ file?.displayName }}</span>
      </div>
      <ng-template #noFiles>
        <div class="file-text file-text-placeholder">
          <span>{{ placeholder ?? '' | translate }}</span>
        </div>
      </ng-template>
      <ui-button-square
        icon="icons-trash"
        type="tertiary"
        size="s"
        *ngIf="file?.displayName"
        (click)="onFileRemove($event)"
      ></ui-button-square>
      <ui-button-rectangle
        size="s"
        type="tertiary"
        *ngIf="!file?.displayName"
        (click)="openFileLibrary($event)"
        label="{{ 'SH.FILE_ITEM.ADD_FILE' | translate }}"
      ></ui-button-rectangle>
    </div>
    <ui-grabber class="handle" *ngIf="sortable"></ui-grabber>
  </div>
</ui-form>
