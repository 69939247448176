import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { get, isObject, set, unset } from 'lodash';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';

import { CheckComponent } from '../checkbox/check/check.component';

export interface CheckboxButtonValue {
  value: string;
  label: string;
  disabled?: boolean;
  isLocked?: string;
}

@Component({
  selector: 'ui-checkbox-buttons',
  templateUrl: './checkbox-buttons.component.html',
  styleUrls: ['./checkbox-buttons.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxButtonsComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, NgxTippyModule, CheckComponent, TranslateModule],
})
export class CheckboxButtonsComponent implements ControlValueAccessor {
  @Input() values: CheckboxButtonValue[] = [];
  @Input() expandable = false;
  expanded = false;
  tooltipSettings: NgxTippyProps = {
    duration: [500, 500],
    offset: [0, 8],
    animation: 'scale',
    placement: 'bottom-start',
    theme: 'light',
  };
  private _checkboxValues: Record<string, boolean> = {};

  get canExpand(): boolean {
    return this.values.length > 4;
  }

  get toggleButtonText() {
    if (this.expanded) {
      return 'SH.FRONTEND_LIB.CHECKBOX_BUTTONS.LESS';
    }
    return 'SH.FRONTEND_LIB.CHECKBOX_BUTTONS.MORE';
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: string[]) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  writeValue(val: string[] | string | null): void {
    if (val) {
      const values = isObject(val) ? val : val.split(',');
      this._checkboxValues = values.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {} as Record<string, boolean>);
    } else {
      this._checkboxValues = {};
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.values.map(v => ({
      ...v,
      disabled: isDisabled,
    }));
  }

  onCheckboxChange(value: string, event: any): void {
    const checked = event.target.checked;
    if (checked) {
      set(this._checkboxValues, value, true);
    } else {
      unset(this._checkboxValues, value);
    }
    this.onChange(Object.keys(this._checkboxValues));
    this.onTouched();
  }

  isChecked(value: string): boolean {
    return get(this._checkboxValues, value, false);
  }

  toggle() {
    this.expanded = !this.expanded;
  }
}
