import { groupBy, isArray } from 'lodash';

export class VisitorsImportModalApartmentErrorsClass {
  static getErrors(response: any): string[] {
    const errors: string[] = [];

    if (response.message && !isArray(response.message?.rejectedRows)) {
      errors.push(response.message as string);
    } else response.message?.rejectedRows?.length;
    {
      const grouped = groupBy(response.message?.rejectedRows, 'errorMessage');

      if (Object.keys(grouped).length) {
        Object.entries(grouped).forEach((item: any) => {
          errors.push(`${item[0]}: ${item[1].map((i: any) => i.column).join(', ')}`);
        });
      }
    }

    return errors;
  }
}
