export enum MessageItemStatusEnum {
  created = 'created',
  submitting = 'submitting',
  submitted = 'submitted',
  delivered = 'delivered',
  read = 'read',
  rejected = 'rejected',
  undeliverable = 'undeliverable',
  failed = 'failed',
  unknown = 'unknown',
  pending = 'pending',
}
