import { Injectable } from '@angular/core';
import { ITag } from '@smarttypes/core';
import { IGuest } from '@smarttypes/hotel';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private hardCodedTags: ITag[] = [
    {
      group: 'Default',
      value: 'kids',
      icon: 'icons-type-kids',
      label: 'SH.TAGS.KIDS',
    },
    {
      group: 'Default',
      value: 'business',
      icon: 'icons-type-business',
      label: 'SH.TAGS.BUSINESS',
    },
    {
      group: 'Default',
      value: 'rest',
      icon: 'icons-type-rest',
      label: 'SH.TAGS.REST',
    },
    {
      group: 'Default',
      value: 'couple',
      icon: 'icons-type-together',
      label: 'SH.TAGS.COUPLE',
    },
    {
      group: 'Default',
      value: 'conference',
      icon: 'icons-type-conference',
      label: 'SH.TAGS.CONFERENCE',
    },
    {
      group: 'Default',
      value: 'medical group',
      icon: 'icons-type-medical',
      label: 'SH.TAGS.MEDICAL_GROUP',
    },
    {
      group: 'Default',
      value: 'troubles',
      icon: 'icons-type-troubles',
      label: 'SH.TAGS.TROUBLES',
    },
    {
      group: 'Default',
      value: 'returning',
      icon: 'icons-type-returning',
      label: 'SH.TAGS.RETURNING',
    },
  ];

  getTags() {
    return this.hardCodedTags;
  }

  mergeTags(tags: string[]): ITag[] {
    const customTags: ITag[] = tags
      .filter(t => !this.hardCodedTags.find(h => h.value.toLowerCase() === t.toLowerCase()))
      .map(t => ({
        value: t.toLowerCase(),
        label: t,
        icon: '',
        group: 'Custom',
      })) as ITag[];
    return this.hardCodedTags.concat(customTags).sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  removeTag(object: IGuest, tag: string) {
    if (!Array.isArray(object?.tags)) {
      object.tags = [];
    }
    object.tags = object.tags.filter(t => t !== tag.toLowerCase());
  }

  addTag(object: IGuest, tag: string) {
    if (!Array.isArray(object?.tags)) {
      object.tags = [];
    }
    if (!object.tags.includes(tag.toLowerCase())) {
      object.tags.push(tag.toLowerCase());
    }
  }
}
