import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ui-join-smartchat-modal',
  templateUrl: './join-smartchat-modal.component.html',
  styleUrls: ['./join-smartchat-modal.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, ButtonCircleComponent, TranslateModule, ButtonRectangleComponent],
})
export class JoinSmartchatModalComponent {
  constructor(public bsModalRef: BsModalRef) {}
}
