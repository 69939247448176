import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ButtonCircleComponent } from '@ui/common/buttons';

export function createCounterRangeValidator(maxValue: number, minValue: number) {
  return function validateCounterRange(c: FormControl) {
    // const err: any = {
    //   rangeError: {
    //     given: c.value,
    //     max: maxValue,
    //     min: minValue
    //   }
    // };
    if (c.value < minValue) {
      return { min: { valid: false } };
    }
    if (c.value > maxValue) {
      return { max: { valid: false } };
    }

    return null;
  };
}

@Component({
  selector: 'ui-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
  imports: [ButtonCircleComponent, FormsModule],
  standalone: true,
})
export class InputNumberComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() value = 1;
  @Input() minValue = 0;
  @Input() maxValue = 100;
  isDisabledDec = false;
  isDisabledInc = false;
  private onTouch!: () => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: number) => {};

  ngOnInit() {
    this.validateFn = createCounterRangeValidator(this.maxValue, this.minValue);
    this.emitChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['minValue'] || changes['maxValue']) {
      this.validateFn = createCounterRangeValidator(this.maxValue, this.minValue);
    }
    this.emitChange();
  }

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  get numberValue() {
    return this.value;
  }

  set numberValue(val: number) {
    this.value = val;
    this.emitChange();
  }

  onDecrease(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.value - 1 >= this.minValue) {
      this.value--;
      this.emitChange();
    }
  }

  onIncrease(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.value + 1 <= this.maxValue) {
      this.value++;
      this.emitChange();
    }
  }

  emitChange() {
    this.onChange(this.value);
    this.isDisabledDec = this.value <= this.minValue;
    this.isDisabledInc = this.value >= this.maxValue;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: number): void {
    this.value = obj;
    this.isDisabledDec = this.value <= this.minValue;
    this.isDisabledInc = this.value >= this.maxValue;
  }

  private validateFn = (_: any) => {
    console.log();
  };
}
