import { IBaseEntity, ILinkedObject } from '@smarttypes/core';

import { SortByEnum } from './common';

export interface ISection extends IBaseEntity {
  companyId: string;
  parentId?: string;
  isStatic?: boolean;
  sectionCode?: string;
  name: string;
  icon?: string;
  draggable?: boolean;
  canAddWidgets?: boolean;
  divider?: boolean;
  allowedWidgets: string[];
  widgetsOrder?: string[];
  sectionsOrder?: string[];
  sortBy?: SortByEnum;
  tooltip?: boolean;
  data?: unknown;
  externalConfiguration?: string;
  linkedObjects?: ILinkedObject[];
  language?: string;
  editorConfig?: ISectionEditorConfig;
}

export interface ISectionEditorConfig {
  renderAs?: EditorConfigRenderAs;
  allowedActions?: EditorConfigAllowedActionsEnum[];
  widgetStructure?: Record<string, unknown>[];
}

export enum EditorConfigRenderAs {
  inline = 'INLINE',
  list = 'LIST',
  hidden = 'HIDDEN',
}

export enum EditorConfigAllowedActionsEnum {
  addSection = 'ADD_SECTION',
  addWidget = 'ADD_WIDGET',
  removeSection = 'REMOVE_SECTION',
  renameSection = 'RENAME_SECTION',
}

export enum SectionLayoutEnum {
  grid = 'grid',
  list = 'list',
  carousel = 'carousel',
  links = 'links',
  static = 'static',
  buttons = 'buttons',
}
