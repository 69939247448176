import { BreakpointObserver } from '@angular/cdk/layout';
import { Pipe, PipeTransform } from '@angular/core';

const MOBILE_BREAKPOINT = '768px';

@Pipe({
  name: 'switchValueForMobile',
  standalone: true,
})
export class SwitchToMobileValuePipe implements PipeTransform {
  get isMobile(): boolean {
    return this.breakpointObserver.isMatched(`(max-width: ${MOBILE_BREAKPOINT})`);
  }

  constructor(private breakpointObserver: BreakpointObserver) {}

  transform(desktopValue: unknown, mobileValue: unknown): any {
    return this.isMobile ? mobileValue : desktopValue;
  }
}
