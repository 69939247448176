import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule, FrontendUiModule } from '@smarthotel/angular-ui';
import { GrabberComponent, LinkedObjectsButtonComponent, TipComponent } from '@ui/common';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { PhoneNumberComponent } from '@ui/common/forms';
import { LinkedObjectsModalComponent } from '@ui/common/modals';
import { FormWidgetPermalinkComponent } from '@widgets/front-common/form';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { AutosizeModule } from 'ngx-autosize';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { QuillModule } from 'ngx-quill';
import { SortablejsModule } from 'nxt-sortablejs';

import { ConfiguratorComponentsModule } from '../../components/configurator-components.module';
import { FormContentWifiComponent } from './form-content-wifi/form-content-wifi.component';
import { FormWidgetAiAssistantComponent } from './form-widget-ai-assistant/form-widget-ai-assistant.component';
import { FormWidgetBaseComponent } from './form-widget-base/form-widget-base.component';
import { FormWidgetChatSingleComponent } from './form-widget-chat-single/form-widget-chat-single.component';
import { FormWidgetCheckInOutComponent } from './form-widget-check-in-out/form-widget-check-in-out.component';
import { FormWidgetCommunicationComponent } from './form-widget-communication/form-widget-communication.component';
import { FormWidgetContactComponent } from './form-widget-contact/form-widget-contact.component';
import { FormWidgetCustomV1Component } from './form-widget-custom-v1/form-widget-custom-v1.component';
import { FormWidgetDepositComponent } from './form-widget-deposit/form-widget-deposit.component';
import { FormWidgetEventComponent } from './form-widget-event/form-widget-event.component';
import { FormWidgetFullPotentialComponent } from './form-widget-full-potential/form-widget-full-potential.component';
import { FormWidgetGastronomyComponent } from './form-widget-gastronomy/form-widget-gastronomy.component';
import { FormWidgetGymComponent } from './form-widget-gym/form-widget-gym.component';
import { FormWidgetInfoComponent } from './form-widget-info/form-widget-info.component';
import { FormWidgetMealComponent } from './form-widget-meal/form-widget-meal.component';
import { FormWidgetParkingComponent } from './form-widget-parking/form-widget-parking.component';
import { FormWidgetPetsComponent } from './form-widget-pets/form-widget-pets.component';
import { FormWidgetReceptionComponent } from './form-widget-reception/form-widget-reception.component';
import { FormWidgetSpaComponent } from './form-widget-spa/form-widget-spa.component';
import { FormWidgetWifiComponent } from './form-widget-wifi/form-widget-wifi.component';
import { FormWidgetsComponent } from './form-widgets.component';
import { WidgetCollectionComponent } from './widget-collection/widget-collection.component';

@NgModule({
  declarations: [
    FormWidgetBaseComponent,
    FormWidgetWifiComponent,
    FormWidgetMealComponent,
    FormWidgetSpaComponent,
    FormWidgetParkingComponent,
    FormWidgetReceptionComponent,
    FormWidgetCheckInOutComponent,
    FormWidgetDepositComponent,
    FormWidgetGymComponent,
    FormWidgetPetsComponent,
    WidgetCollectionComponent,
    FormWidgetInfoComponent,
    FormWidgetsComponent,
    FormWidgetContactComponent,
    FormWidgetCommunicationComponent,
    FormWidgetChatSingleComponent,
    FormWidgetGastronomyComponent,
    FormWidgetFullPotentialComponent,
    FormWidgetEventComponent,
    FormWidgetCustomV1Component,
    FormWidgetAiAssistantComponent,
    FormContentWifiComponent,
  ],
  imports: [
    AngularSvgIconModule,
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    ConfiguratorComponentsModule,
    CardModule,
    FormsModule,
    SortablejsModule,
    AutosizeModule,
    FrontendUiModule,
    QuillModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PickerModule,
    EmojiModule,
    PhoneNumberComponent,
    FormControlDirective,
    FormWidgetPermalinkComponent,
    ButtonCircleComponent,
    GrabberComponent,
    LinkedObjectsButtonComponent,
    LinkedObjectsModalComponent,
    TipComponent,
  ],
  exports: [
    FormWidgetsComponent,
    FormWidgetChatSingleComponent,
    FormWidgetBaseComponent,
    FormWidgetGastronomyComponent,
    FormWidgetEventComponent,
    FormWidgetMealComponent,
    FormContentWifiComponent,
  ],
  providers: [
    {
      provide: BsDropdownConfig,
      useValue: { isAnimated: true, autoClose: true },
    },
  ],
})
export class FormWidgetsModule {}
