<div [class.active]="isActive" [class.disabled-reservation]="isPast && isOlderThan3Weeks" class="reservation">
  <img [src]="image" alt="img" />
  <div class="info">
    <p class="title">{{ name }}</p>
    <p class="date" *ngIf="from && to">{{ from }} - {{ to }}</p>
    <p class="price" *ngIf="paid && currency">
      <span>{{ paid }} {{ currency }}</span>
      <span *ngIf="adultCount" class="adult-count">
        · {{ 'GA.MY_RESERVATIONS.ADULT_COUNT' | translate : { count: adultCount } }}</span
      >
    </p>
  </div>
  <div class="actions" *ngIf="arrow">
    <div class="dot"></div>
    <svg-icon class="arrow" src="/assets/icons/icons-arrow-right.svg"></svg-icon>
  </div>
</div>
<ng-container *ngIf="!checkInSigned && checkInButton">
  <ui-button-rectangle
    *ngIf="!small; else smallButton"
    class="check-in-button"
    type="secondary"
    size="m"
    icon="icons-sign"
    [forceFullWidth]="true"
    label="{{ 'GA.CHECK_IN_NOW' | translate }}"
    (click)="onCheckInClick($event)"
  ></ui-button-rectangle>
  <ng-template #smallButton>
    <ui-button-pill
      class="check-in-button"
      size="s"
      type="secondary"
      text="{{ 'GA.CHECK_IN' | translate }}"
      (click)="onCheckInClick($event)"
    ></ui-button-pill>
  </ng-template>
</ng-container>
