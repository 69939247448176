import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private authService!: AuthService;

  constructor(private inj: Injector, private toastr: ToastrService, private router: Router) {
    setTimeout(() => {
      this.authService = this.inj.get(AuthService);
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.headers.get('hideErrorToast')) {
      return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }

    return next.handle(request);
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<HttpErrorResponse>): Observable<HttpEvent<HttpErrorResponse>> {
    if (!environment.production) {
      // Do something with the error
    }
    if (response instanceof HttpErrorResponse) {
      if (!(response.error instanceof ArrayBuffer)) {
        this.toastr.error(response?.error?.message ?? response?.statusText);
      } else {
        const error = String.fromCharCode.apply(null, new Uint8Array(response.error) as any);

        this.toastr.error(JSON.parse(error).message ?? response?.statusText);
      }

      if (response?.status === 401 && this.authService.loggedIn) {
        this.authService.logout(true).subscribe();
      }
    }
    throw response;
  }
}
