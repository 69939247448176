import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sh-button-circle',
  templateUrl: './button-circle.component.html',
  styleUrls: ['./button-circle.component.scss'],
})
export class ButtonCircleComponent {
  @HostBinding('class') @Input() icon!: string;
  @Input() disabled!: boolean;
  @Input() class!: string;
  @Input() iconSmall = false;
  @Input() size: 's' | 'm' = 's';
  @Input() color: 'blue' | 'danger' | null = null;
  @HostBinding('class.pending') @Input() pending = false;
  @Input() type: 'tertiary' | 'circle' | 'quaternary' | 'quaternary-light' = 'circle';

  get iconPath(): string | '' {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get iconSize(): string {
    return this.iconSmall ? 'button-icon-small' : '';
  }

  get iconColor(): string {
    return this.color ? `button-color-${this.color}` : '';
  }

  get typeClass(): string {
    return `button-type-${this.type}`;
  }

  get sizeClass(): string {
    return `button-size-${this.size}`;
  }
}
