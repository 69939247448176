import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../../core/services/navigation.service';

@Component({
  selector: 'sh-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HamburgerComponent implements OnInit, OnDestroy {
  isOpen = false;
  private subscription: Subscription = new Subscription();

  @HostListener('click') toggleNavigation() {
    this.isOpen = !this.isOpen;
    this.navigationService.toggleNavigation(this.isOpen);
    this.cdr.detectChanges();
  }

  constructor(
    private readonly navigationService: NavigationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.navigationService.$toggleNavigation.subscribe(open => {
        this.isOpen = open;
        this.cdr.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
