import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILanguage } from '@smarttypes/core';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import urlJoin from 'url-join';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  selectedLang = new BehaviorSubject<string>('en');
  private availableLanguages = ['en', 'pl', 'es'];
  private allLanguages: ILanguage[] = [];
  private googleLanguages: ILanguage[] = [];

  constructor(private http: HttpClient) {}

  getLanguageByISO1(code: string, base?: string): Observable<ILanguage | undefined> {
    if (this.allLanguages.length > 0) {
      return of(this?.allLanguages?.find(a => a.iso639_1 === code.toLowerCase()));
    }
    return this.getLanguages(base).pipe(map(langs => langs.find(a => a.iso639_1 === code.toLowerCase())));
  }

  getLanguageByISO2(code: string, base?: string): Observable<ILanguage | undefined> {
    if (this.allLanguages.length > 0) {
      return of(this?.allLanguages?.find(a => a.iso639_2 === code.toLowerCase()));
    }
    return this.getLanguages(base).pipe(map(langs => langs.find(a => a.iso639_2 === code.toLowerCase())));
  }

  getLanguages(base?: string): Observable<ILanguage[]> {
    const baseApi: string = base ? base : '';
    if (this.allLanguages.length > 0) {
      return of(this.allLanguages);
    }
    return this.http.get<ILanguage[]>(urlJoin(baseApi, '/countries/languages/all')).pipe(
      switchMap(objects => {
        this.allLanguages = objects.sort((a, b) => a.name.localeCompare(b.name));
        return of(this.allLanguages);
      }),
    );
  }

  getLanguagesFromGoogle(base?: string): Observable<ILanguage[]> {
    const baseApi: string = base ? base : '';
    if (this.googleLanguages.length > 0) {
      return of(this.googleLanguages);
    }
    return this.http.get<ILanguage[]>(urlJoin(baseApi, `/countries/languages/google`)).pipe(
      switchMap(objects => {
        this.googleLanguages = objects.sort((a, b) => a.name.localeCompare(b.name));
        return of(this.googleLanguages);
      }),
    );
  }

  getAdminLanguages(base?: string): Observable<ILanguage[]> {
    return this.getLanguages(base).pipe(
      switchMap(languages => {
        return of(languages.filter((lang: ILanguage) => this.availableLanguages.includes(lang.iso639_1)));
      }),
    );
  }
}
