import { Injectable } from '@angular/core';
import { ImportStatusEnum } from '@smarttypes/core';
import { Observable } from 'rxjs';

import { HttpService } from '../core/http/http.service';

export interface ISignedUrlResponse {
  signed: string;
  url: string;
}

export interface IImportResponse {
  importId: string;
}

export interface IStatusResponse {
  importStatus: ImportStatusEnum;
  message: unknown;
  numOfNewGuests: number;
  numOfUpdatedGuests: number;
  numOfRejectedGuests: number;
}

export interface IApartmentStatusResponse {
  importStatus: ImportStatusEnum;
  message: unknown;
  numOfCreatedDocuments: number;
  numOfUnImportedDocuments: number;
  numOfUpdatedDocuments: number;
  numOfRemovedDocuments: number;
  numOfAddedRooms?: number;
}

@Injectable({
  providedIn: 'root',
})
export class VisitorsImportService {
  constructor(private readonly httpService: HttpService) {}

  signUrl(fileName: string): Observable<ISignedUrlResponse> {
    return this.httpService.post<ISignedUrlResponse>('/files/signed-url', {
      fileName: fileName,
    });
  }

  upload(url: string, file: any) {
    return this.httpService.put(url, file);
  }

  import(url: string, importId = '', importAnyway = false): Observable<IImportResponse> {
    const body = importAnyway
      ? {
          importId: importId,
        }
      : {
          urlToFileInBucket: url,
        };
    return this.httpService.post<IImportResponse>('/import/import-guests', body);
  }

  importApartments(url: string, importId = '', importAnyway = false): Observable<IImportResponse> {
    const body = importAnyway
      ? {
          importId: importId,
        }
      : {
          urlToFileInBucket: url,
        };

    return this.httpService.post<IImportResponse>('/import/import-apartments', body);
  }

  status(importId: string): Observable<IStatusResponse & IApartmentStatusResponse> {
    return this.httpService.get<IStatusResponse & IApartmentStatusResponse>(`/import/status/${importId}`);
  }
}
