import { Component, Input, OnInit } from '@angular/core';
import { IGuest } from '@smarttypes/hotel';
import { toDate } from 'date-fns-tz';
import { first } from 'lodash';

@Component({
  selector: 'sh-stay-timeline-lib',
  templateUrl: './stay-timeline.component.html',
  styleUrls: ['./stay-timeline.component.scss'],
})
export class StayTimelineComponent implements OnInit {
  @Input() guest!: IGuest;

  from!: Date;
  to!: Date;

  ngOnInit() {
    if (this.guest.reservation?.from && this.guest.reservation?.to) {
      this.from = toDate(first((this.guest.reservation.from as unknown as string).split('T')) as string, {
        timeZone: 'Etc/UTC',
      });
      this.to = toDate(first((this.guest.reservation.to as unknown as string).split('T')) as string, {
        timeZone: 'Etc/UTC',
      });
    }
  }

  get progress(): number {
    if (!this.from || !this.to) {
      return 0;
    }

    const startDate = this.from.getTime();
    const endDate = this.to.getTime();
    const todayDate = new Date().getTime();
    const percentage = Math.round(((todayDate - startDate) / (endDate - startDate)) * 100);
    if (percentage < 0) {
      return 0;
    }
    return percentage > 100 ? 100 : percentage;
  }
}
