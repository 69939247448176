import { animate, style, transition, trigger } from '@angular/animations';

export const toggleAnimation = trigger('toggle', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(3px) translateX(-3px) scale(0.98)',
    }),
    animate(
      '0.3s ease',
      style({
        transform: 'translateY(0) translateX(0) scale(1)',
        opacity: 1,
      }),
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'translateY(0) translateX(0) scale(1)',
      opacity: 1,
    }),
    animate(
      '0.3s ease',
      style({
        opacity: 0,
        transform: 'translateY(3px) translateX(-3px) scale(0.98)',
      }),
    ),
  ]),
]);
