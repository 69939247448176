<ng-container *ngFor="let data of notifications">
  <ui-banner
    @showNotification
    [header]="data?.header"
    [html]="data?.html ? data?.description : ''"
    [iconLeft]="data?.icon"
    [type]="data?.type ?? 'warning'"
    [text]="!data?.html ? data?.description : ''"
    [hierarchy]="data?.hierarchy ?? 'high'"
    [noRadius]="true"
  ></ui-banner>
</ng-container>
