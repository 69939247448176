import { Component, Input } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'sh-communication-limit-tip',
  templateUrl: './communication-limit-tip.component.html',
  styleUrls: ['./communication-limit-tip.component.scss'],
})
export class CommunicationLimitTipComponent {
  @Input() content = '';

  tooltipSettings: NgxTippyProps = {
    duration: [500, 500],
    offset: [0, 8],
    animation: 'scale',
    placement: 'bottom-start',
    theme: 'light',
  };
}
