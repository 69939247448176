<svg-icon class="head-icon" src="/assets/icons/icons-user-copy.svg"></svg-icon>

<h1>{{ guestName }}</h1>

<ul>
  <li (click)="onMyReservationsClick()" *ngIf="canShowReservations">
    <svg-icon src="/assets/icons/icons-calendar-alt.svg"></svg-icon>
    <span>
      {{ 'GA.PROFILE.MY_RESERVATIONS' | translate }}
    </span>
  </li>
  <li (click)="onAddRoommateClick()">
    <svg-icon src="/assets/icons/icons-user-filled.svg"></svg-icon>
    <span>
      {{ 'GA.PROFILE.ADD_ROOMMATE' | translate }}
    </span>
  </li>
</ul>

<ui-button-rectangle
  size="l"
  (click)="onCloseClick()"
  type="secondary"
  [label]="'GA.CLOSE' | translate"
></ui-button-rectangle>
