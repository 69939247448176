<form [formGroup]="form">
  <ui-form [readonly]="readonly">
    <label *ngIf="label" [for]="fieldId">{{ label }} <em *ngIf="required">*</em></label>
    <div class="wrapper">
      <ui-select-country
        [items]="countries"
        [disableLangSwitch]="disableLangSwitch"
        [guestArea]="guestArea"
        [loading]="false"
        [searchable]="true"
        [readonly]="readonly"
        typeToSearchText="{{ (!guestArea ? 'SH.SEARCH_NUMBER' : 'GA.SEARCH_NUMBER') | translate }}"
        type="ng-select-input-big"
        [arrow]="true"
        [formControlName]="'country'"
      ></ui-select-country>
      <div class="wrapper">
        <div class="form-input form-input-prefix">{{ prefix }}</div>
        <input
          #input
          type="tel"
          [id]="fieldId"
          name="number"
          class="form-input form-input-number"
          [readonly]="readonly"
          [formControlName]="'number'"
          formInput
          (keyup)="onSanitizeInput()"
          (paste)="onSanitizeInput()"
          (blur)="onInputBlur()"
        />
      </div>
    </div>
  </ui-form>
</form>
