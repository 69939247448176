import { ICountry } from '@smarttypes/core';

export const COUNTRIES: ICountry[] = [
  {
    name: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    callingCodes: ['93'],
    nativeName: 'افغانستان',
    flags: {
      svg: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
      png: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/320px-Flag_of_the_Taliban.svg.png',
    },
  },
  {
    name: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    callingCodes: ['358'],
    nativeName: 'Åland',
    flags: {
      svg: 'https://flagcdn.com/ax.svg',
      png: 'https://flagcdn.com/w320/ax.png',
    },
  },
  {
    name: 'Albania',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    callingCodes: ['355'],
    nativeName: 'Shqipëria',
    flags: {
      svg: 'https://flagcdn.com/al.svg',
      png: 'https://flagcdn.com/w320/al.png',
    },
  },
  {
    name: 'Algeria',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    callingCodes: ['213'],
    nativeName: 'الجزائر',
    flags: {
      svg: 'https://flagcdn.com/dz.svg',
      png: 'https://flagcdn.com/w320/dz.png',
    },
  },
  {
    name: 'American Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    callingCodes: ['1'],
    nativeName: 'American Samoa',
    flags: {
      svg: 'https://flagcdn.com/as.svg',
      png: 'https://flagcdn.com/w320/as.png',
    },
  },
  {
    name: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    callingCodes: ['376'],
    nativeName: 'Andorra',
    flags: {
      svg: 'https://flagcdn.com/ad.svg',
      png: 'https://flagcdn.com/w320/ad.png',
    },
  },
  {
    name: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    callingCodes: ['244'],
    nativeName: 'Angola',
    flags: {
      svg: 'https://flagcdn.com/ao.svg',
      png: 'https://flagcdn.com/w320/ao.png',
    },
  },
  {
    name: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    callingCodes: ['1'],
    nativeName: 'Anguilla',
    flags: {
      svg: 'https://flagcdn.com/ai.svg',
      png: 'https://flagcdn.com/w320/ai.png',
    },
  },
  {
    name: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    callingCodes: ['672'],
    nativeName: 'Antarctica',
    flags: {
      svg: 'https://flagcdn.com/aq.svg',
      png: 'https://flagcdn.com/w320/aq.png',
    },
  },
  {
    name: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    callingCodes: ['1'],
    nativeName: 'Antigua and Barbuda',
    flags: {
      svg: 'https://flagcdn.com/ag.svg',
      png: 'https://flagcdn.com/w320/ag.png',
    },
  },
  {
    name: 'Argentina',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    callingCodes: ['54'],
    nativeName: 'Argentina',
    flags: {
      svg: 'https://flagcdn.com/ar.svg',
      png: 'https://flagcdn.com/w320/ar.png',
    },
  },
  {
    name: 'Armenia',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    callingCodes: ['374'],
    nativeName: 'Հայաստան',
    flags: {
      svg: 'https://flagcdn.com/am.svg',
      png: 'https://flagcdn.com/w320/am.png',
    },
  },
  {
    name: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    callingCodes: ['297'],
    nativeName: 'Aruba',
    flags: {
      svg: 'https://flagcdn.com/aw.svg',
      png: 'https://flagcdn.com/w320/aw.png',
    },
  },
  {
    name: 'Australia',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    callingCodes: ['61'],
    nativeName: 'Australia',
    flags: {
      svg: 'https://flagcdn.com/au.svg',
      png: 'https://flagcdn.com/w320/au.png',
    },
  },
  {
    name: 'Austria',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    callingCodes: ['43'],
    nativeName: 'Österreich',
    flags: {
      svg: 'https://flagcdn.com/at.svg',
      png: 'https://flagcdn.com/w320/at.png',
    },
  },
  {
    name: 'Azerbaijan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    callingCodes: ['994'],
    nativeName: 'Azərbaycan',
    flags: {
      svg: 'https://flagcdn.com/az.svg',
      png: 'https://flagcdn.com/w320/az.png',
    },
  },
  {
    name: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    callingCodes: ['1'],
    nativeName: 'Bahamas',
    flags: {
      svg: 'https://flagcdn.com/bs.svg',
      png: 'https://flagcdn.com/w320/bs.png',
    },
  },
  {
    name: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    callingCodes: ['973'],
    nativeName: '‏البحرين',
    flags: {
      svg: 'https://flagcdn.com/bh.svg',
      png: 'https://flagcdn.com/w320/bh.png',
    },
  },
  {
    name: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    callingCodes: ['880'],
    nativeName: 'Bangladesh',
    flags: {
      svg: 'https://flagcdn.com/bd.svg',
      png: 'https://flagcdn.com/w320/bd.png',
    },
  },
  {
    name: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    callingCodes: ['1'],
    nativeName: 'Barbados',
    flags: {
      svg: 'https://flagcdn.com/bb.svg',
      png: 'https://flagcdn.com/w320/bb.png',
    },
  },
  {
    name: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    callingCodes: ['375'],
    nativeName: 'Белару́сь',
    flags: {
      svg: 'https://flagcdn.com/by.svg',
      png: 'https://flagcdn.com/w320/by.png',
    },
  },
  {
    name: 'Belgium',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    callingCodes: ['32'],
    nativeName: 'België',
    flags: {
      svg: 'https://flagcdn.com/be.svg',
      png: 'https://flagcdn.com/w320/be.png',
    },
  },
  {
    name: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    callingCodes: ['501'],
    nativeName: 'Belize',
    flags: {
      svg: 'https://flagcdn.com/bz.svg',
      png: 'https://flagcdn.com/w320/bz.png',
    },
  },
  {
    name: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    callingCodes: ['229'],
    nativeName: 'Bénin',
    flags: {
      svg: 'https://flagcdn.com/bj.svg',
      png: 'https://flagcdn.com/w320/bj.png',
    },
  },
  {
    name: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    callingCodes: ['1'],
    nativeName: 'Bermuda',
    flags: {
      svg: 'https://flagcdn.com/bm.svg',
      png: 'https://flagcdn.com/w320/bm.png',
    },
  },
  {
    name: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    callingCodes: ['975'],
    nativeName: 'ʼbrug-yul',
    flags: {
      svg: 'https://flagcdn.com/bt.svg',
      png: 'https://flagcdn.com/w320/bt.png',
    },
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    callingCodes: ['591'],
    nativeName: 'Bolivia',
    flags: {
      svg: 'https://flagcdn.com/bo.svg',
      png: 'https://flagcdn.com/w320/bo.png',
    },
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    callingCodes: ['599'],
    nativeName: 'Bonaire',
    flags: {
      svg: 'https://flagcdn.com/bq.svg',
      png: 'https://flagcdn.com/w320/bq.png',
    },
  },
  {
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    callingCodes: ['387'],
    nativeName: 'Bosna i Hercegovina',
    flags: {
      svg: 'https://flagcdn.com/ba.svg',
      png: 'https://flagcdn.com/w320/ba.png',
    },
  },
  {
    name: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    callingCodes: ['267'],
    nativeName: 'Botswana',
    flags: {
      svg: 'https://flagcdn.com/bw.svg',
      png: 'https://flagcdn.com/w320/bw.png',
    },
  },
  {
    name: 'Bouvet Island',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    callingCodes: ['47'],
    nativeName: 'Bouvetøya',
    flags: {
      svg: 'https://flagcdn.com/bv.svg',
      png: 'https://flagcdn.com/w320/bv.png',
    },
  },
  {
    name: 'Brazil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    callingCodes: ['55'],
    nativeName: 'Brasil',
    flags: {
      svg: 'https://flagcdn.com/br.svg',
      png: 'https://flagcdn.com/w320/br.png',
    },
  },
  {
    name: 'British Indian Ocean Territory',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    callingCodes: ['246'],
    nativeName: 'British Indian Ocean Territory',
    flags: {
      svg: 'https://flagcdn.com/io.svg',
      png: 'https://flagcdn.com/w320/io.png',
    },
  },
  {
    name: 'United States Minor Outlying Islands',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    callingCodes: ['246'],
    nativeName: 'United States Minor Outlying Islands',
    flags: {
      svg: 'https://flagcdn.com/um.svg',
      png: 'https://flagcdn.com/w320/um.png',
    },
  },
  {
    name: 'Virgin Islands (British)',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    callingCodes: ['1'],
    nativeName: 'British Virgin Islands',
    flags: {
      svg: 'https://flagcdn.com/vg.svg',
      png: 'https://flagcdn.com/w320/vg.png',
    },
  },
  {
    name: 'Virgin Islands (U.S.)',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    callingCodes: ['1 340'],
    nativeName: 'Virgin Islands of the United States',
    flags: {
      svg: 'https://flagcdn.com/vi.svg',
      png: 'https://flagcdn.com/w320/vi.png',
    },
  },
  {
    name: 'Brunei Darussalam',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    callingCodes: ['673'],
    nativeName: 'Negara Brunei Darussalam',
    flags: {
      svg: 'https://flagcdn.com/bn.svg',
      png: 'https://flagcdn.com/w320/bn.png',
    },
  },
  {
    name: 'Bulgaria',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    callingCodes: ['359'],
    nativeName: 'България',
    flags: {
      svg: 'https://flagcdn.com/bg.svg',
      png: 'https://flagcdn.com/w320/bg.png',
    },
  },
  {
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    callingCodes: ['226'],
    nativeName: 'Burkina Faso',
    flags: {
      svg: 'https://flagcdn.com/bf.svg',
      png: 'https://flagcdn.com/w320/bf.png',
    },
  },
  {
    name: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    callingCodes: ['257'],
    nativeName: 'Burundi',
    flags: {
      svg: 'https://flagcdn.com/bi.svg',
      png: 'https://flagcdn.com/w320/bi.png',
    },
  },
  {
    name: 'Cambodia',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    callingCodes: ['855'],
    nativeName: 'Kâmpŭchéa',
    flags: {
      svg: 'https://flagcdn.com/kh.svg',
      png: 'https://flagcdn.com/w320/kh.png',
    },
  },
  {
    name: 'Cameroon',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    callingCodes: ['237'],
    nativeName: 'Cameroon',
    flags: {
      svg: 'https://flagcdn.com/cm.svg',
      png: 'https://flagcdn.com/w320/cm.png',
    },
  },
  {
    name: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    callingCodes: ['1'],
    nativeName: 'Canada',
    flags: {
      svg: 'https://flagcdn.com/ca.svg',
      png: 'https://flagcdn.com/w320/ca.png',
    },
  },
  {
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    callingCodes: ['238'],
    nativeName: 'Cabo Verde',
    flags: {
      svg: 'https://flagcdn.com/cv.svg',
      png: 'https://flagcdn.com/w320/cv.png',
    },
  },
  {
    name: 'Cayman Islands',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    callingCodes: ['1'],
    nativeName: 'Cayman Islands',
    flags: {
      svg: 'https://flagcdn.com/ky.svg',
      png: 'https://flagcdn.com/w320/ky.png',
    },
  },
  {
    name: 'Central African Republic',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    callingCodes: ['236'],
    nativeName: 'Ködörösêse tî Bêafrîka',
    flags: {
      svg: 'https://flagcdn.com/cf.svg',
      png: 'https://flagcdn.com/w320/cf.png',
    },
  },
  {
    name: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    callingCodes: ['235'],
    nativeName: 'Tchad',
    flags: {
      svg: 'https://flagcdn.com/td.svg',
      png: 'https://flagcdn.com/w320/td.png',
    },
  },
  {
    name: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    callingCodes: ['56'],
    nativeName: 'Chile',
    flags: {
      svg: 'https://flagcdn.com/cl.svg',
      png: 'https://flagcdn.com/w320/cl.png',
    },
  },
  {
    name: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    callingCodes: ['86'],
    nativeName: '中国',
    flags: {
      svg: 'https://flagcdn.com/cn.svg',
      png: 'https://flagcdn.com/w320/cn.png',
    },
  },
  {
    name: 'Christmas Island',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    callingCodes: ['61'],
    nativeName: 'Christmas Island',
    flags: {
      svg: 'https://flagcdn.com/cx.svg',
      png: 'https://flagcdn.com/w320/cx.png',
    },
  },
  {
    name: 'Cocos (Keeling) Islands',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    callingCodes: ['61'],
    nativeName: 'Cocos (Keeling) Islands',
    flags: {
      svg: 'https://flagcdn.com/cc.svg',
      png: 'https://flagcdn.com/w320/cc.png',
    },
  },
  {
    name: 'Colombia',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    callingCodes: ['57'],
    nativeName: 'Colombia',
    flags: {
      svg: 'https://flagcdn.com/co.svg',
      png: 'https://flagcdn.com/w320/co.png',
    },
  },
  {
    name: 'Comoros',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    callingCodes: ['269'],
    nativeName: 'Komori',
    flags: {
      svg: 'https://flagcdn.com/km.svg',
      png: 'https://flagcdn.com/w320/km.png',
    },
  },
  {
    name: 'Congo',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    callingCodes: ['242'],
    nativeName: 'République du Congo',
    flags: {
      svg: 'https://flagcdn.com/cg.svg',
      png: 'https://flagcdn.com/w320/cg.png',
    },
  },
  {
    name: 'Congo (Democratic Republic of the)',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    callingCodes: ['243'],
    nativeName: 'République démocratique du Congo',
    flags: {
      svg: 'https://flagcdn.com/cd.svg',
      png: 'https://flagcdn.com/w320/cd.png',
    },
  },
  {
    name: 'Cook Islands',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    callingCodes: ['682'],
    nativeName: 'Cook Islands',
    flags: {
      svg: 'https://flagcdn.com/ck.svg',
      png: 'https://flagcdn.com/w320/ck.png',
    },
  },
  {
    name: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    callingCodes: ['506'],
    nativeName: 'Costa Rica',
    flags: {
      svg: 'https://flagcdn.com/cr.svg',
      png: 'https://flagcdn.com/w320/cr.png',
    },
  },
  {
    name: 'Croatia',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    callingCodes: ['385'],
    nativeName: 'Hrvatska',
    flags: {
      svg: 'https://flagcdn.com/hr.svg',
      png: 'https://flagcdn.com/w320/hr.png',
    },
  },
  {
    name: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    callingCodes: ['53'],
    nativeName: 'Cuba',
    flags: {
      svg: 'https://flagcdn.com/cu.svg',
      png: 'https://flagcdn.com/w320/cu.png',
    },
  },
  {
    name: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    callingCodes: ['599'],
    nativeName: 'Curaçao',
    flags: {
      svg: 'https://flagcdn.com/cw.svg',
      png: 'https://flagcdn.com/w320/cw.png',
    },
  },
  {
    name: 'Cyprus',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    callingCodes: ['357'],
    nativeName: 'Κύπρος',
    flags: {
      svg: 'https://flagcdn.com/cy.svg',
      png: 'https://flagcdn.com/w320/cy.png',
    },
  },
  {
    name: 'Czech Republic',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    callingCodes: ['420'],
    nativeName: 'Česká republika',
    flags: {
      svg: 'https://flagcdn.com/cz.svg',
      png: 'https://flagcdn.com/w320/cz.png',
    },
  },
  {
    name: 'Denmark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    callingCodes: ['45'],
    nativeName: 'Danmark',
    flags: {
      svg: 'https://flagcdn.com/dk.svg',
      png: 'https://flagcdn.com/w320/dk.png',
    },
  },
  {
    name: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    callingCodes: ['253'],
    nativeName: 'Djibouti',
    flags: {
      svg: 'https://flagcdn.com/dj.svg',
      png: 'https://flagcdn.com/w320/dj.png',
    },
  },
  {
    name: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    callingCodes: ['1'],
    nativeName: 'Dominica',
    flags: {
      svg: 'https://flagcdn.com/dm.svg',
      png: 'https://flagcdn.com/w320/dm.png',
    },
  },
  {
    name: 'Dominican Republic',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    callingCodes: ['1'],
    nativeName: 'República Dominicana',
    flags: {
      svg: 'https://flagcdn.com/do.svg',
      png: 'https://flagcdn.com/w320/do.png',
    },
  },
  {
    name: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    callingCodes: ['593'],
    nativeName: 'Ecuador',
    flags: {
      svg: 'https://flagcdn.com/ec.svg',
      png: 'https://flagcdn.com/w320/ec.png',
    },
  },
  {
    name: 'Egypt',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    callingCodes: ['20'],
    nativeName: 'مصر‎',
    flags: {
      svg: 'https://flagcdn.com/eg.svg',
      png: 'https://flagcdn.com/w320/eg.png',
    },
  },
  {
    name: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    callingCodes: ['503'],
    nativeName: 'El Salvador',
    flags: {
      svg: 'https://flagcdn.com/sv.svg',
      png: 'https://flagcdn.com/w320/sv.png',
    },
  },
  {
    name: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    callingCodes: ['240'],
    nativeName: 'Guinea Ecuatorial',
    flags: {
      svg: 'https://flagcdn.com/gq.svg',
      png: 'https://flagcdn.com/w320/gq.png',
    },
  },
  {
    name: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    callingCodes: ['291'],
    nativeName: 'ኤርትራ',
    flags: {
      svg: 'https://flagcdn.com/er.svg',
      png: 'https://flagcdn.com/w320/er.png',
    },
  },
  {
    name: 'Estonia',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    callingCodes: ['372'],
    nativeName: 'Eesti',
    flags: {
      svg: 'https://flagcdn.com/ee.svg',
      png: 'https://flagcdn.com/w320/ee.png',
    },
  },
  {
    name: 'Ethiopia',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    callingCodes: ['251'],
    nativeName: 'ኢትዮጵያ',
    flags: {
      svg: 'https://flagcdn.com/et.svg',
      png: 'https://flagcdn.com/w320/et.png',
    },
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    callingCodes: ['500'],
    nativeName: 'Falkland Islands',
    flags: {
      svg: 'https://flagcdn.com/fk.svg',
      png: 'https://flagcdn.com/w320/fk.png',
    },
  },
  {
    name: 'Faroe Islands',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    callingCodes: ['298'],
    nativeName: 'Føroyar',
    flags: {
      svg: 'https://flagcdn.com/fo.svg',
      png: 'https://flagcdn.com/w320/fo.png',
    },
  },
  {
    name: 'Fiji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    callingCodes: ['679'],
    nativeName: 'Fiji',
    flags: {
      svg: 'https://flagcdn.com/fj.svg',
      png: 'https://flagcdn.com/w320/fj.png',
    },
  },
  {
    name: 'Finland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    callingCodes: ['358'],
    nativeName: 'Suomi',
    flags: {
      svg: 'https://flagcdn.com/fi.svg',
      png: 'https://flagcdn.com/w320/fi.png',
    },
  },
  {
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    callingCodes: ['33'],
    nativeName: 'France',
    flags: {
      svg: 'https://flagcdn.com/fr.svg',
      png: 'https://flagcdn.com/w320/fr.png',
    },
  },
  {
    name: 'French Guiana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    callingCodes: ['594'],
    nativeName: 'Guyane française',
    flags: {
      svg: 'https://flagcdn.com/gf.svg',
      png: 'https://flagcdn.com/w320/gf.png',
    },
  },
  {
    name: 'French Polynesia',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    callingCodes: ['689'],
    nativeName: 'Polynésie française',
    flags: {
      svg: 'https://flagcdn.com/pf.svg',
      png: 'https://flagcdn.com/w320/pf.png',
    },
  },
  {
    name: 'French Southern Territories',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    callingCodes: ['262'],
    nativeName: 'Territoire des Terres australes et antarctiques françaises',
    flags: {
      svg: 'https://flagcdn.com/tf.svg',
      png: 'https://flagcdn.com/w320/tf.png',
    },
  },
  {
    name: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    callingCodes: ['241'],
    nativeName: 'Gabon',
    flags: {
      svg: 'https://flagcdn.com/ga.svg',
      png: 'https://flagcdn.com/w320/ga.png',
    },
  },
  {
    name: 'Gambia',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    callingCodes: ['220'],
    nativeName: 'Gambia',
    flags: {
      svg: 'https://flagcdn.com/gm.svg',
      png: 'https://flagcdn.com/w320/gm.png',
    },
  },
  {
    name: 'Georgia',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    callingCodes: ['995'],
    nativeName: 'საქართველო',
    flags: {
      svg: 'https://flagcdn.com/ge.svg',
      png: 'https://flagcdn.com/w320/ge.png',
    },
  },
  {
    name: 'Germany',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    callingCodes: ['49'],
    nativeName: 'Deutschland',
    flags: {
      svg: 'https://flagcdn.com/de.svg',
      png: 'https://flagcdn.com/w320/de.png',
    },
  },
  {
    name: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    callingCodes: ['233'],
    nativeName: 'Ghana',
    flags: {
      svg: 'https://flagcdn.com/gh.svg',
      png: 'https://flagcdn.com/w320/gh.png',
    },
  },
  {
    name: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    callingCodes: ['350'],
    nativeName: 'Gibraltar',
    flags: {
      svg: 'https://flagcdn.com/gi.svg',
      png: 'https://flagcdn.com/w320/gi.png',
    },
  },
  {
    name: 'Greece',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    callingCodes: ['30'],
    nativeName: 'Ελλάδα',
    flags: {
      svg: 'https://flagcdn.com/gr.svg',
      png: 'https://flagcdn.com/w320/gr.png',
    },
  },
  {
    name: 'Greenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    callingCodes: ['299'],
    nativeName: 'Kalaallit Nunaat',
    flags: {
      svg: 'https://flagcdn.com/gl.svg',
      png: 'https://flagcdn.com/w320/gl.png',
    },
  },
  {
    name: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    callingCodes: ['1'],
    nativeName: 'Grenada',
    flags: {
      svg: 'https://flagcdn.com/gd.svg',
      png: 'https://flagcdn.com/w320/gd.png',
    },
  },
  {
    name: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    callingCodes: ['590'],
    nativeName: 'Guadeloupe',
    flags: {
      svg: 'https://flagcdn.com/gp.svg',
      png: 'https://flagcdn.com/w320/gp.png',
    },
  },
  {
    name: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    callingCodes: ['1'],
    nativeName: 'Guam',
    flags: {
      svg: 'https://flagcdn.com/gu.svg',
      png: 'https://flagcdn.com/w320/gu.png',
    },
  },
  {
    name: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    callingCodes: ['502'],
    nativeName: 'Guatemala',
    flags: {
      svg: 'https://flagcdn.com/gt.svg',
      png: 'https://flagcdn.com/w320/gt.png',
    },
  },
  {
    name: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    callingCodes: ['44'],
    nativeName: 'Guernsey',
    flags: {
      svg: 'https://flagcdn.com/gg.svg',
      png: 'https://flagcdn.com/w320/gg.png',
    },
  },
  {
    name: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    callingCodes: ['224'],
    nativeName: 'Guinée',
    flags: {
      svg: 'https://flagcdn.com/gn.svg',
      png: 'https://flagcdn.com/w320/gn.png',
    },
  },
  {
    name: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    callingCodes: ['245'],
    nativeName: 'Guiné-Bissau',
    flags: {
      svg: 'https://flagcdn.com/gw.svg',
      png: 'https://flagcdn.com/w320/gw.png',
    },
  },
  {
    name: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    callingCodes: ['592'],
    nativeName: 'Guyana',
    flags: {
      svg: 'https://flagcdn.com/gy.svg',
      png: 'https://flagcdn.com/w320/gy.png',
    },
  },
  {
    name: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    callingCodes: ['509'],
    nativeName: 'Haïti',
    flags: {
      svg: 'https://flagcdn.com/ht.svg',
      png: 'https://flagcdn.com/w320/ht.png',
    },
  },
  {
    name: 'Heard Island and McDonald Islands',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    callingCodes: ['672'],
    nativeName: 'Heard Island and McDonald Islands',
    flags: {
      svg: 'https://flagcdn.com/hm.svg',
      png: 'https://flagcdn.com/w320/hm.png',
    },
  },
  {
    name: 'Vatican City',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    callingCodes: ['379'],
    nativeName: 'Status Civitatis Vaticanae',
    flags: {
      svg: 'https://flagcdn.com/va.svg',
      png: 'https://flagcdn.com/w320/va.png',
    },
  },
  {
    name: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    callingCodes: ['504'],
    nativeName: 'Honduras',
    flags: {
      svg: 'https://flagcdn.com/hn.svg',
      png: 'https://flagcdn.com/w320/hn.png',
    },
  },
  {
    name: 'Hungary',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    callingCodes: ['36'],
    nativeName: 'Magyarország',
    flags: {
      svg: 'https://flagcdn.com/hu.svg',
      png: 'https://flagcdn.com/w320/hu.png',
    },
  },
  {
    name: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    callingCodes: ['852'],
    nativeName: '香港',
    flags: {
      svg: 'https://flagcdn.com/hk.svg',
      png: 'https://flagcdn.com/w320/hk.png',
    },
  },
  {
    name: 'Iceland',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    callingCodes: ['354'],
    nativeName: 'Ísland',
    flags: {
      svg: 'https://flagcdn.com/is.svg',
      png: 'https://flagcdn.com/w320/is.png',
    },
  },
  {
    name: 'India',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    callingCodes: ['91'],
    nativeName: 'भारत',
    flags: {
      svg: 'https://flagcdn.com/in.svg',
      png: 'https://flagcdn.com/w320/in.png',
    },
  },
  {
    name: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    callingCodes: ['62'],
    nativeName: 'Indonesia',
    flags: {
      svg: 'https://flagcdn.com/id.svg',
      png: 'https://flagcdn.com/w320/id.png',
    },
  },
  {
    name: 'Ivory Coast',
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    callingCodes: ['225'],
    nativeName: "Côte d'Ivoire",
    flags: {
      svg: 'https://flagcdn.com/ci.svg',
      png: 'https://flagcdn.com/w320/ci.png',
    },
  },
  {
    name: 'Iran (Islamic Republic of)',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    callingCodes: ['98'],
    nativeName: 'ایران',
    flags: {
      svg: 'https://flagcdn.com/ir.svg',
      png: 'https://flagcdn.com/w320/ir.png',
    },
  },
  {
    name: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    callingCodes: ['964'],
    nativeName: 'العراق',
    flags: {
      svg: 'https://flagcdn.com/iq.svg',
      png: 'https://flagcdn.com/w320/iq.png',
    },
  },
  {
    name: 'Ireland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    callingCodes: ['353'],
    nativeName: 'Éire',
    flags: {
      svg: 'https://flagcdn.com/ie.svg',
      png: 'https://flagcdn.com/w320/ie.png',
    },
  },
  {
    name: 'Isle of Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    callingCodes: ['44'],
    nativeName: 'Isle of Man',
    flags: {
      svg: 'https://flagcdn.com/im.svg',
      png: 'https://flagcdn.com/w320/im.png',
    },
  },
  {
    name: 'Israel',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    callingCodes: ['972'],
    nativeName: 'יִשְׂרָאֵל',
    flags: {
      svg: 'https://flagcdn.com/il.svg',
      png: 'https://flagcdn.com/w320/il.png',
    },
  },
  {
    name: 'Italy',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    callingCodes: ['39'],
    nativeName: 'Italia',
    flags: {
      svg: 'https://flagcdn.com/it.svg',
      png: 'https://flagcdn.com/w320/it.png',
    },
  },
  {
    name: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    callingCodes: ['1'],
    nativeName: 'Jamaica',
    flags: {
      svg: 'https://flagcdn.com/jm.svg',
      png: 'https://flagcdn.com/w320/jm.png',
    },
  },
  {
    name: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    callingCodes: ['81'],
    nativeName: '日本',
    flags: {
      svg: 'https://flagcdn.com/jp.svg',
      png: 'https://flagcdn.com/w320/jp.png',
    },
  },
  {
    name: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    callingCodes: ['44'],
    nativeName: 'Jersey',
    flags: {
      svg: 'https://flagcdn.com/je.svg',
      png: 'https://flagcdn.com/w320/je.png',
    },
  },
  {
    name: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    callingCodes: ['962'],
    nativeName: 'الأردن',
    flags: {
      svg: 'https://flagcdn.com/jo.svg',
      png: 'https://flagcdn.com/w320/jo.png',
    },
  },
  {
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    callingCodes: ['76', '77'],
    nativeName: 'Қазақстан',
    flags: {
      svg: 'https://flagcdn.com/kz.svg',
      png: 'https://flagcdn.com/w320/kz.png',
    },
  },
  {
    name: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    callingCodes: ['254'],
    nativeName: 'Kenya',
    flags: {
      svg: 'https://flagcdn.com/ke.svg',
      png: 'https://flagcdn.com/w320/ke.png',
    },
  },
  {
    name: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    callingCodes: ['686'],
    nativeName: 'Kiribati',
    flags: {
      svg: 'https://flagcdn.com/ki.svg',
      png: 'https://flagcdn.com/w320/ki.png',
    },
  },
  {
    name: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    callingCodes: ['965'],
    nativeName: 'الكويت',
    flags: {
      svg: 'https://flagcdn.com/kw.svg',
      png: 'https://flagcdn.com/w320/kw.png',
    },
  },
  {
    name: 'Kyrgyzstan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    callingCodes: ['996'],
    nativeName: 'Кыргызстан',
    flags: {
      svg: 'https://flagcdn.com/kg.svg',
      png: 'https://flagcdn.com/w320/kg.png',
    },
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    callingCodes: ['856'],
    nativeName: 'ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ',
    flags: {
      svg: 'https://flagcdn.com/la.svg',
      png: 'https://flagcdn.com/w320/la.png',
    },
  },
  {
    name: 'Latvia',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    callingCodes: ['371'],
    nativeName: 'Latvija',
    flags: {
      svg: 'https://flagcdn.com/lv.svg',
      png: 'https://flagcdn.com/w320/lv.png',
    },
  },
  {
    name: 'Lebanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    callingCodes: ['961'],
    nativeName: 'لبنان',
    flags: {
      svg: 'https://flagcdn.com/lb.svg',
      png: 'https://flagcdn.com/w320/lb.png',
    },
  },
  {
    name: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    callingCodes: ['266'],
    nativeName: 'Lesotho',
    flags: {
      svg: 'https://flagcdn.com/ls.svg',
      png: 'https://flagcdn.com/w320/ls.png',
    },
  },
  {
    name: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    callingCodes: ['231'],
    nativeName: 'Liberia',
    flags: {
      svg: 'https://flagcdn.com/lr.svg',
      png: 'https://flagcdn.com/w320/lr.png',
    },
  },
  {
    name: 'Libya',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    callingCodes: ['218'],
    nativeName: '‏ليبيا',
    flags: {
      svg: 'https://flagcdn.com/ly.svg',
      png: 'https://flagcdn.com/w320/ly.png',
    },
  },
  {
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    callingCodes: ['423'],
    nativeName: 'Liechtenstein',
    flags: {
      svg: 'https://flagcdn.com/li.svg',
      png: 'https://flagcdn.com/w320/li.png',
    },
  },
  {
    name: 'Lithuania',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    callingCodes: ['370'],
    nativeName: 'Lietuva',
    flags: {
      svg: 'https://flagcdn.com/lt.svg',
      png: 'https://flagcdn.com/w320/lt.png',
    },
  },
  {
    name: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    callingCodes: ['352'],
    nativeName: 'Lëtzebuerg',
    flags: {
      svg: 'https://flagcdn.com/lu.svg',
      png: 'https://flagcdn.com/w320/lu.png',
    },
  },
  {
    name: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    callingCodes: ['853'],
    nativeName: '澳門',
    flags: {
      svg: 'https://flagcdn.com/mo.svg',
      png: 'https://flagcdn.com/w320/mo.png',
    },
  },
  {
    name: 'North Macedonia',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    callingCodes: ['389'],
    nativeName: 'Македонија',
    flags: {
      svg: 'https://flagcdn.com/mk.svg',
      png: 'https://flagcdn.com/w320/mk.png',
    },
  },
  {
    name: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    callingCodes: ['261'],
    nativeName: 'Madagasikara',
    flags: {
      svg: 'https://flagcdn.com/mg.svg',
      png: 'https://flagcdn.com/w320/mg.png',
    },
  },
  {
    name: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    callingCodes: ['265'],
    nativeName: 'Malawi',
    flags: {
      svg: 'https://flagcdn.com/mw.svg',
      png: 'https://flagcdn.com/w320/mw.png',
    },
  },
  {
    name: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    callingCodes: ['60'],
    nativeName: 'Malaysia',
    flags: {
      svg: 'https://flagcdn.com/my.svg',
      png: 'https://flagcdn.com/w320/my.png',
    },
  },
  {
    name: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    callingCodes: ['960'],
    nativeName: 'Maldives',
    flags: {
      svg: 'https://flagcdn.com/mv.svg',
      png: 'https://flagcdn.com/w320/mv.png',
    },
  },
  {
    name: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    callingCodes: ['223'],
    nativeName: 'Mali',
    flags: {
      svg: 'https://flagcdn.com/ml.svg',
      png: 'https://flagcdn.com/w320/ml.png',
    },
  },
  {
    name: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    callingCodes: ['356'],
    nativeName: 'Malta',
    flags: {
      svg: 'https://flagcdn.com/mt.svg',
      png: 'https://flagcdn.com/w320/mt.png',
    },
  },
  {
    name: 'Marshall Islands',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    callingCodes: ['692'],
    nativeName: 'M̧ajeļ',
    flags: {
      svg: 'https://flagcdn.com/mh.svg',
      png: 'https://flagcdn.com/w320/mh.png',
    },
  },
  {
    name: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    callingCodes: ['596'],
    nativeName: 'Martinique',
    flags: {
      svg: 'https://flagcdn.com/mq.svg',
      png: 'https://flagcdn.com/w320/mq.png',
    },
  },
  {
    name: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    callingCodes: ['222'],
    nativeName: 'موريتانيا',
    flags: {
      svg: 'https://flagcdn.com/mr.svg',
      png: 'https://flagcdn.com/w320/mr.png',
    },
  },
  {
    name: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    callingCodes: ['230'],
    nativeName: 'Maurice',
    flags: {
      svg: 'https://flagcdn.com/mu.svg',
      png: 'https://flagcdn.com/w320/mu.png',
    },
  },
  {
    name: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    callingCodes: ['262'],
    nativeName: 'Mayotte',
    flags: {
      svg: 'https://flagcdn.com/yt.svg',
      png: 'https://flagcdn.com/w320/yt.png',
    },
  },
  {
    name: 'Mexico',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    callingCodes: ['52'],
    nativeName: 'México',
    flags: {
      svg: 'https://flagcdn.com/mx.svg',
      png: 'https://flagcdn.com/w320/mx.png',
    },
  },
  {
    name: 'Micronesia (Federated States of)',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    callingCodes: ['691'],
    nativeName: 'Micronesia',
    flags: {
      svg: 'https://flagcdn.com/fm.svg',
      png: 'https://flagcdn.com/w320/fm.png',
    },
  },
  {
    name: 'Moldova (Republic of)',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    callingCodes: ['373'],
    nativeName: 'Moldova',
    flags: {
      svg: 'https://flagcdn.com/md.svg',
      png: 'https://flagcdn.com/w320/md.png',
    },
  },
  {
    name: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    callingCodes: ['377'],
    nativeName: 'Monaco',
    flags: {
      svg: 'https://flagcdn.com/mc.svg',
      png: 'https://flagcdn.com/w320/mc.png',
    },
  },
  {
    name: 'Mongolia',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    callingCodes: ['976'],
    nativeName: 'Монгол улс',
    flags: {
      svg: 'https://flagcdn.com/mn.svg',
      png: 'https://flagcdn.com/w320/mn.png',
    },
  },
  {
    name: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    callingCodes: ['382'],
    nativeName: 'Црна Гора',
    flags: {
      svg: 'https://flagcdn.com/me.svg',
      png: 'https://flagcdn.com/w320/me.png',
    },
  },
  {
    name: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    callingCodes: ['1'],
    nativeName: 'Montserrat',
    flags: {
      svg: 'https://flagcdn.com/ms.svg',
      png: 'https://flagcdn.com/w320/ms.png',
    },
  },
  {
    name: 'Morocco',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    callingCodes: ['212'],
    nativeName: 'المغرب',
    flags: {
      svg: 'https://flagcdn.com/ma.svg',
      png: 'https://flagcdn.com/w320/ma.png',
    },
  },
  {
    name: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    callingCodes: ['258'],
    nativeName: 'Moçambique',
    flags: {
      svg: 'https://flagcdn.com/mz.svg',
      png: 'https://flagcdn.com/w320/mz.png',
    },
  },
  {
    name: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    callingCodes: ['95'],
    nativeName: 'Myanma',
    flags: {
      svg: 'https://flagcdn.com/mm.svg',
      png: 'https://flagcdn.com/w320/mm.png',
    },
  },
  {
    name: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    callingCodes: ['264'],
    nativeName: 'Namibia',
    flags: {
      svg: 'https://flagcdn.com/na.svg',
      png: 'https://flagcdn.com/w320/na.png',
    },
  },
  {
    name: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    callingCodes: ['674'],
    nativeName: 'Nauru',
    flags: {
      svg: 'https://flagcdn.com/nr.svg',
      png: 'https://flagcdn.com/w320/nr.png',
    },
  },
  {
    name: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    callingCodes: ['977'],
    nativeName: 'नेपाल',
    flags: {
      svg: 'https://flagcdn.com/np.svg',
      png: 'https://flagcdn.com/w320/np.png',
    },
  },
  {
    name: 'Netherlands',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    callingCodes: ['31'],
    nativeName: 'Nederland',
    flags: {
      svg: 'https://flagcdn.com/nl.svg',
      png: 'https://flagcdn.com/w320/nl.png',
    },
  },
  {
    name: 'New Caledonia',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    callingCodes: ['687'],
    nativeName: 'Nouvelle-Calédonie',
    flags: {
      svg: 'https://flagcdn.com/nc.svg',
      png: 'https://flagcdn.com/w320/nc.png',
    },
  },
  {
    name: 'New Zealand',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    callingCodes: ['64'],
    nativeName: 'New Zealand',
    flags: {
      svg: 'https://flagcdn.com/nz.svg',
      png: 'https://flagcdn.com/w320/nz.png',
    },
  },
  {
    name: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    callingCodes: ['505'],
    nativeName: 'Nicaragua',
    flags: {
      svg: 'https://flagcdn.com/ni.svg',
      png: 'https://flagcdn.com/w320/ni.png',
    },
  },
  {
    name: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    callingCodes: ['227'],
    nativeName: 'Niger',
    flags: {
      svg: 'https://flagcdn.com/ne.svg',
      png: 'https://flagcdn.com/w320/ne.png',
    },
  },
  {
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    callingCodes: ['234'],
    nativeName: 'Nigeria',
    flags: {
      svg: 'https://flagcdn.com/ng.svg',
      png: 'https://flagcdn.com/w320/ng.png',
    },
  },
  {
    name: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    callingCodes: ['683'],
    nativeName: 'Niuē',
    flags: {
      svg: 'https://flagcdn.com/nu.svg',
      png: 'https://flagcdn.com/w320/nu.png',
    },
  },
  {
    name: 'Norfolk Island',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    callingCodes: ['672'],
    nativeName: 'Norfolk Island',
    flags: {
      svg: 'https://flagcdn.com/nf.svg',
      png: 'https://flagcdn.com/w320/nf.png',
    },
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    callingCodes: ['850'],
    nativeName: '북한',
    flags: {
      svg: 'https://flagcdn.com/kp.svg',
      png: 'https://flagcdn.com/w320/kp.png',
    },
  },
  {
    name: 'Northern Mariana Islands',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    callingCodes: ['1'],
    nativeName: 'Northern Mariana Islands',
    flags: {
      svg: 'https://flagcdn.com/mp.svg',
      png: 'https://flagcdn.com/w320/mp.png',
    },
  },
  {
    name: 'Norway',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    callingCodes: ['47'],
    nativeName: 'Norge',
    flags: {
      svg: 'https://flagcdn.com/no.svg',
      png: 'https://flagcdn.com/w320/no.png',
    },
  },
  {
    name: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    callingCodes: ['968'],
    nativeName: 'عمان',
    flags: {
      svg: 'https://flagcdn.com/om.svg',
      png: 'https://flagcdn.com/w320/om.png',
    },
  },
  {
    name: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    callingCodes: ['92'],
    nativeName: 'Pakistan',
    flags: {
      svg: 'https://flagcdn.com/pk.svg',
      png: 'https://flagcdn.com/w320/pk.png',
    },
  },
  {
    name: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    callingCodes: ['680'],
    nativeName: 'Palau',
    flags: {
      svg: 'https://flagcdn.com/pw.svg',
      png: 'https://flagcdn.com/w320/pw.png',
    },
  },
  {
    name: 'Palestine, State of',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    callingCodes: ['970'],
    nativeName: 'فلسطين',
    flags: {
      svg: 'https://flagcdn.com/ps.svg',
      png: 'https://flagcdn.com/w320/ps.png',
    },
  },
  {
    name: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    callingCodes: ['507'],
    nativeName: 'Panamá',
    flags: {
      svg: 'https://flagcdn.com/pa.svg',
      png: 'https://flagcdn.com/w320/pa.png',
    },
  },
  {
    name: 'Papua New Guinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    callingCodes: ['675'],
    nativeName: 'Papua Niugini',
    flags: {
      svg: 'https://flagcdn.com/pg.svg',
      png: 'https://flagcdn.com/w320/pg.png',
    },
  },
  {
    name: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    callingCodes: ['595'],
    nativeName: 'Paraguay',
    flags: {
      svg: 'https://flagcdn.com/py.svg',
      png: 'https://flagcdn.com/w320/py.png',
    },
  },
  {
    name: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    callingCodes: ['51'],
    nativeName: 'Perú',
    flags: {
      svg: 'https://flagcdn.com/pe.svg',
      png: 'https://flagcdn.com/w320/pe.png',
    },
  },
  {
    name: 'Philippines',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    callingCodes: ['63'],
    nativeName: 'Pilipinas',
    flags: {
      svg: 'https://flagcdn.com/ph.svg',
      png: 'https://flagcdn.com/w320/ph.png',
    },
  },
  {
    name: 'Pitcairn',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    callingCodes: ['64'],
    nativeName: 'Pitcairn Islands',
    flags: {
      svg: 'https://flagcdn.com/pn.svg',
      png: 'https://flagcdn.com/w320/pn.png',
    },
  },
  {
    name: 'Poland',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    callingCodes: ['48'],
    nativeName: 'Polska',
    flags: {
      svg: 'https://flagcdn.com/pl.svg',
      png: 'https://flagcdn.com/w320/pl.png',
    },
  },
  {
    name: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    callingCodes: ['351'],
    nativeName: 'Portugal',
    flags: {
      svg: 'https://flagcdn.com/pt.svg',
      png: 'https://flagcdn.com/w320/pt.png',
    },
  },
  {
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    callingCodes: ['1'],
    nativeName: 'Puerto Rico',
    flags: {
      svg: 'https://flagcdn.com/pr.svg',
      png: 'https://flagcdn.com/w320/pr.png',
    },
  },
  {
    name: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    callingCodes: ['974'],
    nativeName: 'قطر',
    flags: {
      svg: 'https://flagcdn.com/qa.svg',
      png: 'https://flagcdn.com/w320/qa.png',
    },
  },
  {
    name: 'Republic of Kosovo',
    alpha2Code: 'XK',
    alpha3Code: 'UNK',
    callingCodes: ['383'],
    nativeName: 'Republika e Kosovës',
    flags: {
      svg: 'https://flagcdn.com/xk.svg',
      png: 'https://flagcdn.com/w320/xk.png',
    },
  },
  {
    name: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    callingCodes: ['262'],
    nativeName: 'La Réunion',
    flags: {
      svg: 'https://flagcdn.com/re.svg',
      png: 'https://flagcdn.com/w320/re.png',
    },
  },
  {
    name: 'Romania',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    callingCodes: ['40'],
    nativeName: 'România',
    flags: {
      svg: 'https://flagcdn.com/ro.svg',
      png: 'https://flagcdn.com/w320/ro.png',
    },
  },
  {
    name: 'Russian Federation',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    callingCodes: ['7'],
    nativeName: 'Россия',
    flags: {
      svg: 'https://flagcdn.com/ru.svg',
      png: 'https://flagcdn.com/w320/ru.png',
    },
  },
  {
    name: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    callingCodes: ['250'],
    nativeName: 'Rwanda',
    flags: {
      svg: 'https://flagcdn.com/rw.svg',
      png: 'https://flagcdn.com/w320/rw.png',
    },
  },
  {
    name: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    callingCodes: ['590'],
    nativeName: 'Saint-Barthélemy',
    flags: {
      svg: 'https://flagcdn.com/bl.svg',
      png: 'https://flagcdn.com/w320/bl.png',
    },
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    callingCodes: ['290'],
    nativeName: 'Saint Helena',
    flags: {
      svg: 'https://flagcdn.com/sh.svg',
      png: 'https://flagcdn.com/w320/sh.png',
    },
  },
  {
    name: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    callingCodes: ['1'],
    nativeName: 'Saint Kitts and Nevis',
    flags: {
      svg: 'https://flagcdn.com/kn.svg',
      png: 'https://flagcdn.com/w320/kn.png',
    },
  },
  {
    name: 'Saint Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    callingCodes: ['1'],
    nativeName: 'Saint Lucia',
    flags: {
      svg: 'https://flagcdn.com/lc.svg',
      png: 'https://flagcdn.com/w320/lc.png',
    },
  },
  {
    name: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    callingCodes: ['590'],
    nativeName: 'Saint-Martin',
    flags: {
      svg: 'https://flagcdn.com/mf.svg',
      png: 'https://flagcdn.com/w320/mf.png',
    },
  },
  {
    name: 'Saint Pierre and Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    callingCodes: ['508'],
    nativeName: 'Saint-Pierre-et-Miquelon',
    flags: {
      svg: 'https://flagcdn.com/pm.svg',
      png: 'https://flagcdn.com/w320/pm.png',
    },
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    callingCodes: ['1'],
    nativeName: 'Saint Vincent and the Grenadines',
    flags: {
      svg: 'https://flagcdn.com/vc.svg',
      png: 'https://flagcdn.com/w320/vc.png',
    },
  },
  {
    name: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    callingCodes: ['685'],
    nativeName: 'Samoa',
    flags: {
      svg: 'https://flagcdn.com/ws.svg',
      png: 'https://flagcdn.com/w320/ws.png',
    },
  },
  {
    name: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    callingCodes: ['378'],
    nativeName: 'San Marino',
    flags: {
      svg: 'https://flagcdn.com/sm.svg',
      png: 'https://flagcdn.com/w320/sm.png',
    },
  },
  {
    name: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    callingCodes: ['239'],
    nativeName: 'São Tomé e Príncipe',
    flags: {
      svg: 'https://flagcdn.com/st.svg',
      png: 'https://flagcdn.com/w320/st.png',
    },
  },
  {
    name: 'Saudi Arabia',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    callingCodes: ['966'],
    nativeName: 'العربية السعودية',
    flags: {
      svg: 'https://flagcdn.com/sa.svg',
      png: 'https://flagcdn.com/w320/sa.png',
    },
  },
  {
    name: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    callingCodes: ['221'],
    nativeName: 'Sénégal',
    flags: {
      svg: 'https://flagcdn.com/sn.svg',
      png: 'https://flagcdn.com/w320/sn.png',
    },
  },
  {
    name: 'Serbia',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    callingCodes: ['381'],
    nativeName: 'Србија',
    flags: {
      svg: 'https://flagcdn.com/rs.svg',
      png: 'https://flagcdn.com/w320/rs.png',
    },
  },
  {
    name: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    callingCodes: ['248'],
    nativeName: 'Seychelles',
    flags: {
      svg: 'https://flagcdn.com/sc.svg',
      png: 'https://flagcdn.com/w320/sc.png',
    },
  },
  {
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    callingCodes: ['232'],
    nativeName: 'Sierra Leone',
    flags: {
      svg: 'https://flagcdn.com/sl.svg',
      png: 'https://flagcdn.com/w320/sl.png',
    },
  },
  {
    name: 'Singapore',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    callingCodes: ['65'],
    nativeName: 'Singapore',
    flags: {
      svg: 'https://flagcdn.com/sg.svg',
      png: 'https://flagcdn.com/w320/sg.png',
    },
  },
  {
    name: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    callingCodes: ['1'],
    nativeName: 'Sint Maarten',
    flags: {
      svg: 'https://flagcdn.com/sx.svg',
      png: 'https://flagcdn.com/w320/sx.png',
    },
  },
  {
    name: 'Slovakia',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    callingCodes: ['421'],
    nativeName: 'Slovensko',
    flags: {
      svg: 'https://flagcdn.com/sk.svg',
      png: 'https://flagcdn.com/w320/sk.png',
    },
  },
  {
    name: 'Slovenia',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    callingCodes: ['386'],
    nativeName: 'Slovenija',
    flags: {
      svg: 'https://flagcdn.com/si.svg',
      png: 'https://flagcdn.com/w320/si.png',
    },
  },
  {
    name: 'Solomon Islands',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    callingCodes: ['677'],
    nativeName: 'Solomon Islands',
    flags: {
      svg: 'https://flagcdn.com/sb.svg',
      png: 'https://flagcdn.com/w320/sb.png',
    },
  },
  {
    name: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    callingCodes: ['252'],
    nativeName: 'Soomaaliya',
    flags: {
      svg: 'https://flagcdn.com/so.svg',
      png: 'https://flagcdn.com/w320/so.png',
    },
  },
  {
    name: 'South Africa',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    callingCodes: ['27'],
    nativeName: 'South Africa',
    flags: {
      svg: 'https://flagcdn.com/za.svg',
      png: 'https://flagcdn.com/w320/za.png',
    },
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    callingCodes: ['500'],
    nativeName: 'South Georgia',
    flags: {
      svg: 'https://flagcdn.com/gs.svg',
      png: 'https://flagcdn.com/w320/gs.png',
    },
  },
  {
    name: 'Korea (Republic of)',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    callingCodes: ['82'],
    nativeName: '대한민국',
    flags: {
      svg: 'https://flagcdn.com/kr.svg',
      png: 'https://flagcdn.com/w320/kr.png',
    },
  },
  {
    name: 'Spain',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    callingCodes: ['34'],
    nativeName: 'España',
    flags: {
      svg: 'https://flagcdn.com/es.svg',
      png: 'https://flagcdn.com/w320/es.png',
    },
  },
  {
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    callingCodes: ['94'],
    nativeName: 'śrī laṃkāva',
    flags: {
      svg: 'https://flagcdn.com/lk.svg',
      png: 'https://flagcdn.com/w320/lk.png',
    },
  },
  {
    name: 'Sudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    callingCodes: ['249'],
    nativeName: 'السودان',
    flags: {
      svg: 'https://flagcdn.com/sd.svg',
      png: 'https://flagcdn.com/w320/sd.png',
    },
  },
  {
    name: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    callingCodes: ['211'],
    nativeName: 'South Sudan',
    flags: {
      svg: 'https://flagcdn.com/ss.svg',
      png: 'https://flagcdn.com/w320/ss.png',
    },
  },
  {
    name: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    callingCodes: ['597'],
    nativeName: 'Suriname',
    flags: {
      svg: 'https://flagcdn.com/sr.svg',
      png: 'https://flagcdn.com/w320/sr.png',
    },
  },
  {
    name: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    callingCodes: ['47'],
    nativeName: 'Svalbard og Jan Mayen',
    flags: {
      svg: 'https://flagcdn.com/sj.svg',
      png: 'https://flagcdn.com/w320/sj.png',
    },
  },
  {
    name: 'Swaziland',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    callingCodes: ['268'],
    nativeName: 'Swaziland',
    flags: {
      svg: 'https://flagcdn.com/sz.svg',
      png: 'https://flagcdn.com/w320/sz.png',
    },
  },
  {
    name: 'Sweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    callingCodes: ['46'],
    nativeName: 'Sverige',
    flags: {
      svg: 'https://flagcdn.com/se.svg',
      png: 'https://flagcdn.com/w320/se.png',
    },
  },
  {
    name: 'Switzerland',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    callingCodes: ['41'],
    nativeName: 'Schweiz',
    flags: {
      svg: 'https://flagcdn.com/ch.svg',
      png: 'https://flagcdn.com/w320/ch.png',
    },
  },
  {
    name: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    callingCodes: ['963'],
    nativeName: 'سوريا',
    flags: {
      svg: 'https://flagcdn.com/sy.svg',
      png: 'https://flagcdn.com/w320/sy.png',
    },
  },
  {
    name: 'Taiwan',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    callingCodes: ['886'],
    nativeName: '臺灣',
    flags: {
      svg: 'https://flagcdn.com/tw.svg',
      png: 'https://flagcdn.com/w320/tw.png',
    },
  },
  {
    name: 'Tajikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    callingCodes: ['992'],
    nativeName: 'Тоҷикистон',
    flags: {
      svg: 'https://flagcdn.com/tj.svg',
      png: 'https://flagcdn.com/w320/tj.png',
    },
  },
  {
    name: 'Tanzania, United Republic of',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    callingCodes: ['255'],
    nativeName: 'Tanzania',
    flags: {
      svg: 'https://flagcdn.com/tz.svg',
      png: 'https://flagcdn.com/w320/tz.png',
    },
  },
  {
    name: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    callingCodes: ['66'],
    nativeName: 'ประเทศไทย',
    flags: {
      svg: 'https://flagcdn.com/th.svg',
      png: 'https://flagcdn.com/w320/th.png',
    },
  },
  {
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    callingCodes: ['670'],
    nativeName: 'Timor-Leste',
    flags: {
      svg: 'https://flagcdn.com/tl.svg',
      png: 'https://flagcdn.com/w320/tl.png',
    },
  },
  {
    name: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    callingCodes: ['228'],
    nativeName: 'Togo',
    flags: {
      svg: 'https://flagcdn.com/tg.svg',
      png: 'https://flagcdn.com/w320/tg.png',
    },
  },
  {
    name: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    callingCodes: ['690'],
    nativeName: 'Tokelau',
    flags: {
      svg: 'https://flagcdn.com/tk.svg',
      png: 'https://flagcdn.com/w320/tk.png',
    },
  },
  {
    name: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    callingCodes: ['676'],
    nativeName: 'Tonga',
    flags: {
      svg: 'https://flagcdn.com/to.svg',
      png: 'https://flagcdn.com/w320/to.png',
    },
  },
  {
    name: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    callingCodes: ['1'],
    nativeName: 'Trinidad and Tobago',
    flags: {
      svg: 'https://flagcdn.com/tt.svg',
      png: 'https://flagcdn.com/w320/tt.png',
    },
  },
  {
    name: 'Tunisia',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    callingCodes: ['216'],
    nativeName: 'تونس',
    flags: {
      svg: 'https://flagcdn.com/tn.svg',
      png: 'https://flagcdn.com/w320/tn.png',
    },
  },
  {
    name: 'Turkey',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    callingCodes: ['90'],
    nativeName: 'Türkiye',
    flags: {
      svg: 'https://flagcdn.com/tr.svg',
      png: 'https://flagcdn.com/w320/tr.png',
    },
  },
  {
    name: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    callingCodes: ['993'],
    nativeName: 'Türkmenistan',
    flags: {
      svg: 'https://flagcdn.com/tm.svg',
      png: 'https://flagcdn.com/w320/tm.png',
    },
  },
  {
    name: 'Turks and Caicos Islands',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    callingCodes: ['1'],
    nativeName: 'Turks and Caicos Islands',
    flags: {
      svg: 'https://flagcdn.com/tc.svg',
      png: 'https://flagcdn.com/w320/tc.png',
    },
  },
  {
    name: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    callingCodes: ['688'],
    nativeName: 'Tuvalu',
    flags: {
      svg: 'https://flagcdn.com/tv.svg',
      png: 'https://flagcdn.com/w320/tv.png',
    },
  },
  {
    name: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    callingCodes: ['256'],
    nativeName: 'Uganda',
    flags: {
      svg: 'https://flagcdn.com/ug.svg',
      png: 'https://flagcdn.com/w320/ug.png',
    },
  },
  {
    name: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    callingCodes: ['380'],
    nativeName: 'Україна',
    flags: {
      svg: 'https://flagcdn.com/ua.svg',
      png: 'https://flagcdn.com/w320/ua.png',
    },
  },
  {
    name: 'United Arab Emirates',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    callingCodes: ['971'],
    nativeName: 'دولة الإمارات العربية المتحدة',
    flags: {
      svg: 'https://flagcdn.com/ae.svg',
      png: 'https://flagcdn.com/w320/ae.png',
    },
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    callingCodes: ['44'],
    nativeName: 'United Kingdom',
    flags: {
      svg: 'https://flagcdn.com/gb.svg',
      png: 'https://flagcdn.com/w320/gb.png',
    },
  },
  {
    name: 'United States of America',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    callingCodes: ['1'],
    nativeName: 'United States',
    flags: {
      svg: 'https://flagcdn.com/us.svg',
      png: 'https://flagcdn.com/w320/us.png',
    },
  },
  {
    name: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    callingCodes: ['598'],
    nativeName: 'Uruguay',
    flags: {
      svg: 'https://flagcdn.com/uy.svg',
      png: 'https://flagcdn.com/w320/uy.png',
    },
  },
  {
    name: 'Uzbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    callingCodes: ['998'],
    nativeName: 'O‘zbekiston',
    flags: {
      svg: 'https://flagcdn.com/uz.svg',
      png: 'https://flagcdn.com/w320/uz.png',
    },
  },
  {
    name: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    callingCodes: ['678'],
    nativeName: 'Vanuatu',
    flags: {
      svg: 'https://flagcdn.com/vu.svg',
      png: 'https://flagcdn.com/w320/vu.png',
    },
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    callingCodes: ['58'],
    nativeName: 'Venezuela',
    flags: {
      svg: 'https://flagcdn.com/ve.svg',
      png: 'https://flagcdn.com/w320/ve.png',
    },
  },
  {
    name: 'Vietnam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    callingCodes: ['84'],
    nativeName: 'Việt Nam',
    flags: {
      svg: 'https://flagcdn.com/vn.svg',
      png: 'https://flagcdn.com/w320/vn.png',
    },
  },
  {
    name: 'Wallis and Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    callingCodes: ['681'],
    nativeName: 'Wallis et Futuna',
    flags: {
      svg: 'https://flagcdn.com/wf.svg',
      png: 'https://flagcdn.com/w320/wf.png',
    },
  },
  {
    name: 'Western Sahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    callingCodes: ['212'],
    nativeName: 'الصحراء الغربية',
    flags: {
      svg: 'https://flagcdn.com/eh.svg',
      png: 'https://flagcdn.com/w320/eh.png',
    },
  },
  {
    name: 'Yemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    callingCodes: ['967'],
    nativeName: 'اليَمَن',
    flags: {
      svg: 'https://flagcdn.com/ye.svg',
      png: 'https://flagcdn.com/w320/ye.png',
    },
  },
  {
    name: 'Zambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    callingCodes: ['260'],
    nativeName: 'Zambia',
    flags: {
      svg: 'https://flagcdn.com/zm.svg',
      png: 'https://flagcdn.com/w320/zm.png',
    },
  },
  {
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    callingCodes: ['263'],
    nativeName: 'Zimbabwe',
    flags: {
      svg: 'https://flagcdn.com/zw.svg',
      png: 'https://flagcdn.com/w320/zw.png',
    },
  },
];
