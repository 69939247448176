<ng-container *ngIf="hideLogo || text; else logoWrapper">
  <p *ngIf="text" class="name" (click)="logoClick.emit()" [routerLink]="['/']">{{ text }}</p>
</ng-container>
<ng-template #logoWrapper>
  <img
    (load)="onLogoLoad()"
    [src]="logo"
    class="logo"
    *ngIf="logo"
    alt=""
    (click)="logoClick.emit()"
    [routerLink]="['/']"
  />
  <ng-container *ngIf="!logo && noLogoInfo">
    <div class="logo">
      <p>{{ logoPlaceholderText | translate }}</p>
    </div>
  </ng-container>
</ng-template>
<ng-content></ng-content>
<div class="buttons">
  <div class="language" *ngIf="availableLanguages.length > 1">
    <ui-select-country
      [locked]="isPreview"
      [items]="availableLanguages"
      [guestArea]="true"
      [loading]="false"
      type="ng-select-input ng-select-input-small"
      [arrow]="true"
      [virtualScroll]="false"
      [(ngModel)]="selectedLanguage"
      [bindValue]="'iso639_1'"
      (ngModelChange)="changeLang($event)"
    ></ui-select-country>
  </div>
  <div class="account" *ngIf="showMyProfile && guest && !isPreview">
    <ui-my-profile [guest]="guest" (buttonClick)="profileClick.emit()"></ui-my-profile>
  </div>
</div>
<div class="indicator" *ngIf="alt">
  <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow arrow-1"></svg-icon>
  <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow arrow-2"></svg-icon>
  <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow arrow-3"></svg-icon>
</div>
