import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CardModule, FrontendUiModule } from '@smarthotel/angular-ui';
import { DividerComponent, TipComponent } from '@ui/common';
import { ButtonCircleComponent, ButtonPillComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, PhoneNumberComponent, SelectLanguageComponent } from '@ui/common/forms';
import { FormHoursComponent, FormWidgetPermalinkComponent } from '@widgets/front-common/form';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DragNDropDirective, FormControlDirective } from 'angular-v2-directives';
import { LocalizedDatePipe } from 'angular-v2-pipes';
import { ColorChromeModule } from 'ngx-color/chrome';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { QuillModule } from 'ngx-quill';
import { SortablejsModule } from 'nxt-sortablejs';

import { DirectivesModule } from '../../../directives/directives.module';
import { ConfiguratorComponentsModule } from '../components/configurator-components.module';
import { VisitorsApartmentModule } from '../visitors-apartment/visitors-apartment.module';
import { FormAttachemntsComponent } from './form-attachemnts/form-attachemnts.component';
import { FormAttractionsComponent } from './form-attractions/form-attractions.component';
import { FormEventsComponent } from './form-events/form-events.component';
import { FormFaqComponent } from './form-faq/form-faq.component';
import { FormGastronomyComponent } from './form-gastronomy/form-gastronomy.component';
import { FormHealthComponent } from './form-health/form-health.component';
import { FormIdentificationComponent } from './form-identification/form-identification.component';
import { FormIdentificationAdjustmentsComponent } from './form-identification/form-identification-adjustments/form-identification-adjustments.component';
import { FormIdentificationBackgroundManagerComponent } from './form-identification/form-identification-background-manager/form-identification-background-manager.component';
import { FormIdentificationBaseComponent } from './form-identification/form-identification-base/form-identification-base.component';
import { FormIdentificationColorsComponent } from './form-identification/form-identification-colors/form-identification-colors.component';
import { FormIdentificationContactComponent } from './form-identification/form-identification-contact/form-identification-contact.component';
import { FormIdentificationLogoPickerComponent } from './form-identification/form-identification-logo-picker/form-identification-logo-picker.component';
import { FormIdentificationUploaderComponent } from './form-identification/form-identification-uploader/form-identification-uploader.component';
import { FormMealsComponent } from './form-meals/form-meals.component';
import { FormSocialMediaComponent } from './form-social-media/form-social-media.component';
import { FormWellnessComponent } from './form-wellness/form-wellness.component';
import { FormWidgetsModule } from './form-widgets/form-widgets.module';
import { FormWifiComponent } from './form-wifi/form-wifi.component';
import { VisitorsFormComponent } from './visitors-form.component';

@NgModule({
  declarations: [
    VisitorsFormComponent,
    FormIdentificationComponent,
    FormFaqComponent,
    FormEventsComponent,
    FormAttachemntsComponent,
    FormGastronomyComponent,
    FormAttractionsComponent,
    FormHealthComponent,
    FormWellnessComponent,
    FormMealsComponent,
    FormSocialMediaComponent,
    FormIdentificationAdjustmentsComponent,
    FormIdentificationColorsComponent,
    FormIdentificationBackgroundManagerComponent,
    FormIdentificationLogoPickerComponent,
    FormIdentificationBaseComponent,
    FormIdentificationUploaderComponent,
    FormIdentificationContactComponent,
    FormWifiComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ConfiguratorComponentsModule,
    CardModule,
    AngularSvgIconModule,
    FormWidgetsModule,
    TranslateModule,
    FrontendUiModule,
    QuillModule,
    SortablejsModule,
    ColorChromeModule,
    ImageCropperComponent,
    DirectivesModule,
    LocalizedDatePipe,
    TipComponent,
    DragNDropDirective,
    FormControlDirective,
    FormWidgetPermalinkComponent,
    SelectLanguageComponent,
    ErrorMessageComponent,
    PhoneNumberComponent,
    FormHoursComponent,
    ButtonCircleComponent,
    VisitorsApartmentModule,
    DividerComponent,
    ButtonPillComponent,
  ],
  exports: [VisitorsFormComponent],
})
export class VisitorsFormModule {}
