export enum VaWidgetCode {
  info = 'info',
  chat = 'chat',
  chatWhatsApp = 'chat-whatsApp',
  chatMessenger = 'chat-messenger',
  fullPotential = 'full-potential',
  contact = 'contact',
  wifi = 'wifi',
  reception = 'reception',
  gym = 'gym',
  spa = 'spa',
  parking = 'parking',
  breakfast = 'breakfast',
  deposit = 'deposit',
  files = 'files',
  faq = 'faq',
  whatsnearby = 'whatsnearby',
  events = 'events',
  gastronomy = 'gastronomy',
  attractions = 'attractions',
  checkInOut = 'check-in-out',
  meals = 'meals',
  socialMedia = 'socialmedia',
  custom = 'custom-widget-v1',
  pets = 'pets',
  health = 'health',
  wellness = 'wellness',
  aiAssistant = 'ai-assistant',
}
