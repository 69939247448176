<div [class.notification-bar]="showNotificationBar" [class.account-expired]="accountExpired">
  <sh-notification-bar *ngIf="showNotificationBar" #notificationBar></sh-notification-bar>
  <sh-top-bar *ngIf="showTopBar" [style.top.px]="topOffset" #topBar></sh-top-bar>
  <sh-top-navigation [items]="menuStandaloneTop" *ngIf="showTopNavigation && !showTopBar"
                     #topNavigation></sh-top-navigation>
  <sh-sidebar *ngIf="showSideBar" [style.top.px]="topOffset" (mouseenter)="toggleSidebar(false)"
              (mouseleave)="toggleSidebar(true)">
    <sh-menu-group *ngIf="menuStandaloneOperations.length > 0" [items]="menuStandaloneOperations"></sh-menu-group>
    <sh-menu-group *ngIf="menuStandaloneResources.length > 0" [items]="menuStandaloneResources"></sh-menu-group>
  </sh-sidebar>
  <router-outlet class="dashboard-outlet"></router-outlet>
</div>
