<div class="banner">
  <svg-icon [src]="iconLeftPath" *ngIf="iconLeft"></svg-icon>
  <div class="banner-content">
    <h6 *ngIf="header">{{ header }}</h6>
    <p *ngIf="text" [innerHTML]="text">{{ text }}</p>
    <p *ngIf="html" [innerHTML]="html"></p>
  </div>
  <div class="banner-actions" *ngIf="iconRight || buttonText">
    <svg-icon class="icon-right" [class.clickable]="iconRightAction" [src]="iconRightPath"
              *ngIf="iconRight && !buttonText" (click)="onIconClick()"></svg-icon>
    <button *ngIf="buttonText && !iconRight" (click)="buttonClick.emit()">
      <span>{{ buttonText }}</span>
    </button>
  </div>
</div>
