import { Component, Input } from '@angular/core';
import { QRCodeElementType, QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'ui-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  standalone: true,
  imports: [QRCodeModule],
})
export class QrCodeComponent {
  elementType: QRCodeElementType = 'url';
  @Input() value = '';
  @Input() width = 500;
  @Input() margin = 6;
}
