export enum Timezones {
  Africa_Abidjan = 'Africa/Abidjan',
  Africa_Accra = 'Africa/Accra',
  Africa_Nairobi = 'Africa/Nairobi',
  Africa_Algiers = 'Africa/Algiers',
  Africa_Lagos = 'Africa/Lagos',
  Africa_Bissau = 'Africa/Bissau',
  Africa_Maputo = 'Africa/Maputo',
  Africa_Cairo = 'Africa/Cairo',
  Africa_Casablanca = 'Africa/Casablanca',
  Africa_Ceuta = 'Africa/Ceuta',
  Africa_El_Aaiun = 'Africa/El_Aaiun',
  Africa_Johannesburg = 'Africa/Johannesburg',
  Africa_Juba = 'Africa/Juba',
  Africa_Khartoum = 'Africa/Khartoum',
  Africa_Monrovia = 'Africa/Monrovia',
  Africa_Ndjamena = 'Africa/Ndjamena',
  Africa_Sao_Tome = 'Africa/Sao_Tome',
  Africa_Tripoli = 'Africa/Tripoli',
  Africa_Tunis = 'Africa/Tunis',
  Africa_Windhoek = 'Africa/Windhoek',
  America_Adak = 'America/Adak',
  America_Anchorage = 'America/Anchorage',
  America_Port_of_Spain = 'America/Port_of_Spain',
  America_Araguaina = 'America/Araguaina',
  America_Argentina_Buenos_Aires = 'America/Argentina/Buenos_Aires',
  America_Argentina_Catamarca = 'America/Argentina/Catamarca',
  America_Argentina_Cordoba = 'America/Argentina/Cordoba',
  America_Argentina_Jujuy = 'America/Argentina/Jujuy',
  America_Argentina_La_Rioja = 'America/Argentina/La_Rioja',
  America_Argentina_Mendoza = 'America/Argentina/Mendoza',
  America_Argentina_Rio_Gallegos = 'America/Argentina/Rio_Gallegos',
  America_Argentina_Salta = 'America/Argentina/Salta',
  America_Argentina_San_Juan = 'America/Argentina/San_Juan',
  America_Argentina_San_Luis = 'America/Argentina/San_Luis',
  America_Argentina_Tucuman = 'America/Argentina/Tucuman',
  America_Argentina_Ushuaia = 'America/Argentina/Ushuaia',
  America_Curacao = 'America/Curacao',
  America_Asuncion = 'America/Asuncion',
  America_Atikokan = 'America/Atikokan',
  America_Bahia_Banderas = 'America/Bahia_Banderas',
  America_Bahia = 'America/Bahia',
  America_Barbados = 'America/Barbados',
  America_Belem = 'America/Belem',
  America_Belize = 'America/Belize',
  America_Blanc_Sablon = 'America/Blanc-Sablon',
  America_Boa_Vista = 'America/Boa_Vista',
  America_Bogota = 'America/Bogota',
  America_Boise = 'America/Boise',
  America_Cambridge_Bay = 'America/Cambridge_Bay',
  America_Campo_Grande = 'America/Campo_Grande',
  America_Cancun = 'America/Cancun',
  America_Caracas = 'America/Caracas',
  America_Cayenne = 'America/Cayenne',
  America_Panama = 'America/Panama',
  America_Chicago = 'America/Chicago',
  America_Chihuahua = 'America/Chihuahua',
  America_Costa_Rica = 'America/Costa_Rica',
  America_Creston = 'America/Creston',
  America_Cuiaba = 'America/Cuiaba',
  America_Danmarkshavn = 'America/Danmarkshavn',
  America_Dawson_Creek = 'America/Dawson_Creek',
  America_Dawson = 'America/Dawson',
  America_Denver = 'America/Denver',
  America_Detroit = 'America/Detroit',
  America_Edmonton = 'America/Edmonton',
  America_Eirunepe = 'America/Eirunepe',
  America_El_Salvador = 'America/El_Salvador',
  America_Tijuana = 'America/Tijuana',
  America_Fort_Nelson = 'America/Fort_Nelson',
  America_Fort_Wayne = 'America/Fort_Wayne',
  America_Fortaleza = 'America/Fortaleza',
  America_Glace_Bay = 'America/Glace_Bay',
  America_Godthab = 'America/Godthab',
  America_Goose_Bay = 'America/Goose_Bay',
  America_Grand_Turk = 'America/Grand_Turk',
  America_Guatemala = 'America/Guatemala',
  America_Guayaquil = 'America/Guayaquil',
  America_Guyana = 'America/Guyana',
  America_Halifax = 'America/Halifax',
  America_Havana = 'America/Havana',
  America_Hermosillo = 'America/Hermosillo',
  America_Indiana_Knox = 'America/Indiana/Knox',
  America_Indiana_Marengo = 'America/Indiana/Marengo',
  America_Indiana_Petersburg = 'America/Indiana/Petersburg',
  America_Indiana_Tell_City = 'America/Indiana/Tell_City',
  America_Indiana_Vevay = 'America/Indiana/Vevay',
  America_Indiana_Vincennes = 'America/Indiana/Vincennes',
  America_Indiana_Winamac = 'America/Indiana/Winamac',
  America_Inuvik = 'America/Inuvik',
  America_Iqaluit = 'America/Iqaluit',
  America_Jamaica = 'America/Jamaica',
  America_Juneau = 'America/Juneau',
  America_Kentucky_Louisville = 'America/Kentucky/Louisville',
  America_Kentucky_Monticello = 'America/Kentucky/Monticello',
  America_La_Paz = 'America/La_Paz',
  America_Lima = 'America/Lima',
  America_Los_Angeles = 'America/Los_Angeles',
  America_Maceio = 'America/Maceio',
  America_Managua = 'America/Managua',
  America_Manaus = 'America/Manaus',
  America_Martinique = 'America/Martinique',
  America_Matamoros = 'America/Matamoros',
  America_Mazatlan = 'America/Mazatlan',
  America_Menominee = 'America/Menominee',
  America_Merida = 'America/Merida',
  America_Metlakatla = 'America/Metlakatla',
  America_Mexico_City = 'America/Mexico_City',
  America_Miquelon = 'America/Miquelon',
  America_Moncton = 'America/Moncton',
  America_Monterrey = 'America/Monterrey',
  America_Montevideo = 'America/Montevideo',
  America_Toronto = 'America/Toronto',
  America_Nassau = 'America/Nassau',
  America_New_York = 'America/New_York',
  America_Nipigon = 'America/Nipigon',
  America_Nome = 'America/Nome',
  America_Noronha = 'America/Noronha',
  America_North_Dakota_Beulah = 'America/North_Dakota/Beulah',
  America_North_Dakota_Center = 'America/North_Dakota/Center',
  America_North_Dakota_New_Salem = 'America/North_Dakota/New_Salem',
  America_Ojinaga = 'America/Ojinaga',
  America_Pangnirtung = 'America/Pangnirtung',
  America_Paramaribo = 'America/Paramaribo',
  America_Phoenix = 'America/Phoenix',
  America_Port_au_Prince = 'America/Port-au-Prince',
  America_Rio_Branco = 'America/Rio_Branco',
  America_Porto_Velho = 'America/Porto_Velho',
  America_Puerto_Rico = 'America/Puerto_Rico',
  America_Punta_Arenas = 'America/Punta_Arenas',
  America_Rainy_River = 'America/Rainy_River',
  America_Rankin_Inlet = 'America/Rankin_Inlet',
  America_Recife = 'America/Recife',
  America_Regina = 'America/Regina',
  America_Resolute = 'America/Resolute',
  America_Santarem = 'America/Santarem',
  America_Santiago = 'America/Santiago',
  America_Santo_Domingo = 'America/Santo_Domingo',
  America_Sao_Paulo = 'America/Sao_Paulo',
  America_Scoresbysund = 'America/Scoresbysund',
  America_Sitka = 'America/Sitka',
  America_St_Johns = 'America/St_Johns',
  America_Swift_Current = 'America/Swift_Current',
  America_Tegucigalpa = 'America/Tegucigalpa',
  America_Thule = 'America/Thule',
  America_Thunder_Bay = 'America/Thunder_Bay',
  America_Vancouver = 'America/Vancouver',
  America_Whitehorse = 'America/Whitehorse',
  America_Winnipeg = 'America/Winnipeg',
  America_Yakutat = 'America/Yakutat',
  America_Yellowknife = 'America/Yellowknife',
  Antarctica_Casey = 'Antarctica/Casey',
  Antarctica_Davis = 'Antarctica/Davis',
  Antarctica_DumontDUrville = 'Antarctica/DumontDUrville',
  Antarctica_Macquarie = 'Antarctica/Macquarie',
  Antarctica_Mawson = 'Antarctica/Mawson',
  Pacific_Auckland = 'Pacific/Auckland',
  Antarctica_Palmer = 'Antarctica/Palmer',
  Antarctica_Rothera = 'Antarctica/Rothera',
  Antarctica_Syowa = 'Antarctica/Syowa',
  Antarctica_Troll = 'Antarctica/Troll',
  Antarctica_Vostok = 'Antarctica/Vostok',
  Europe_Oslo = 'Europe/Oslo',
  Asia_Riyadh = 'Asia/Riyadh',
  Asia_Almaty = 'Asia/Almaty',
  Asia_Amman = 'Asia/Amman',
  Asia_Anadyr = 'Asia/Anadyr',
  Asia_Aqtau = 'Asia/Aqtau',
  Asia_Aqtobe = 'Asia/Aqtobe',
  Asia_Ashgabat = 'Asia/Ashgabat',
  Asia_Atyrau = 'Asia/Atyrau',
  Asia_Baghdad = 'Asia/Baghdad',
  Asia_Qatar = 'Asia/Qatar',
  Asia_Baku = 'Asia/Baku',
  Asia_Bangkok = 'Asia/Bangkok',
  Asia_Barnaul = 'Asia/Barnaul',
  Asia_Beirut = 'Asia/Beirut',
  Asia_Bishkek = 'Asia/Bishkek',
  Asia_Brunei = 'Asia/Brunei',
  Asia_Kolkata = 'Asia/Kolkata',
  Asia_Chita = 'Asia/Chita',
  Asia_Choibalsan = 'Asia/Choibalsan',
  Asia_Shanghai = 'Asia/Shanghai',
  Asia_Colombo = 'Asia/Colombo',
  Asia_Dhaka = 'Asia/Dhaka',
  Asia_Damascus = 'Asia/Damascus',
  Asia_Dili = 'Asia/Dili',
  Asia_Dubai = 'Asia/Dubai',
  Asia_Dushanbe = 'Asia/Dushanbe',
  Asia_Famagusta = 'Asia/Famagusta',
  Asia_Gaza = 'Asia/Gaza',
  Asia_Hebron = 'Asia/Hebron',
  Asia_Ho_Chi_Minh = 'Asia/Ho_Chi_Minh',
  Asia_Hong_Kong = 'Asia/Hong_Kong',
  Asia_Hovd = 'Asia/Hovd',
  Asia_Irkutsk = 'Asia/Irkutsk',
  Europe_Istanbul = 'Europe/Istanbul',
  Asia_Jakarta = 'Asia/Jakarta',
  Asia_Jayapura = 'Asia/Jayapura',
  Asia_Jerusalem = 'Asia/Jerusalem',
  Asia_Kabul = 'Asia/Kabul',
  Asia_Kamchatka = 'Asia/Kamchatka',
  Asia_Karachi = 'Asia/Karachi',
  Asia_Urumqi = 'Asia/Urumqi',
  Asia_Kathmandu = 'Asia/Kathmandu',
  Asia_Khandyga = 'Asia/Khandyga',
  Asia_Krasnoyarsk = 'Asia/Krasnoyarsk',
  Asia_Kuala_Lumpur = 'Asia/Kuala_Lumpur',
  Asia_Kuching = 'Asia/Kuching',
  Asia_Macau = 'Asia/Macau',
  Asia_Magadan = 'Asia/Magadan',
  Asia_Makassar = 'Asia/Makassar',
  Asia_Manila = 'Asia/Manila',
  Asia_Nicosia = 'Asia/Nicosia',
  Asia_Novokuznetsk = 'Asia/Novokuznetsk',
  Asia_Novosibirsk = 'Asia/Novosibirsk',
  Asia_Omsk = 'Asia/Omsk',
  Asia_Oral = 'Asia/Oral',
  Asia_Pontianak = 'Asia/Pontianak',
  Asia_Pyongyang = 'Asia/Pyongyang',
  Asia_Qostanay = 'Asia/Qostanay',
  Asia_Qyzylorda = 'Asia/Qyzylorda',
  Asia_Rangoon = 'Asia/Rangoon',
  Asia_Sakhalin = 'Asia/Sakhalin',
  Asia_Samarkand = 'Asia/Samarkand',
  Asia_Seoul = 'Asia/Seoul',
  Asia_Singapore = 'Asia/Singapore',
  Asia_Srednekolymsk = 'Asia/Srednekolymsk',
  Asia_Taipei = 'Asia/Taipei',
  Asia_Tashkent = 'Asia/Tashkent',
  Asia_Tbilisi = 'Asia/Tbilisi',
  Asia_Tehran = 'Asia/Tehran',
  Asia_Thimphu = 'Asia/Thimphu',
  Asia_Tokyo = 'Asia/Tokyo',
  Asia_Tomsk = 'Asia/Tomsk',
  Asia_Ulaanbaatar = 'Asia/Ulaanbaatar',
  Asia_Ust_Nera = 'Asia/Ust-Nera',
  Asia_Vladivostok = 'Asia/Vladivostok',
  Asia_Yakutsk = 'Asia/Yakutsk',
  Asia_Yekaterinburg = 'Asia/Yekaterinburg',
  Asia_Yerevan = 'Asia/Yerevan',
  Atlantic_Azores = 'Atlantic/Azores',
  Atlantic_Bermuda = 'Atlantic/Bermuda',
  Atlantic_Canary = 'Atlantic/Canary',
  Atlantic_Cape_Verde = 'Atlantic/Cape_Verde',
  Atlantic_Faroe = 'Atlantic/Faroe',
  Atlantic_Madeira = 'Atlantic/Madeira',
  Atlantic_Reykjavik = 'Atlantic/Reykjavik',
  Atlantic_South_Georgia = 'Atlantic/South_Georgia',
  Atlantic_Stanley = 'Atlantic/Stanley',
  Australia_Sydney = 'Australia/Sydney',
  Australia_Adelaide = 'Australia/Adelaide',
  Australia_Brisbane = 'Australia/Brisbane',
  Australia_Broken_Hill = 'Australia/Broken_Hill',
  Australia_Currie = 'Australia/Currie',
  Australia_Darwin = 'Australia/Darwin',
  Australia_Eucla = 'Australia/Eucla',
  Australia_Hobart = 'Australia/Hobart',
  Australia_Lord_Howe = 'Australia/Lord_Howe',
  Australia_Lindeman = 'Australia/Lindeman',
  Australia_Melbourne = 'Australia/Melbourne',
  Australia_Perth = 'Australia/Perth',
  CET = 'CET',
  Pacific_Easter = 'Pacific/Easter',
  CST6CDT = 'CST6CDT',
  EET = 'EET',
  Europe_Dublin = 'Europe/Dublin',
  EST = 'EST',
  EST5EDT = 'EST5EDT',
  Etc_UTC = 'Etc/UTC',
  Europe_Amsterdam = 'Europe/Amsterdam',
  Europe_Andorra = 'Europe/Andorra',
  Europe_Astrakhan = 'Europe/Astrakhan',
  Europe_Athens = 'Europe/Athens',
  Europe_London = 'Europe/London',
  Europe_Belgrade = 'Europe/Belgrade',
  Europe_Berlin = 'Europe/Berlin',
  Europe_Prague = 'Europe/Prague',
  Europe_Brussels = 'Europe/Brussels',
  Europe_Bucharest = 'Europe/Bucharest',
  Europe_Budapest = 'Europe/Budapest',
  Europe_Zurich = 'Europe/Zurich',
  Europe_Chisinau = 'Europe/Chisinau',
  Europe_Copenhagen = 'Europe/Copenhagen',
  Europe_Gibraltar = 'Europe/Gibraltar',
  Europe_Helsinki = 'Europe/Helsinki',
  Europe_Kaliningrad = 'Europe/Kaliningrad',
  Europe_Kiev = 'Europe/Kiev',
  Europe_Kirov = 'Europe/Kirov',
  Europe_Lisbon = 'Europe/Lisbon',
  Europe_Luxembourg = 'Europe/Luxembourg',
  Europe_Madrid = 'Europe/Madrid',
  Europe_Malta = 'Europe/Malta',
  Europe_Minsk = 'Europe/Minsk',
  Europe_Monaco = 'Europe/Monaco',
  Europe_Moscow = 'Europe/Moscow',
  Europe_Paris = 'Europe/Paris',
  Europe_Riga = 'Europe/Riga',
  Europe_Rome = 'Europe/Rome',
  Europe_Samara = 'Europe/Samara',
  Europe_Saratov = 'Europe/Saratov',
  Europe_Simferopol = 'Europe/Simferopol',
  Europe_Sofia = 'Europe/Sofia',
  Europe_Stockholm = 'Europe/Stockholm',
  Europe_Tallinn = 'Europe/Tallinn',
  Europe_Tirane = 'Europe/Tirane',
  Europe_Ulyanovsk = 'Europe/Ulyanovsk',
  Europe_Uzhgorod = 'Europe/Uzhgorod',
  Europe_Vienna = 'Europe/Vienna',
  Europe_Vilnius = 'Europe/Vilnius',
  Europe_Volgograd = 'Europe/Volgograd',
  Europe_Warsaw = 'Europe/Warsaw',
  Europe_Zaporozhye = 'Europe/Zaporozhye',
  HST = 'HST',
  Indian_Chagos = 'Indian/Chagos',
  Indian_Christmas = 'Indian/Christmas',
  Indian_Cocos = 'Indian/Cocos',
  Indian_Kerguelen = 'Indian/Kerguelen',
  Indian_Mahe = 'Indian/Mahe',
  Indian_Maldives = 'Indian/Maldives',
  Indian_Mauritius = 'Indian/Mauritius',
  Indian_Reunion = 'Indian/Reunion',
  Pacific_Kwajalein = 'Pacific/Kwajalein',
  MET = 'MET',
  MST = 'MST',
  MST7MDT = 'MST7MDT',
  Pacific_Chatham = 'Pacific/Chatham',
  Pacific_Apia = 'Pacific/Apia',
  Pacific_Bougainville = 'Pacific/Bougainville',
  Pacific_Chuuk = 'Pacific/Chuuk',
  Pacific_Efate = 'Pacific/Efate',
  Pacific_Enderbury = 'Pacific/Enderbury',
  Pacific_Fakaofo = 'Pacific/Fakaofo',
  Pacific_Fiji = 'Pacific/Fiji',
  Pacific_Funafuti = 'Pacific/Funafuti',
  Pacific_Galapagos = 'Pacific/Galapagos',
  Pacific_Gambier = 'Pacific/Gambier',
  Pacific_Guadalcanal = 'Pacific/Guadalcanal',
  Pacific_Guam = 'Pacific/Guam',
  Pacific_Honolulu = 'Pacific/Honolulu',
  Pacific_Kiritimati = 'Pacific/Kiritimati',
  Pacific_Kosrae = 'Pacific/Kosrae',
  Pacific_Majuro = 'Pacific/Majuro',
  Pacific_Marquesas = 'Pacific/Marquesas',
  Pacific_Pago_Pago = 'Pacific/Pago_Pago',
  Pacific_Nauru = 'Pacific/Nauru',
  Pacific_Niue = 'Pacific/Niue',
  Pacific_Norfolk = 'Pacific/Norfolk',
  Pacific_Noumea = 'Pacific/Noumea',
  Pacific_Palau = 'Pacific/Palau',
  Pacific_Pitcairn = 'Pacific/Pitcairn',
  Pacific_Pohnpei = 'Pacific/Pohnpei',
  Pacific_Port_Moresby = 'Pacific/Port_Moresby',
  Pacific_Rarotonga = 'Pacific/Rarotonga',
  Pacific_Tahiti = 'Pacific/Tahiti',
  Pacific_Tarawa = 'Pacific/Tarawa',
  Pacific_Tongatapu = 'Pacific/Tongatapu',
  Pacific_Wake = 'Pacific/Wake',
  Pacific_Wallis = 'Pacific/Wallis',
  PST8PDT = 'PST8PDT',
  WET = 'WET',
}
