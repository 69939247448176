import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { copyToClipboard } from 'angular-v2-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sh-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CopyTextComponent {
  @Input() value!: string;

  constructor(private toastr: ToastrService, private translateService: TranslateService) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    copyToClipboard(this.value);
    this.toastr.info(this.translateService.instant('SH.COPIED'));
  }
}
