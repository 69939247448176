<div class="check-apps-content">
  <img
    src="/assets/sidebar-app-preview.png"
    srcset="
      /assets/sidebar-app-preview.png 1x,
      /assets/sidebar-app-preview@2x.png 2x,
      /assets/sidebar-app-preview@3x.png 3x,
      /assets/sidebar-app-preview@4x.png 4x
    "
    alt="{{ 'SH.CHECK_OUR_APPS' | translate }}"
  />
  <a href="https://mysmarthotel.com/mobile" target="_blank" class="button">
    <span>{{ 'SH.CHECK_OUR_APPS' | translate }}</span>
    <svg-icon src="/assets/icons/icons-arrow-right.svg"></svg-icon>
  </a>
</div>
