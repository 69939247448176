import { IMediaFile } from '@smarttypes/core';

export enum VisitorsCategoryEnum {
  Info = 'Info',
  Files = 'Files',
  Restaurants = 'Restaurants',
  Events = 'Events',
  Attractions = 'Attractions',
  Meals = 'Meals',
  Faq = 'FAQ',
  SocialMedia = 'SocialMediaLinks',
  Health = 'Health',
  Wellness = 'Wellness',
  Wifi = 'WiFi',
}

export interface IVisitorsFormFaq {
  title: string;
  description: string;
}

export interface IVisitorsForm {
  identification: {
    logo: string;
    name: string;
    url: string;
    language?: string;
  };
  widgetsArray: Record<string, unknown>[];
  widgetsActive?: Record<string, unknown>[];
  files: IMediaFile[];
  gastronomy?: Record<string, string>[];
  events?: Record<string, string>[];
  faq: IVisitorsFormFaq[];
}
