import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nlToBr',
  standalone: true,
})
export class NlToBrPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string | null {
    const textParsed = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    return this.sanitizer.sanitize(SecurityContext.HTML, textParsed);
  }
}
