import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppModules, ICompanyRole } from '@smarttypes/core';
import { hasModule } from 'angular-v2-utils';
import { map, take } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { CompanyService } from '../services/company.service';
import { ResponsiveService } from '../services/responsive.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  constructor(
    private readonly companyService: CompanyService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.companyService.$currentUser().pipe(
      filter(e => !!e),
      take(1),
      timeout(10000),
      map(company => {
        if (!this.responsiveService.isMobile) {
          return true;
        }
        if (this.canRunOnMobile(route)) {
          return true;
        }
        // Current route is not available on mobile, redirect to livechat which is supported
        if (hasModule(company?.modulesV2, AppModules.unifiedInbox)) {
          this.router.navigate(['/livechat']);
          return false;
        }
        if (this.isSmartGuestPlan(company)) {
          this.router.navigate(['/mobile-not-support']);
          return false;
        }
        this.router.navigate(['/start']);
        return false;
      }),
    );
  }

  private canRunOnMobile(route: ActivatedRouteSnapshot) {
    return environment.mobileAvailablePages.includes(`/${route.routeConfig?.path}`);
  }

  private isSmartGuestPlan(company: ICompanyRole) {
    return company.plan === 'SmartGuest';
  }
}
