export enum RequestCategoryEnum {
  voucher = 'Voucher',
  housekeeping = 'Housekeeping',
  foodAndBeverages = 'FoodAndBeverages',
  breakfast = 'Breakfast',
  spa = 'Spa',
  services = 'Services',
  maintenance = 'Maintenance',
  standard = 'Standard',
  activities = 'Activities',
  feedback = 'Feedback',
  humanResources = 'HumanResources',
  accounting = 'Accounting',
}
