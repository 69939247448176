import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export interface INotificationBar {
  id: NotificationIdEnum;
  header?: string;
  description?: string;
  html?: boolean;
  icon?: string;
  hierarchy?: 'low' | 'high';
  type?: 'info' | 'positive' | 'warning' | 'negative';
}

export enum NotificationIdEnum {
  AccountExpired = 'account-expired',
  PaymentRequired = 'payment-required',
  UpdatePending = 'update-pending',
  NewVersion = 'new-version',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationBarService {
  private visibility$ = new ReplaySubject<INotificationBar[]>();
  private _notifications: INotificationBar[] = [];

  get $notifications(): Observable<INotificationBar[]> {
    return this.visibility$.asObservable();
  }

  constructor() {}

  addNotification(data: INotificationBar) {
    const existing = this._notifications.findIndex(n => n.id === data.id);
    if (existing === -1) {
      this._notifications.push(data);
      this.visibility$.next(this._notifications);
    }
  }

  hideNotification(id: NotificationIdEnum) {
    this._notifications = this._notifications.filter(n => n.id !== id);
    this.visibility$.next(this._notifications);
  }

  hideAll() {
    this.visibility$.next([]);
  }
}
