import { ICompanyRole } from '@smarttypes/core';
import { IGuest } from '@smarttypes/hotel';
import { get } from 'lodash';

export function isReservationPaid(company?: ICompanyRole, guest?: IGuest): string {
  const paymentVerificationEnable = get(company, 'config.apartmentSettings.paymentVerification', false);
  const isManualAdded = get(guest, 'initialValue.initialValueFrom') === 'add-guest';
  if (paymentVerificationEnable && !isManualAdded) {
    const paid = get(guest, 'reservation.isPaid', undefined);
    if (paid === undefined) {
      return '';
    }
    return paid ? `SH.YES` : `SH.NO`;
  }
  return '';
}
