import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wg-form-section-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './form-section-header.component.html',
  styleUrls: ['./form-section-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionHeaderComponent {
  @Input() title?: string;
  @Input() description?: string;
}
