<div
  #uploader
  (dragover)="onFileDragOver()"
  (dragleave)="onFileDragLeave()"
  (drop)="onFileDragDrop()"
  class="uploader"
  shDragNDrop
  (fileDropped)="onFileDropped($event)"
  [class.uploader-border]="border"
  [class.d-none]="file"
>
  <ng-container>
    <svg-icon src="/assets/icons/icons-color-image.svg"></svg-icon>
    <p class="title">{{ header | translate }}</p>
    <span class="description">{{ description | translate }}</span>
    <input type="file" [accept]="formats" (change)="fileBrowseHandler($any($event.target).files)" />
  </ng-container>
</div>
