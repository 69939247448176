<div class="file-uploader" [class.has-files]="file">
  <span class="file-name" *ngIf="file">{{ file.name | maxChars : 42 }}</span>
  <input (change)="onFileChoose($any($event.target).files)" type="file" id="{{ uploaderId }}" hidden />
  <label (click)="onButtonClick($event)" class="upload-btn" for="{{ uploaderId }}" [class.d-none]="loading">
    <span *ngIf="!file">{{ 'GA.FILE_UPLOAD.ADD_FILE' | translate }}</span>
    <svg-icon *ngIf="file" src="/assets/icons/icons-trash.svg"></svg-icon>
  </label>
  <div class="loading" *ngIf="loading">
    <ui-loader color="dark" [showSpinner]="true" size="m"></ui-loader>
  </div>
</div>
