import { Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@Component({
  selector: 'ui-imported-sign',
  templateUrl: './imported-sign.component.html',
  styleUrls: ['./imported-sign.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, NgxTippyModule],
})
export class ImportedSignComponent {
  @Input() source?: string;
}
