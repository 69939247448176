<div class="wrapper" [ngClass]="{ active: expanded, error: error }">
  <header (click)="onToggleContent()">
    <div class="widget-header">
      <div class="widget-icon" *ngIf="widgetIcon">
        <img *ngIf="!isEmoji" [src]="widgetIcon" [alt]="header" />
        <div *ngIf="isEmoji" class="emoji">{{ icon }}</div>
      </div>
      <div class="widget-title">
        <p>{{ header }}</p>
      </div>
    </div>
    <div class="widget-actions" (click)="preventDefault($event)">
      <div class="own-page-badge" *ngIf="subCompany">
        <p>{{ 'SH.OWN_PAGE' | translate }}</p>
      </div>
      <ui-button-circle
        size="m"
        type="circle"
        icon="icons-trash"
        *ngIf="expanded && isRemovable"
        (click)="onRemove()"
        class="remove"
      ></ui-button-circle>
      <ui-button-circle
        size="m"
        type="circle"
        icon="icons-qr"
        *ngIf="expanded && permalink"
        [ngxTippy]="permalinkModalTemplate"
        [tippyProps]="permalinkTippyConfig"
      ></ui-button-circle>
      <ui-switch [value]="enabled" [(ngModel)]="enabled" (click)="onToggleActive()"></ui-switch>
      <ui-grabber *ngIf="isDraggable"></ui-grabber>
    </div>
  </header>
  <section [@toggleContent]="expanded ? 'open' : 'close'">
    <ng-content></ng-content>
  </section>
</div>
<ui-error-message *ngIf="error && !expanded" message="{{ 'SH.VISITORS_PLACE.ERROR' | translate }}"></ui-error-message>
<ng-template #permalinkModalTemplate>
  <ng-container *ngIf="permalink">
    <ng-content select="[permalink]"></ng-content>
  </ng-container>
</ng-template>
