<button
  class="button"
  type="button"
  [disabled]="disabled"
  [ngClass]="[iconSize, typeClass, sizeClass]"
  [class.active]="active"
>
  <ng-container *ngIf="icon">
    <svg-icon [src]="iconPath" class="icon"></svg-icon>
  </ng-container>
  <span *ngIf="text">{{ text }}</span>
  <ng-content></ng-content>

  <ng-container *ngIf="iconRight">
    <svg-icon [src]="iconRightPath" class="icon-right"></svg-icon>
  </ng-container>
  <svg-icon class="arrow" *ngIf="arrow" src="/assets/icons/icons-arrow-down.svg"></svg-icon>
  <span class="arrow-right" *ngIf="arrowRight">&nbsp;&nbsp;-></span>
</button>
