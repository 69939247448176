import { ALL_FUNCTIONALITIES, CompanyModules } from '@smarttypes/core';

export function getModules(companyModules: CompanyModules | undefined): ALL_FUNCTIONALITIES[] {
  return Object.keys(companyModules ?? []) as ALL_FUNCTIONALITIES[];
}

export function readModeModule(companyModules: CompanyModules | undefined, module: ALL_FUNCTIONALITIES): boolean {
  return companyModules?.[module] === 'read';
}

export function hasModule(companyModules: CompanyModules | undefined, module: ALL_FUNCTIONALITIES): boolean {
  return Object.keys(companyModules ?? [])?.includes(module);
}

export function hasModules(companyModules: CompanyModules | undefined, modules: ALL_FUNCTIONALITIES[]): boolean {
  return modules.every(m => Object.keys(companyModules ?? []).includes(m));
}

export function hasAtLeastOneModule(
  companyModules: CompanyModules | undefined,
  modules: ALL_FUNCTIONALITIES[],
): boolean {
  return modules.some(m => Object.keys(companyModules ?? []).includes(m));
}
