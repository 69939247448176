<div
  class="reservation-item"
  [class.is-past]="isPast"
  [class.is-past-year]="isPastYear"
  [class.no-scroll]="!showArrows"
  [class.is-current]="isCurrent"
  [class.is-active]="isActive"
>
  <div class="dot"></div>
  <div class="options" dropdown [isAnimated]="false">
    <ui-button-circle
      class="transparent"
      size="xs"
      type="quaternary"
      dropdownToggle
      icon="icons-dots-vertical"
    ></ui-button-circle>
    <ul *dropdownMenu [@ngIfDropdownOpen] class="dropdown-menu">
      <li (click)="onReservationClick(reservationClickActionEnum.goToChat)">
        <a class="dropdown-item">{{ 'SH.VISITORS_VIEW.VISITOR_HEADER.OPEN_CHAT' | translate }}</a>
      </li>
      <li (click)="onReservationClick(reservationClickActionEnum.edit)">
        <a class="dropdown-item">{{ 'SH.VISITORS_VIEW.VISITOR_HEADER.EDIT_RESERVATION' | translate }}</a>
      </li>
      <li (click)="onReservationClick(reservationClickActionEnum.showSecrets)" *ngIf="canShowSendSecrets">
        <a class="dropdown-item">{{ 'SH.VISITORS_VIEW.VISITOR_HEADER.SHOW_SECRETS' | translate }}</a>
      </li>
      <li (click)="onReservationClick(reservationClickActionEnum.importInfo)" *ngIf="isGuestImported">
        <a class="dropdown-item">{{ 'SH.VISITORS_VIEW.VISITOR_HEADER.IMPORT_INFO' | translate }}</a>
      </li>
      <li (click)="onReservationClick(reservationClickActionEnum.remove)">
        <a class="dropdown-item text-danger">{{ 'SH.VISITORS_VIEW.VISITOR_HEADER.DELETE_RESERVATION' | translate }}</a>
      </li>
    </ul>
  </div>
  <div class="description" (click)="onReservationClick(reservationClickActionEnum.changeContext)">
    <p>{{ stayRange }}</p>
    <span *ngIf="reservation?.from && isNotCurrentYear && isSameYear">{{ fromDate | date : 'y' }}</span>
    <span *ngIf="!isSameYear">{{ fromDate | date : 'y' }}-{{ toDate | date : 'y' }}</span>
  </div>
</div>
