<div class="phone">
  <div class="phone-inner">
    <div class="phone-header">
      <img
        srcset="
          /assets/images/iphone-top-bar.png    1x,
          /assets/images/iphone-top-bar@2x.png 2x,
          /assets/images/iphone-top-bar@3x.png 3x
        "
        src="/assets/images/iphone-top-bar.png"
        alt="iPhone top bar"
        class="phone-header-background"
      />
      <div class="phone-url">
        <span *ngIf="url; else noUrl">{{ url }}</span>
        <ng-template #noUrl>
          <span>{{ placeholder }}</span>
        </ng-template>
      </div>
    </div>
    <div class="phone-content">
      <main>
        <ng-content></ng-content>
      </main>
    </div>
    <div class="phone-dock"></div>
  </div>
</div>
