import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { AuthService } from '../services/auth.service';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private inj: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes('account/login')) {
      const authService = this.inj.get(AuthService);
      if (authService.isExpired && authService.refreshToken) {
        return authService.refreshJWTToken(authService.refreshToken).pipe(switchMap(() => next.handle(request)));
      }
    }
    return next.handle(request);
  }
}
