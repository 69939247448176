import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-guest-tag-add',
  templateUrl: './guest-tag-add.component.html',
  styleUrls: ['./guest-tag-add.component.scss'],
  standalone: true,
  imports: [TranslateModule, AngularSvgIconModule, CommonModule],
})
export class GuestTagAddComponent {
  @Input() @HostBinding('class.has-label') label = false;
}
