<svg xmlns="http://www.w3.org/2000/svg" width="94" height="100" viewBox="0 0 94 100">
  <g fill="none" fill-rule="evenodd" transform="translate(.125)">
    <path
      fill="#CACACA"
      d="M86.037234,47.0592295 L41.6888298,47.0592295 C37.4468085,47.0592295 33.9760638,50.5886717 33.9760638,54.9024344 L33.9760638,80.3928504 C33.9760638,84.7066131 37.4468085,88.2360553 41.6888298,88.2360553 L76.2053856,88.2360553 C78.3071144,88.2360553 80.2410904,89.3948888 81.2611037,91.2615716 L84.5236037,97.2322113 C85.2987367,98.6498706 86.6388298,99.7381153 88.2199468,99.9538034 C91.202859,100.36165 93.75,98.014571 93.75,95.0635651 L93.75,80.3928504 L93.75,54.9024344 C93.75,50.5886717 90.2792553,47.0592295 86.037234,47.0592295 Z"
    />
    <path
      fill="#9E9E9E"
      d="M71.3430851,0 L7.71276596,0 C3.47074468,0 0,3.52944221 0,7.84320491 L0,47.0592295 L0,56.8632356 L0,66.0554718 C0,68.9378496 1.79707447,71.6437553 4.54281915,72.3535653 C7.2962766,73.0653362 10.0613032,71.7810114 11.3744016,69.3751083 L16.0059176,60.8985645 C17.3672207,58.408347 19.947141,56.8632356 22.7488032,56.8632356 L71.3430851,56.8632356 C75.5851064,56.8632356 79.0558511,53.3337934 79.0558511,49.0200307 L79.0558511,7.84320491 C79.0558511,3.52944221 75.5851064,0 71.3430851,0 Z"
    />
    <path
      fill="#F2F2F2"
      d="M39.5279255,23.5296147 C36.8656486,23.5296147 34.7074468,25.7243163 34.7074468,28.4316178 C34.7074468,31.1389193 36.8656486,33.3336209 39.5279255,33.3336209 C42.1902024,33.3336209 44.3484043,31.1389193 44.3484043,28.4316178 C44.3484043,25.7243163 42.1902024,23.5296147 39.5279255,23.5296147 Z M56.8816489,23.5296147 C54.2193721,23.5296147 52.0611702,25.7243163 52.0611702,28.4316178 C52.0611702,31.1389193 54.2193721,33.3336209 56.8816489,33.3336209 C59.5439258,33.3336209 61.7021277,31.1389193 61.7021277,28.4316178 C61.7021277,25.7243163 59.5439258,23.5296147 56.8816489,23.5296147 Z M22.1742021,23.5296147 C19.5119252,23.5296147 17.3537234,25.7243163 17.3537234,28.4316178 C17.3537234,31.1389193 19.5119252,33.3336209 22.1742021,33.3336209 C24.836479,33.3336209 26.9946809,31.1389193 26.9946809,28.4316178 C26.9946809,25.7243163 24.836479,23.5296147 22.1742021,23.5296147 Z"
    />
  </g>
</svg>
<h2>{{ 'SH.CHAT.NO_CONVERSATION.TITLE' | translate }}</h2>
<p>{{ 'SH.CHAT.NO_CONVERSATION.DESCRIPTION' | translate }}</p>
