import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReservationStatusEnum } from '@smarttypes/hotel';

@Component({
  selector: 'ui-reservation-status',
  templateUrl: './reservation-status.component.html',
  styleUrls: ['./reservation-status.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule, TranslateModule],
  standalone: true,
})
export class ReservationStatusComponent {
  @Input() @HostBinding('class') status?: ReservationStatusEnum;
}
