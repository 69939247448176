import { CompanyTypeEnum } from '@smarttypes/core';
import { IRoom, RoomStatusEnum } from '@smarttypes/hotel';

export interface SelectOption {
  label: string;
  value: IRoom | string;
  icon?: string;
}

export function covertRoomToSelectOptions(
  rooms: IRoom[],
  companyType?: CompanyTypeEnum,
  returnObject = false,
): SelectOption[] {
  const isApartment = companyType === CompanyTypeEnum.apartments;
  return rooms.map((room: IRoom) => {
    return {
      label: isApartment && room.roomNo ? room.roomNo : [room.roomNo, room.roomType].filter(f => !!f).join(' '),
      value: returnObject ? room : (room._id as string),
      disabled: room.status === RoomStatusEnum.inactive,
    };
  }) as SelectOption[];
}
