<div class="roommate" *ngFor="let item of formArray?.controls; let index = index" [formGroup]="$any(item)">
  <div class="top" *ngIf="canAddRoommate">
    <div class="number">{{ index + 1 }}</div>
    <span class="delete-button" (click)="onDeleteClick(index)">{{ 'GA.ADD_ROOMMATE.REMOVE' | translate }}</span>
  </div>
  <ui-add-roommate-item
    [formGroup]="$any(item)"
    [formChanged$]="formChanged$"
    [index]="index"
    [countries]="countries"
  ></ui-add-roommate-item>
</div>
<span [class.less-margin]="hasRoommates" *ngIf="canAddRoommate" (click)="onAddClick()">{{
  (!hasRoommates ? 'GA.ADD_ROOMMATE.ADD_FIRST_ROOMMATE' : 'GA.ADD_ROOMMATE.ADD_NEXT_ROOMMATE') | translate
}}</span>
