<div class="navigation">
  <ul class="tabs">
    <li
      [attr.data-key]="tab.key"
      (click)="onTabClick(tab)"
      #listItem
      *ngFor="let tab of tabs"
      [ngClass]="{ active: tab.key === activeTab }"
    >
      <p>{{ tab?.label ?? '' | translate }}</p>
    </li>
  </ul>
</div>
