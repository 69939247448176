import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ITab } from './navigation.widget.interface';

@Component({
  selector: 'wg-navigation',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  @Input() tabs: ITab[] = [];
  @Output() tabClick: EventEmitter<ITab> = new EventEmitter();
  @Input() activeTab?: string | null = null;
  @ViewChildren('listItem', { read: ElementRef })
  listElements?: QueryList<ElementRef>;

  ngOnInit() {
    if (!this.activeTab) {
      this.activeTab = this.tabs ? this.tabs[0].key : '';
    }
  }

  onTabClick(tab: ITab): void {
    const tabElement = this.listElements?.find(
      el => el.nativeElement.getAttribute('data-key') === tab.key,
    )?.nativeElement;

    if (tabElement) {
      tabElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }

    this.tabClick.emit(tab);
  }
}
