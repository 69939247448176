<h3 *ngIf="cfg?.header" translate>{{ cfg.header }}</h3>
<input
  type="text"
  formInput
  [ngClass]="{ 'input-error': hasError }"
  [(ngModel)]="value"
  placeholder="{{ 'SH.FRONTEND_LIB.INPUT_MODAL.FOLDER_NAME' | translate }}"
  #input
/>
<div class="actions">
  <sh-button-rectangle
    size="m"
    type="tertiary"
    [label]="cfg.submit ?? '' | translate"
    (click)="onCancelClick()"
  ></sh-button-rectangle>
  <sh-button-rectangle
    size="m"
    type="primary"
    [label]="cfg.submit2 ?? '' | translate"
    (click)="onConfirmClick()"
  ></sh-button-rectangle>
</div>
