<ng-select
  class="ng-select-sh ng-select-100"
  [ngClass]="type"
  [class.ng-select-readonly]="readonly"
  [disabled]="locked"
  [clearable]="clearable"
  [searchable]="false"
  [typeToSearchText]="typeToSearchText"
  [multiple]="false"
  [items]="filteredItems"
  [ngModel]="value"
  (ngModelChange)="changeValue($event)"
  [readonly]="readonly"
  #select
  (close)="onClose()"
  [virtualScroll]="virtualScroll"
  [tabindex]="-1"
  [bindValue]="bindValue"
  (open)="onOpen()"
  [placeholder]="placeholder"
>
  <ng-template ng-header-tmp *ngIf="searchable">
    <input
      class="search-input"
      type="text"
      [placeholder]="typeToSearchText"
      autocomplete="off"
      (input)="onFilterChange($event)"
      #searchInput
    />
  </ng-template>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ng-container *ngIf="icon">
      <svg-icon [src]="iconPath" class="select-icon"></svg-icon>
    </ng-container>
    <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
    <span *ngIf="showLabel" class="ng-value-label">{{ countryCodeForSelected ? item?.alpha2Code : item?.name }}</span>
    <div class="arrow" *ngIf="arrow">
      <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow-icon"></svg-icon>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="ng-option-label country">
      <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
      <span>{{ item?.name }}</span>
      <span class="prefix" *ngIf="hasPrefix(item) && showPrefix">(+{{ item?.callingCodes[0] }})</span>
    </div>
    <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
  </ng-template>
</ng-select>
