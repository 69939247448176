import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { map, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { NavigationService } from '../../../core/services/navigation.service';
import { CheckAppsComponent } from './check-apps/check-apps.component';
import { CompaniesListComponent } from './companies-list/companies-list.component';
import { PoweredByComponent } from './powered-by/powered-by.component';

@Component({
  selector: 'sh-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, NgScrollbarModule, PoweredByComponent, CheckAppsComponent, CompaniesListComponent],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @HostBinding('class.navigation-open') isOpen = false;
  poweredBy: string | boolean = environment.poweredBy;
  showAppsBadge = environment?.ui?.showAppsBadge ?? false;
  companySwitcher = environment?.ui?.sidebar?.companySwitcher ?? true;
  isWindows = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly deviceService: DeviceDetectorService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isWindows = this.deviceService.getDeviceInfo().os?.toLowerCase() === 'windows';

    this.subscription.add(
      this.navigationService.$toggleNavigation.subscribe(open => {
        this.isOpen = open;
      }),
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          filter(() => this.isOpen),
        )
        .subscribe(() => {
          this.navigationService.toggleNavigation(false);
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
