import { NgModule } from '@angular/core';

import { CompanyTypeDirective } from './company-type-directive';
import { HasModulesDirective } from './has-modules.directive';
import { HasOneModuleDirective } from './has-one-module.directive';
import { HiddenFeatureDirective } from './hidden-feature.directive';
import { MobileBreakpointDirective } from './mobile-breakpoint.directive';
import { WriteAccessDirective } from './write-access-module.directive';

@NgModule({
  declarations: [
    HasModulesDirective,
    HasOneModuleDirective,
    HiddenFeatureDirective,
    CompanyTypeDirective,
    WriteAccessDirective,
    MobileBreakpointDirective,
  ],
  exports: [
    HasModulesDirective,
    HasOneModuleDirective,
    HiddenFeatureDirective,
    CompanyTypeDirective,
    WriteAccessDirective,
    MobileBreakpointDirective,
  ],
})
export class DirectivesModule {}
