<div class="review-box">
  <p *ngIf="rating?.Message" class="thanks-message">{{ rating?.Message }}</p>
  <ul>
    <li>
      <p>{{ 'SH.REVIEW.CLEANLINESS' | translate }}</p>
      <ui-review-stars
        [canSet]="false"
        [small]="true"
        [white]="true"
        [rate]="rating?.CategoryRatings?.cleanliness ?? 0"
      ></ui-review-stars>
    </li>
    <li>
      <p>{{ 'SH.REVIEW.COMMUNICATION' | translate }}</p>
      <ui-review-stars
        [canSet]="false"
        [small]="true"
        [white]="true"
        [rate]="rating?.CategoryRatings?.communication ?? 0"
      ></ui-review-stars>
    </li>
    <li>
      <p>{{ 'SH.REVIEW.RESPECT_HOUSE_RULES' | translate }}</p>
      <ui-review-stars
        [canSet]="false"
        [small]="true"
        [white]="true"
        [rate]="rating?.CategoryRatings?.respect_house_rules ?? 0"
      ></ui-review-stars>
    </li>
  </ul>
  <p class="recommendation">
    {{
      rating?.IsReviewRecommended
        ? ('SH.REVIEW.GUEST_RECOMMENDED' | translate)
        : ('SH.REVIEW.GUEST_NOT_RECOMMENDED' | translate)
    }}
  </p>
</div>
