import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent, PoweredByComponent } from '@ui/common';

@Component({
  selector: 'ui-account-setup-loading',
  templateUrl: './account-setup-loading.component.html',
  styleUrls: ['./account-setup-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, LoaderComponent, PoweredByComponent],
})
export class AccountSetupLoadingComponent {
  @Input() text = 'SH.CHAT.SETTING_UP_ACCOUNT';
}
