<div class="library-item" [ngClass]="{ 'has-preview': hasThumbnail }">
  <div class="library-item-image">
    <svg-icon src="/assets/icons/icons-pdf.svg" class="pdf" *ngIf="!hasThumbnail"></svg-icon>
    <svg-icon src="/assets/icons/icons-image.svg" class="img" *ngIf="isList && hasThumbnail"></svg-icon>
    <img [src]="thumbnail" class="image-preview" [alt]="item.fileName" *ngIf="hasThumbnail" />
    <div class="library-item-actions">
      <ui-button-circle
        type="quaternary"
        icon="icons-link"
        [size]="!isList ? 's' : 'm'"
        (click)="onCopyLink($event)"
      ></ui-button-circle>
      <ui-button-circle
        type="quaternary"
        icon="icons-download-small"
        size="m"
        (click)="onItemDownload($event)"
      ></ui-button-circle>
    </div>
  </div>
  <div class="library-item-caption">
    <p>{{ item.displayName || item.fileName }}</p>
  </div>
</div>
