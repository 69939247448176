import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
  pure: false,
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern = 'mediumDate', timezone?: string): string | null {
    // @TODO: MOVE AVAILABLE UI LANGUAGES TO ENVS !!!!
    const availableLanguages = ['en', 'pl', 'es', 'de', 'ru', 'hr', 'uk']; // TODO: Find a better way to check if localize is registered
    const lng = availableLanguages.includes(this.translateService.currentLang)
      ? this.translateService.currentLang
      : 'en';
    const datePipe: DatePipe = new DatePipe(lng);
    if (timezone) {
      return datePipe.transform(value, pattern, timezone);
    }
    return datePipe.transform(value, pattern);
  }
}
