<div [ngSwitch]="question?.type" [class.disabled]="preview" [formGroup]="form" *ngIf="form">
  <ng-container *ngSwitchCase="questionTypeEnum.phone">
    <ui-phone-number
      *ngIf="countries"
      [guestArea]="isGuestArea"
      [countries]="countries"
      formControlName="answer"
      [label]="question?.title || ''"
      [required]="isRequired"
    ></ui-phone-number>
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.number">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input type="number" class="form-input" formInput formControlName="answer" />
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.houseNumber">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input type="number" class="form-input" formInput formControlName="answer" />
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.apartmentNumber">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input *ngIf="!isGuestArea" type="number" class="form-input" formInput formControlName="answer" />
    <ui-select
      *ngIf="isGuestArea"
      [addTag]="addTag"
      type="ng-select-input-big ng-select-100"
      [items]="rooms"
      bindLabel="label"
      bindValue="value"
      [searchable]="true"
      [arrow]="true"
      (loadMore)="onLoadMoreRooms()"
      [loading]="roomLoading"
      [typeahead]="roomInput$"
      [typeToSearchText]="'GA.SEARCH_ROOM' | translate"
      [clearSelected]="true"
      formControlName="answer"
    ></ui-select>
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.checkIn">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input
      type="text"
      onfocus="blur()"
      (keydown)="$event.preventDefault()"
      formControlName="answer"
      formInput
      [bsConfig]="bsConfig"
      bsDatepicker
      autocomplete="off"
    />
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.checkOut">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input
      type="text"
      onfocus="blur()"
      (keydown)="$event.preventDefault()"
      formControlName="answer"
      formInput
      [bsConfig]="bsConfig"
      bsDatepicker
      autocomplete="off"
    />
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.nationality">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <ui-select-country
      *ngIf="countries"
      type="ng-select-input-big"
      typeToSearchText="{{ translatePrefix + '.SEARCH_COUNTRY' | translate }}"
      placeholder="{{ translatePrefix + '.SEARCH_NATIONALITY' | translate }}"
      [items]="countries"
      formControlName="answer"
      bindValue="alpha2Code"
      [disableLangSwitch]="true"
      [guestArea]="true"
      [loading]="false"
      [searchable]="true"
      [showLabel]="true"
      [arrow]="true"
      [showPrefix]="false"
      [setInitialCountry]="false"
    ></ui-select-country>
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.language">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <ui-select
      *ngIf="languages"
      type="ng-select-input-big ng-select-100"
      [clearSelected]="false"
      [items]="languages"
      [bindLabel]="'name'"
      [bindValue]="'iso639_1'"
      formControlName="answer"
      [clearable]="false"
      [searchable]="true"
    ></ui-select>
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.yesNo">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <ui-select
      type="ng-select-input-big ng-select-100"
      [clearSelected]="false"
      [items]="yesNo"
      bindValue="value"
      bindLabel="displayName"
      formControlName="answer"
      [clearable]="false"
      [searchable]="true"
    ></ui-select>
  </ng-container>
  <ng-container *ngSwitchCase="questionTypeEnum.date">
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input
      type="text"
      onfocus="blur()"
      (keydown)="$event.preventDefault()"
      formControlName="answer"
      formInput
      [bsConfig]="bsConfig"
      bsDatepicker
      autocomplete="off"
    />
  </ng-container>
  <ng-container *ngSwitchDefault>
    <label class="label">{{ question?.title }}<em *ngIf="isRequired">*</em></label>
    <input type="text" class="form-input" formInput formControlName="answer" autocomplete="off" />
  </ng-container>
  <ui-error-message [prefix]="translatePrefix" [control]="form.get('answer')"></ui-error-message>
</div>
