import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'sh-check-apps',
  templateUrl: './check-apps.component.html',
  styleUrls: ['./check-apps.component.scss'],
  standalone: true,
  imports: [TranslateModule, AngularSvgIconModule],
})
export class CheckAppsComponent {}
