<button
  class="button"
  type="button"
  [disabled]="disabled"
  [class]="class"
  [ngClass]="[iconSize, sizeClass, typeClass, iconColor]"
>
  <svg-icon *ngIf="!pending" [src]="iconPath"></svg-icon>
  <ui-loader *ngIf="pending" [showSpinner]="pending" [size]="size" color="dark"></ui-loader>
</button>
