import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-star-icon',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  templateUrl: './star-icon.component.html',
  styleUrls: ['./star-icon.component.scss'],
})
export class StarIconComponent {
  @Input() @HostBinding('class.small') small = false;
}
