<svg-icon class="close" (click)="onCloseClick()" src="/assets/icons/icons-close.svg"></svg-icon>

<h1>{{ 'GA.MY_RESERVATIONS' | translate }}</h1>
<div class="reservations">
  <ui-navigation [tabs]="tabs" [activeTab]="activeTab" (tabClick)="tabChange($event)"></ui-navigation>

  <ng-container [ngSwitch]="activeTab">
    <ng-container *ngSwitchCase="reservationTypeEnum.active">
      <ng-container *ngFor="let item of activeReservations">
        <ui-my-profile-reservations-item
          (click)="onReservationClick(item)"
          (checkinClick)="onCheckInClick($event)"
          [reservation]="item"
        ></ui-my-profile-reservations-item>
      </ng-container>
      <ui-empty
        *ngIf="activeReservations.length === 0"
        icon=""
        label="{{ 'GA.MY_RESERVATIONS.EMPTY' | translate }}"
        content="{{ 'GA.MY_RESERVATIONS.ACTIVE_EMPTY' | translate }}"
      ></ui-empty>
    </ng-container>
    <ng-container *ngSwitchCase="reservationTypeEnum.past">
      <ng-container *ngFor="let item of pastReservations">
        <ui-my-profile-reservations-item
          (click)="onReservationClick(item)"
          (checkinClick)="onCheckInClick($event)"
          [isPast]="true"
          [checkInButton]="false"
          [reservation]="item"
        ></ui-my-profile-reservations-item>
      </ng-container>
      <ui-empty
        *ngIf="pastReservations.length === 0"
        icon=""
        label="{{ 'GA.MY_RESERVATIONS.EMPTY' | translate }}"
        content="{{ 'GA.MY_RESERVATIONS.PAST_EMPTY' | translate }}"
      ></ui-empty>
    </ng-container>
  </ng-container>
</div>
