import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ICountry } from '@smarttypes/core';
import { ErrorMessageComponent, FormComponent, PhoneNumberComponent } from '@ui/common/forms';
import { FormControlDirective } from 'angular-v2-directives';
import { get } from 'lodash';
import { debounceTime, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'ui-add-roommate-item',
  templateUrl: './add-roommate-item.component.html',
  styleUrls: ['./add-roommate-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormComponent,
    ErrorMessageComponent,
    PhoneNumberComponent,
    FormControlDirective,
  ],
})
export class AddRoommateItemComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [Validators.email]),
  });
  @Input() countries?: ICountry[];
  @Input() formChanged$!: Observable<unknown[]>;
  @Input() index = 0;
  private subscriptions: Subscription = new Subscription();
  private collection: { email: string[]; phone: string[] } = {
    email: [],
    phone: [],
  };

  ngOnInit() {
    this.subscriptions.add(
      this.formChanged$
        .pipe(
          debounceTime(150),
          map(form => {
            const f = [...form];
            delete f[this.index];
            return f;
          }),
          map(res => {
            return {
              email: this.getValuesFromArray(res, 'email'),
              phone: this.getValuesFromArray(res, 'phone'),
            };
          }),
        )
        .subscribe(res => {
          this.collection = res;
        }),
    );
    this.subscriptions.add(this.contactFieldChange('email', 'onlyUniqueEmails')?.subscribe());
    this.subscriptions.add(this.contactFieldChange('phone', 'onlyUniquePhones')?.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getValuesFromArray(form: unknown[], field: string) {
    return form?.map((arr: unknown) => get(arr, `${field}`, '')).filter((a: string) => !!a);
  }

  private contactFieldChange(field: string, errorKey: string) {
    return this.formGroup?.get(field)?.valueChanges.pipe(
      debounceTime(300),
      map(value => !(get(this.collection, `${field}`, []) as string[]).includes(value)),
      tap(unique => {
        const error = unique
          ? null
          : {
              [errorKey]: true,
            };
        this.formGroup?.get(field)?.setErrors(error);
      }),
    );
  }

  fieldId(field: string): string {
    return `roommate-${field}-${this.index}`;
  }
}
