export interface IWidgetOpenDay {
  isOpen: boolean;
  from: string;
  to: string;
  day: string;
}

export interface WidgetOpenGroupDays {
  days: IWidgetOpenDay[];
  isOpen: boolean;
  from: string;
  to: string;
  label?: string;
  labelFrom?: string;
  labelTo?: string;
}

export enum WidgetOpenDaysEnum {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export const DayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
