<ng-select
  class="ng-select-sh ng-select-new"
  [clearable]="false"
  [searchable]="false"
  [multiple]="false"
  [items]="languages"
  bindValue="iso639_1"
  [formControl]="control"
  [tabindex]="-1"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
    <div class="arrow">
      <svg-icon src="/assets/icons/icons-arrow-down.svg" class="arrow-icon"></svg-icon>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="ng-option-label country">
      <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
      <span>{{ item?.nativeName || item?.name }}</span>
    </div>
    <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
  </ng-template>
  <ng-template ng-footer-tmp *ngIf="newLanguage">
    <div class="ng-option">
      <svg-icon src="/assets/icons/add-language.svg" class="ng-option-icon"></svg-icon>
      <div class="ng-option-label" (click)="addLang.emit()">
        <span>{{ 'SH.SELECT_LANGUAGE' | translate }}</span>
      </div>
    </div>
  </ng-template>
</ng-select>
