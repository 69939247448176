import { Pipe, PipeTransform } from '@angular/core';
import { sortBy } from 'lodash';

@Pipe({
  name: 'sortyBy',

  standalone: true,
})
export class SortyByPipe implements PipeTransform {
  transform<T>(value: T[], fieldName: string): T[] {
    return sortBy(value ?? [], fieldName);
  }
}
