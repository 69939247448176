<div class="step-progress" *ngIf="steps">
  <ng-container *ngFor="let step of steps; let i = index">
    <div
      class="step"
      (click)="onStepClick(step)"
      [ngClass]="{
        'step-active': currentStep?.id === step.id,
        disabled: !step?.canActivate,
        'step-complete': currentStepIndex > i
      }"
    >
      <div class="step-bullet"></div>
      <div class="step-label">
        <span>{{ 'SH.STEP' | translate }} {{ i + 1 }}</span>
      </div>
      <div class="step-title">
        <p>{{ step.label | translate }}</p>
      </div>
    </div>
  </ng-container>
</div>
