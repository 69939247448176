<div class="pre-approval-text">
  <strong *ngIf="!isApproved">{{ 'SH.CHAT.APPROVAL.INVITE' | translate : { guest: guestFullName } }}</strong>
  <strong *ngIf="isApproved">{{ 'SH.CHAT.APPROVAL.INVITED' | translate : { guest: guestFullName } }}</strong>
  <p>{{ 'SH.CHAT.APPROVAL.CALENDAR_OPEN' | translate }}</p>
</div>
<button
  class="pre-approval-button"
  [ngClass]="{ send: !isApproved, withdraw: isApproved }"
  [disabled]="processing"
  (click)="buttonClick()"
>
  <span>{{
    isApproved
      ? ('SH.CHAT.APPROVAL.WITHDRAW_PRE_APPROVAL' | translate)
      : ('SH.CHAT.APPROVAL.SEND_PRE_APPROVAL' | translate)
  }}</span>
</button>
