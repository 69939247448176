import linkifyHtml from 'linkify-html';
import { isString } from 'lodash';

export function convertLinksToHTML(input: string): string {
  if (!isString(input)) {
    throw new Error('Input must be a string');
  }
  return linkifyHtml(input, {
    className: 'link',
    target: '_blank',
  });
}
