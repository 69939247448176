import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ui-beta-badge',
  templateUrl: './beta-badge.component.html',
  styleUrls: ['./beta-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetaBadgeComponent {
  @Input() text = 'SH.BETA';
}
