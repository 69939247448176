import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-linked-objects-button',
  templateUrl: './linked-objects-button.component.html',
  styleUrls: ['./linked-objects-button.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, AngularSvgIconModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedObjectsButtonComponent {
  @Input() counter = 0;
  @Input() @HostBinding('class.small') small = false;
}
