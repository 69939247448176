export enum CommunicationFunctionality {
  SmartAssistant = 'communication-smart-assistant',
  WhatsApp = 'communication-WhatsApp',
  Sms = 'communication-Sms',
  Email = 'communication-Email',
  SmartChat = 'communication-SmartChat',
  BookingCom = 'communication-BookingCom',
  AirBnB = 'communication-AirBnB',
  FacebookMessenger = 'communication-FacebookMessenger',
}
