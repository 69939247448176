@if (poweredBy) {
  <sh-powered-by></sh-powered-by>
}
@if (companySwitcher) {
  <sh-companies-list></sh-companies-list>
}
<ng-scrollbar [visibility]="isWindows ? 'native' : 'hover'">
  <ng-content></ng-content>
  @if (showAppsBadge) {
    <sh-check-apps></sh-check-apps>
  }
</ng-scrollbar>
