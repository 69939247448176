import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiValidatorService, WindowService } from '@smarthotel/angular-services';
import { ICountry, IFileItem, ILanguage } from '@smarttypes/core';
import { IGuest, IRoom } from '@smarttypes/hotel';
import { IAnswer, IConsentAnswer, IRegistrationCardConfig, QuestionTypeEnum } from '@smarttypes/registration-card';
import { SelectOption, untilDestroyed } from 'angular-v2-utils';
import { get, set, unset } from 'lodash';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';

import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { CheckinFormQuestionComponent } from './checkin-form-question/checkin-form-question.component';
import { CheckinSignatureModalComponent } from './checkin-signature-modal/checkin-signature-modal.component';

export interface FormQuestion {
  required?: boolean;
  order?: number;
  title?: string;
  type?: string;
}

export interface RegistrationCardFillData {
  signature?: string;
  answers: IAnswer[];
  consentAnswers: IConsentAnswer[];
  configurationCard: any;
  registrationAttachments?: IFileItem[];
}

interface Translations {
  lang: string;
  translation: Record<string, string>;
}

@Component({
  selector: 'sh-checkin-form',
  templateUrl: './checkin-form.component.html',
  styleUrls: ['./checkin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() disableSignatureAndConsentsEdit = false;
  @Input() preview = false;
  @Input() surveyMode = false;
  @Input() languages?: ILanguage[];
  @Input() countries?: ICountry[];
  @Input() fillData?: RegistrationCardFillData;
  @Output() save: EventEmitter<{ form: FormGroup; roommates: FormArray }> = new EventEmitter<{
    form: FormGroup;
    roommates: FormArray;
  }>();
  @Output() fileAdded: EventEmitter<{
    file: File;
    fb: FormGroup;
    index: number;
  }> = new EventEmitter<{ file: File; fb: FormGroup; index: number }>();
  @Output() fileRemove: EventEmitter<{ file: File; index: number }> = new EventEmitter<{ file: File; index: number }>();
  form?: FormGroup;
  @Input() guest?: IGuest;
  questionType = QuestionTypeEnum;
  @ViewChild('signature') signaturePad?: SignaturePadComponent;
  @ViewChildren('question')
  questionsComponents?: QueryList<CheckinFormQuestionComponent>;
  @ViewChildren('uploader') uploaders?: QueryList<FileUploaderComponent>;
  signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 5,
    canvasWidth: 452,
    canvasHeight: 100,
    backgroundColor: '#f2f2f2',
    penColor: '#18cff7',
  };
  showSignaturePadInfo = true;
  isSigned = false;
  @Input() translations?: Translations[];
  @Input() showRoommates = false;
  roommateFormArray?: FormArray = new FormArray<any>([]);
  @Output() roommateChange = new EventEmitter<FormArray>();
  @Output() loadMoreRooms = new EventEmitter<{
    term: string;
    initial: boolean;
  }>();

  constructor(
    private apiValidator: ApiValidatorService,
    private modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    private windowService: WindowService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  private _translatePrefix: 'GA' | 'SH' = 'GA';

  get translatePrefix(): 'GA' | 'SH' {
    return this._translatePrefix;
  }

  @Input()
  set translatePrefix(prefix: 'GA' | 'SH') {
    this._translatePrefix = prefix;
  }

  private _rooms: SelectOption[] = [];

  get rooms(): SelectOption[] {
    return this._rooms;
  }

  @Input()
  set rooms(rooms: SelectOption[]) {
    this._rooms = rooms;
  }

  private _roomLoading = false;

  get roomLoading(): boolean {
    return this._roomLoading;
  }

  @Input()
  set roomLoading(roomLoading: boolean) {
    this._roomLoading = roomLoading;
  }

  private _pending = false;

  get pending(): boolean {
    return this._pending;
  }

  @Input()
  set pending(pending: boolean) {
    this._pending = pending;
  }

  private _registrationCardConfig?: IRegistrationCardConfig;

  get registrationCardConfig(): IRegistrationCardConfig | undefined {
    return this._registrationCardConfig;
  }

  @Input() set registrationCardConfig(card: IRegistrationCardConfig | undefined) {
    this._registrationCardConfig = card;

    this.onRegistrationCardLoad();
  }

  get isFirstnameAndLastname(): boolean {
    return (
      this.form?.value?.questions.some((item: FormQuestion) => item.type === QuestionTypeEnum.collectFirstName) &&
      this.form?.value?.questions.some((item: FormQuestion) => item.type === QuestionTypeEnum.collectLastName)
    );
  }

  get isCheckInAndCheckOut(): boolean {
    return (
      this.form?.value?.questions.some((item: FormQuestion) => item.type === QuestionTypeEnum.checkIn) &&
      this.form?.value?.questions.some((item: FormQuestion) => item.type === QuestionTypeEnum.checkOut)
    );
  }

  get isDigitalSignatureEnabled(): boolean {
    return !!this.registrationCardConfig?.digitalSignature;
  }

  get showSignatureClearButton(): boolean {
    return (
      this.windowService.innerWidth > 768 &&
      !this.preview &&
      this.form?.get('digitalSignature')?.value?.length > 0 &&
      !this.disableSignatureAndConsentsEdit
    );
  }

  get roomImage(): string | undefined {
    return this.room?.apartmentInfo?.additionalInfo?.['linkToPhotoImage'] as string;
  }

  get room(): IRoom | undefined {
    return this.guest?.reservation?.room ? (this.guest?.reservation?.room as IRoom) : undefined;
  }

  get signatureTranslation(): string {
    if (this.translations?.length) {
      const translation = this.translations.find(
        item => item.lang === this.registrationCardConfig?.language,
      )?.translation;
      return this.translateService.instant(
        (translation && translation[`${this.translatePrefix}.CHECK_IN-FORM.GUEST_SIGNATURE_HERE`]) || '',
      );
    }

    return this.translateWithPrefix('CHECK_IN-FORM.GUEST_SIGNATURE_HERE');
  }

  get checkIn(): string {
    const indexCheckIn = this.form?.value?.questions?.findIndex((item: any) => item.type === QuestionTypeEnum.checkIn);

    return (this.form?.get('questions') as FormArray).at(indexCheckIn)?.get('answer')?.value;
  }

  get checkOut(): string {
    const indexCheckOut = this.form?.value?.questions?.findIndex(
      (item: any) => item.type === QuestionTypeEnum.checkOut,
    );

    return (this.form?.get('questions') as FormArray).at(indexCheckOut)?.get('answer')?.value;
  }

  get allowToSign(): boolean {
    if (!this.alreadySigned) {
      return true;
    }
    return this.alreadySigned && (this.form?.dirty ?? false);
  }

  get alreadySigned(): boolean {
    return !!this.guest?.reservation?.actions?.registrationCardSignedAt;
  }

  get submitText(): string {
    if (this.surveyMode) {
      return this.translateWithPrefix('CHECK_IN.SEND_FORM');
    }
    if (this.alreadySigned) {
      return this.translateWithPrefix('CHECK_IN.RE_SIGN_CARD');
    }
    return this.translateWithPrefix('CHECK_IN.SIGN_CARD');
  }

  get backText(): string {
    if (this.surveyMode) {
      return this.translateWithPrefix('BACK_TO_VISITORS_AREA');
    }
    return this.translateWithPrefix('CHECK_IN.BACK_TO_RESERVATION');
  }

  ngOnInit(): void {
    if (this.preview) {
      this.translatePrefix = 'SH';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.fillData?.signature) {
        this.signaturePad?.fromDataURL(this.fillData.signature);
        this.cdr.detectChanges();
      }
    }, 150);
  }

  onReturn(): void {
    this.router.navigate(['/']);
  }

  onSaveClick(): void {
    this.save.emit({
      form: this.form as FormGroup,
      roommates: this.roommateFormArray as FormArray,
    });
  }

  onSignatureClick(): void {
    if (this.preview || this.windowService.innerWidth > 768) {
      return;
    }

    this.modalService
      .show(CheckinSignatureModalComponent, {
        initialState: {
          formControl: this.form?.get('digitalSignature'),
          prefix: this.translatePrefix,
        },
        class: 'modal-default modal-checkin-signature',
      })
      ?.content?.action?.pipe(untilDestroyed(this))
      .subscribe((data: string) => {
        if (data) {
          this.form?.get('digitalSignature')?.patchValue(data);
          this.signaturePad?.fromDataURL(data);
        }
        this.isSigned = false;
        this.showSignaturePadInfo = false;
        this.cdr.detectChanges();
      });
  }

  clearSignature(): void {
    this.signaturePad?.clear();
    this.form?.get('digitalSignature')?.patchValue('');
  }

  onDrawEnd(): void {
    this.form?.get('digitalSignature')?.patchValue(this.signaturePad?.toDataURL());
  }

  translateWithPrefix(key: string): string {
    return this.translateService.instant(`${this.translatePrefix}.${key}`);
  }

  onFileAdd(file: File, fileFormGroup: FormGroup, index: number): void {
    this.fileAdded.emit({ file, fb: fileFormGroup, index });
    fileFormGroup.get('name')?.patchValue(file.name);
  }

  onFileRemove(file: File, index: number, fileFormGroup: FormGroup): void {
    this.fileRemove.emit({ file, index: index });
    fileFormGroup.get('name')?.patchValue(null);
  }

  isCheckinOrCheckout(control: FormGroup): boolean {
    return control?.value?.type === QuestionTypeEnum.checkIn || control?.value?.type === QuestionTypeEnum.checkOut;
  }

  disableUploaderLoading(index: number): void {
    const uploader = this.uploaders?.get(index);

    if (uploader) {
      uploader.loading = false;
      this.cdr.detectChanges();
    }
  }

  onRoommateChange(event: FormArray): void {
    this.roommateFormArray = event;
  }

  onLoadMoreRooms(event: { term: string; initial: boolean }): void {
    this.loadMoreRooms.emit({ term: event.term, initial: event.initial });
  }

  private onRegistrationCardLoad(): void {
    this.prepareForm();
  }

  private prepareForm(): void {
    this.form = new FormGroup({
      questions: new FormArray([]),
      consents: new FormArray([]),
      title: new FormControl(this.registrationCardConfig?.title),
      digitalSignature: new FormControl('', this.registrationCardConfig?.digitalSignature ? [Validators.required] : []),
      footer: new FormControl(this.registrationCardConfig?.footer),
      registrationAttachments: new FormArray([]),
    });

    this.pushQuestionsIntoForm('questions');
    this.pushQuestionsIntoForm('consents');
    this.pushFilesIntoForm();

    this.setDateListeners();
  }

  private pushQuestionsIntoForm(type: string): void {
    const questions = Array.from(get(this.registrationCardConfig, type) || []);

    questions
      .sort((a: any, b: any) => a.order - b.order)
      .forEach((item: any) => {
        const formGroup = new FormGroup({
          type: new FormControl(type === 'questions' ? get(item, 'questionType') : get(item, 'consentType')),
          required: new FormControl(item.isRequired),
          title: new FormControl(item.title),
          order: new FormControl((questions?.indexOf(item) || 0) + 1),
          answer: new FormControl(null, item.isRequired ? [Validators.required] : []),
        });

        if ((formGroup.get('type')?.value as string)?.toLowerCase() === 'email') {
          formGroup.get('answer')?.addValidators(this.apiValidator.validateEmail as ValidatorFn);
        }

        (this.form?.get(type) as FormArray)?.push(formGroup, {
          emitEvent: false,
        });
      });
  }

  private pushFilesIntoForm(): void {
    const files = Array.from(get(this.registrationCardConfig, 'registrationAttachments') || []);

    files.forEach((item: any) => {
      const formGroup = new FormGroup({
        title: new FormControl(item.title),
        required: new FormControl(item.isRequired),
        name: new FormControl(null, item.isRequired ? [Validators.required] : []),
      });

      (this.form?.get('registrationAttachments') as FormArray)?.push(formGroup, {
        emitEvent: false,
      });
    });
  }

  private fillForm(): void {
    if (!this.fillData) {
      return;
    }

    if (this.registrationCardConfig) {
      if (this.fillData?.signature) {
        set(this.registrationCardConfig, 'digitalSignature', this.fillData?.signature);
        this.form?.get('digitalSignature')?.patchValue(this.fillData?.signature);
      }

      this.fillData.answers.forEach((answer: IAnswer, index: number) => {
        const control = (this.form?.get('questions') as FormArray)?.at(index);
        control?.get('answer')?.patchValue(answer.answer);
      });

      this.fillData.consentAnswers.forEach((answer: IConsentAnswer, index: number) => {
        const control = (this.form?.get('consents') as FormArray)?.at(index);
        control?.get('answer')?.patchValue(answer.answer);
      });
    }
  }

  private setDateListeners(): void {
    setTimeout(() => {
      if (
        this.form?.value?.questions?.some((item: any) => item.type === QuestionTypeEnum.checkIn) &&
        this.form?.value?.questions?.some((item: any) => item.type === QuestionTypeEnum.checkOut)
      ) {
        const indexCheckIn = this.form?.value?.questions?.findIndex(
          (item: any) => item.type === QuestionTypeEnum.checkIn,
        );
        const indexCheckOut = this.form?.value?.questions?.findIndex(
          (item: any) => item.type === QuestionTypeEnum.checkOut,
        );
        const questionCheckInComponent = this.questionsComponents?.find((item, i) => i === indexCheckOut);
        const questionCheckOutComponent = this.questionsComponents?.find((item, i) => i === indexCheckIn);
        const checkInControl = (this.form?.get('questions') as FormArray).at(indexCheckIn)?.get('answer');
        const checkOutControl = (this.form?.get('questions') as FormArray).at(indexCheckOut)?.get('answer');

        checkInControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (questionCheckInComponent) {
            if (checkInControl?.value) {
              set(
                questionCheckInComponent.bsConfig,
                'minDate',
                new Date(moment(checkInControl?.value).add(1, 'days').format('YYYY-MM-DD')),
              );
            } else {
              unset(questionCheckInComponent.bsConfig, 'minDate');
            }
          }
        });

        checkOutControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (questionCheckOutComponent) {
            if (checkOutControl?.value) {
              set(
                questionCheckOutComponent.bsConfig,
                'maxDate',
                new Date(moment(checkOutControl?.value).subtract(1, 'days').format('YYYY-MM-DD')),
              );
            } else {
              unset(questionCheckOutComponent.bsConfig, 'maxDate');
            }
          }
        });
      }

      this.fillForm();
      this.cdr.detectChanges();
    }, 150);
  }

  ngOnDestroy() {}
}
