<div class="width-100" [class.additional-margin]="isExpandable && lines > 1" (click)="checkbox.click()">
  <ui-check [checked]="checkboxValue" [disabled]="disabled" [class.small-gap]="smallGap"></ui-check>
  <div class="label">
    <input
      type="checkbox"
      class="form-checkbox"
      [name]="id ?? ''"
      [disabled]="disabled"
      [(ngModel)]="checkboxValue"
      [required]="required"
      #checkbox
    />
    <span
      #textElement
      class="form-checkbox-text"
      [innerHTML]="text"
      [ngClass]="{ required: required, collapsed: !expanded }"
    ></span>
  </div>
  <span *ngIf="isExpandable && lines > 1" class="toggle-link" (click)="onExpandClick()">
    {{
      isCollapsed
        ? (translationPrefix + 'CHECKBOX.EXPAND' | translate)
        : (translationPrefix + 'CHECKBOX.COLLAPSE' | translate)
    }}
  </span>
</div>
