<ui-modal-base [header]="headerTitle" [subtitle]="subtitle">
  <div content>
    <ui-uploader
      *ngIf="!file && !fileEditOriginalUrl"
      [maxSize]="maxSize"
      [formats]="formats"
      (fileAdded)="onFileAdded($event)"
      [minWidth]="minWidth"
      [minHeight]="minHeight"
      [description]="uploaderDescription"
    ></ui-uploader>

    <ng-container *ngIf="allowEdit">
      <ng-container *ngIf="logoMode">
        <div class="logo-tip2">
          <svg-icon src="/assets/icons/icons-information-alt.svg"></svg-icon>
          <p>{{ 'SH.IMAGE_PICKER.LOGO.NO_SPACE_TIP' | translate }}</p>
        </div>
        <p class="crop-info">{{ 'SH.IMAGE_PICKER.LOGO.USE_CLIPPING_TOOL' | translate }}</p>
      </ng-container>

      <div class="cropper">
        <ui-tip *ngIf="logoMode" class="logo-tip" placement="left-start" [offset]="[16, 4]">
          <div class="logo-tip-template">
            <p>{{ 'SH.FORM.IDENTIFICATION.LOGO_PICKER.TIP3' | translate }}</p>
            <svg-icon src="/assets/images/upload-logo-tip.svg"></svg-icon>
          </div>
        </ui-tip>
        <image-cropper
          *ngIf="!isSvg && file"
          [class.light-bg]="file && isLogo"
          [imageFile]="file"
          output="base64"
          [maintainAspectRatio]="lockedRatio"
          [aspectRatio]="aspectRatio"
          format="png"
          [autoCrop]="false"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </div>
    </ng-container>

    <ng-container *ngIf="(canReplace && fileEditOriginalUrl) || (!isLogo && fileEditOriginalUrl); else defaultMode">
      <div class="edit-buttons">
        <ng-container *ngIf="!allowEdit; else saveButton">
          <ui-button-rectangle
            type="secondary"
            size="l"
            label="{{ 'SH.CHANGE_LOGO' | translate }}"
            (click)="onChangeLogoClick()"
          ></ui-button-rectangle>
        </ng-container>
        <ng-template #saveButton>
          <ui-button-rectangle
            *ngIf="!isSvg"
            [pending]="pending"
            type="secondary"
            size="l"
            label="{{ 'SH.SAVE' | translate }}"
            (click)="crop()"
          ></ui-button-rectangle>
        </ng-template>
        <ui-button-rectangle
          type="danger"
          size="l"
          label="{{ 'SH.DELETE' | translate }}"
          (click)="delete()"
        ></ui-button-rectangle>
      </div>
    </ng-container>
    <ng-template #defaultMode>
      <ui-button-rectangle
        *ngIf="file"
        [pending]="pending"
        type="primary"
        size="l"
        label="{{ (isLogo ? 'SH.ITS_CROPPED_ADD_LOGO' : 'SH.ITS_CROPPED_ADD_IMAGE') | translate }}"
        (click)="crop()"
      ></ui-button-rectangle>
    </ng-template>
  </div>
</ui-modal-base>
