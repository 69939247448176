<div class="page-content" [class.search]="isSearch">
  <div class="page-header" *ngIf="!isSearch">
    <h1>{{ name }}</h1>
    <ui-beta-badge *ngIf="beta"></ui-beta-badge>
  </div>
  <div class="page-actions">
    <ng-content></ng-content>
  </div>
</div>
<p *ngIf="description"><span [innerHTML]="description"></span></p>
