import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMediaFile } from '@smarttypes/core';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { convertUrlToCdnCgi, copyToClipboard } from 'angular-v2-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-media-library-item',
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, ButtonCircleComponent],
  templateUrl: './media-library-item.component.html',
  styleUrls: ['./media-library-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaLibraryItemComponent {
  @Input() item!: IMediaFile;
  @Input() isList = true;

  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {}

  @HostBinding('class')
  get hostClass() {
    return this.isList ? 'list' : 'tile';
  }

  get fileUrl(): string {
    const url = this.item.shortUrl ?? (this.item.url as string);
    return url.includes('https://') ? url : `https://${url}`;
  }

  get hasThumbnail(): boolean {
    return !!this.item?.contentType?.includes('image');
  }

  get thumbnail(): string {
    if (!this.hasThumbnail) {
      return '';
    }
    return convertUrlToCdnCgi(this.item.bucket, this.item.filePath, this.item.url, {
      width: 200,
      quality: 70,
    });
  }

  onItemDownload($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    const link = document.createElement('a');
    link.href = this.fileUrl;
    link.download = this.item.fileName;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onCopyLink($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    copyToClipboard(this.fileUrl);
    this.toastr.info(this.translateService.instant('SH.FRONTEND_LIB.FILE_LIBRARY.LINK_COPIED'));
  }
}
