export const mentionsFields = [
  'floorNo',
  'apartmentNo',
  'stairwellCode',
  'doorCode',
  'gateCode',
  'country',
  'city',
  'street',
  'postalCode',
  'wifiName',
  'wifiPassword',
  'googleMapsPin',
  'checkInFromTime',
  'checkOutUntilTime',
  'checkInToTime',
  'propertyName',
  'wwwEN',
  'wwwPL',
];

export const accessFields = ['doorCode', 'stairwellCode', 'gateCode'];

export const mentionsFieldsWithoutAccess = mentionsFields.filter(c => !accessFields.includes(c));
