import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IRoom } from '@smarttypes/hotel';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@Component({
  selector: 'ui-apartment-badge',
  templateUrl: './apartment-badge.component.html',
  styleUrls: ['./apartment-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, NgxTippyModule, TranslateModule],
})
export class ApartmentBadgeComponent {
  @Input() room?: IRoom;
  @Input() icon = false;
  @Input() toolTip = false;

  get shouldShowTooltip(): boolean {
    return (this.room?.roomNo ?? '').length > 35 && this.toolTip;
  }
}
