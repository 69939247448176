<button
  class="button"
  type="button"
  [disabled]="disabled"
  [class]="class"
  [ngClass]="[iconSize, sizeClass, typeClass, iconColor, hoverClass]"
>
  <svg-icon *ngIf="!loading" [src]="iconPath"></svg-icon>
  <ui-loader [showSpinner]="loading" size="s" color="dark"></ui-loader>
</button>
