import { animate, style, transition, trigger } from '@angular/animations';

export const enterHeightAnimation = trigger('ngIfEnterHeight', [
  transition(':enter', [
    style({
      opacity: 0,
      height: '0px',
      overflow: 'hidden',
      marginTop: '0px',
      paddingBottom: '0px',
    }),
    animate(
      `.3s cubic-bezier(.25, .8, .25, 1)`,
      style({
        overflow: 'hidden',
        opacity: 1,
        height: '*',
        marginTop: '*',
        paddingBottom: '*',
      }),
    ),
  ]),
  transition(':leave', [
    style({
      overflow: 'hidden',
      opacity: 1,
      height: '*',
      marginTop: '*',
      paddingBottom: '*',
    }),
    animate(
      `.3s cubic-bezier(.25, .8, .25, 1)`,
      style({
        opacity: 0,
        height: '0px',
        overflow: 'hidden',
        marginTop: '0px',
        paddingBottom: '0px',
      }),
    ),
  ]),
]);
