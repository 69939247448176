export function enumToSelectOptions(enumObj: object, prefix = 'SH', showAll?: string) {
  const options = Object.values(enumObj).reduce((aggr: { value: string; label: string }[], item: string) => {
    aggr.push({
      value: item,
      label: `${prefix}.${item.toUpperCase()?.replace(/-/g, '_')}`,
    });
    return aggr;
  }, [] as { value: string; label: string }[]);
  if (showAll) {
    options.unshift({
      value: '',
      label: `${prefix}.${showAll?.toUpperCase()?.replace(/-/g, '_')}`,
    });
  }
  return options;
}
