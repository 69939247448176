<button
  class="button"
  type="submit"
  [disabled]="disabled"
  [ngClass]="[sizeClass, typeClass, hasIcon, iconSize, blockType, hasBottomLabel]"
>
  <ui-loader [showSpinner]="pending" [size]="loaderSize" [color]="loaderColor"></ui-loader>
  <ui-avatar *ngIf="showAvatar" [size]="avatarSize" [type]="avatarType" [key]="avatarKey" [object]="object"></ui-avatar>
  <ng-container *ngIf="showIcon">
    <svg-icon class="icon" [class.color]="color" [src]="iconPath"></svg-icon>
  </ng-container>
  <span class="label">{{ label }}</span>
  <ng-content></ng-content>
  <!--  <span class="bottom-label" *ngIf="bottomLabel">{{bottomLabel | maxChars: 40}}</span>-->
  <svg-icon class="arrow" *ngIf="showArrow" src="/assets/icons/icons-arrow-down.svg"></svg-icon>
</button>
