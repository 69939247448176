export enum ReservationStatusEnum {
  requested = 'requested',
  confirmed = 'confirmed',
  modified = 'modified',
  checkedIn = 'checkedIn',
  checkedOut = 'checkedOut',
  cancelled = 'cancelled',
  reserved = 'reserved',
  noShows = 'noShows',
}
