import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommunicationChannelEnum } from '@smarttypes/core';
import { ComparisonTypeEnum, IMessageStats, MessageCountTabEnum } from '@smarttypes/metrics';
import { PercentageBar, PercentageBarComponent } from '@ui/common';
import { ButtonCircleComponent, ButtonPillComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { endOfMonth, startOfMonth, subDays, subMonths } from 'date-fns';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { MessagesSentByChannelItemComponent } from '../messages-sent-by-channel-item';

export interface IMessageStatsGroup {
  stats: IMessageStats[];
  channel: MessageCountTabEnum;
}

@Component({
  selector: 'ui-messages-sent-by-channel',
  templateUrl: './messages-sent-by-channel.component.html',
  styleUrls: ['./messages-sent-by-channel.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    AngularSvgIconModule,
    ButtonPillComponent,
    MessagesSentByChannelItemComponent,
    PercentageBarComponent,
    NgxSkeletonLoaderModule,
    ButtonCircleComponent,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesSentByChannelComponent implements OnChanges {
  activeChannel = MessageCountTabEnum.massMessages;
  activeChannelEnum = MessageCountTabEnum;
  _stats: IMessageStatsGroup[] | undefined = [];
  private _period?: { days: number | string; range: Date[] };
  allMessages = 0;
  percentChange: string | number = 0;
  chartData?: PercentageBar[];
  @Output() loadData = new EventEmitter<{
    period: [Date, Date, ComparisonTypeEnum | string];
  }>();
  @Output() public export = new EventEmitter<void>();
  @Input() public downloading = false;
  @ViewChildren('tdItem') public items?: QueryList<ElementRef>;
  @ViewChild(PercentageBarComponent)
  percentageBarComponent?: PercentageBarComponent;
  isLoading = true;

  @Input() period?: { days: number | string; range: Date[] } | undefined;

  /*@Input() set period(value: { days: number | string; range: Date[] } | undefined) {
    this._period = value;
    this.getData();
  }

  get period(): { days: number | string; range: Date[] } | undefined {
    return this._period || undefined;
  }*/

  @Input() set stats(value: IMessageStatsGroup[] | undefined) {
    this._stats = value;
    this.updateStats();
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  get stats(): IMessageStatsGroup[] | undefined {
    return this._stats || undefined;
  }

  get statsForActiveTab(): IMessageStats[] {
    return (
      this.stats?.find(item => item.channel === this.activeChannel)?.stats?.sort((a, b) => b.count - a.count) ?? []
    );
  }

  get isPercentChangePositive(): boolean {
    return +this.percentChange > 0;
  }

  get isPercentChangeNegative(): boolean {
    return +this.percentChange < 0;
  }

  get iconPath(): string {
    return this.isPercentChangePositive
      ? 'assets/icons/icons-arrow-up-alt.svg'
      : 'assets/icons/icons-arrow-down-alt.svg';
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['period'] && !changes['period'].isFirstChange()) {
      this._period = changes['period'].currentValue;
      if (this._period) {
        this.getData();
      }
    }
  }

  onChannelClick(activeChannel: MessageCountTabEnum): void {
    this.activeChannel = activeChannel;
    this.getData();
  }

  private getData(): void {
    this.loadData.emit({
      period: this.getPeriodDates(),
    });
    this.isLoading = true;
  }

  private getPeriodDates(): [Date, Date, ComparisonTypeEnum | string] {
    const currentDate = new Date();
    let start,
      end,
      comparisonType = '';

    if (this.period?.range) {
      [start, end] = this.period.range;
    } else if (this.period?.days === 'last_month') {
      const previousMonth = subMonths(currentDate, 1);
      start = startOfMonth(previousMonth);
      end = endOfMonth(previousMonth);
      comparisonType = ComparisonTypeEnum.lastMonth;
    } else if (this.period?.days === 'current_month') {
      start = startOfMonth(currentDate);
      end = currentDate;
      comparisonType = ComparisonTypeEnum.currentMonth;
    } else {
      start = subDays(currentDate, parseInt(this.period?.days as string));
      end = currentDate;
    }

    return [start, end, comparisonType];
  }

  private updateStats(): void {
    const comparisson = this.calculateComparissionTotalMessageCount();
    this.allMessages = this.calculateTotalMessageCount();

    this.percentChange = comparisson > 0 ? (((this.allMessages - comparisson) / comparisson) * 100).toFixed(1) : 0;
    const relevantChannels = [
      CommunicationChannelEnum.Sms,
      CommunicationChannelEnum.WhatsApp,
      CommunicationChannelEnum.Email,
    ];

    const channelSums = relevantChannels.reduce((sums, channel) => {
      const count = this.stats
        ?.find(item => item.channel === this.activeChannel)
        ?.stats.filter(stat => stat.channel === channel)
        .reduce((acc, stat) => acc + stat.count, 0);

      if (count) {
        sums.set(channel, count);
      }

      return sums;
    }, new Map<CommunicationChannelEnum, number>());

    const emailMessages = channelSums.get(CommunicationChannelEnum.Email) || 0;
    const whatsAppMessages = channelSums.get(CommunicationChannelEnum.WhatsApp) || 0;
    const smsMessages = channelSums.get(CommunicationChannelEnum.Sms) || 0;
    const sum = emailMessages + whatsAppMessages + smsMessages;

    this.updateChart([
      Math.round((emailMessages / sum) * 100),
      Math.round((whatsAppMessages / sum) * 100),
      Math.round((smsMessages / sum) * 100),
    ]);
  }

  private updateChart(percentages: [number, number, number]): void {
    this.chartData = [
      {
        channel: CommunicationChannelEnum.Email,
        percent: percentages[0] || 0,
        color: '#4d4d4d',
      },
      {
        channel: CommunicationChannelEnum.WhatsApp,
        percent: percentages[1] || 0,
        color: '#40c351',
      },
      {
        channel: CommunicationChannelEnum.Sms,
        percent: percentages[2] || 0,
        color: '#fc865b',
      },
    ];
  }

  private calculateTotalMessageCount(): number {
    return this.calculateStatsSum(item => item.count);
  }

  private calculateComparissionTotalMessageCount(): number {
    return this.calculateStatsSum(item => item.comparisonCount);
  }

  private calculateStatsSum(getCount: (item: IMessageStats) => number): number {
    if (!this.stats) return 0;

    return (
      this.stats
        .filter(item => item.channel === this.activeChannel)
        .flatMap(statsGroup => statsGroup.stats)
        .reduce((total, stats) => total + getCount(stats), 0) || 0
    );
  }

  onMouseEnter(channel: CommunicationChannelEnum): void {
    this.percentageBarComponent?.onMouseEnter(channel);
  }

  onMouseLeave(): void {
    this.percentageBarComponent?.onMouseLeave();
  }
}
