import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IMessageItem } from '@smarttypes/messages';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { ErrorMessageComponent, FormComponent } from '@ui/common/forms';
import { ConfirmModalComponent } from '@ui/common/modals';
import { FormControlDirective } from 'angular-v2-directives';
import { ApiValidatorService } from 'angular-v2-services';
import { AutosizeModule } from 'ngx-autosize';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'ui-reply-to-review',
  standalone: true,
  templateUrl: './reply-to-review.component.html',
  styleUrls: ['./reply-to-review.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReplyToReviewComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormComponent,
    ErrorMessageComponent,
    ButtonRectangleComponent,
    TranslateModule,
    FormControlDirective,
    AutosizeModule,
  ],
})
export class ReplyToReviewComponent implements OnChanges {
  @Input() public message?: IMessageItem;
  @Output() public review: EventEmitter<string> = new EventEmitter<string>();
  canSendReview = false;
  pending = false;
  form: FormGroup = new FormGroup({
    respond: new FormControl('', Validators.required),
  });

  constructor(
    private apiValidator: ApiValidatorService,
    private readonly modalService: BsModalService,
    private readonly cd: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['message']?.currentValue) {
      const message = changes['message'].currentValue as IMessageItem;
      this.canSendReview = (message?.data as { canSendReview: boolean })?.canSendReview;
    }
  }

  submit() {
    if (this.pending || !this.apiValidator.formIsValid(this.form)) {
      return;
    }
    this.pending = true;
    this.modalService.show(ConfirmModalComponent, {
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        confirmFunc: (): void => {
          this.pending = false;
          this.review.emit(this.form.get('respond')?.value ?? '');
          this.canSendReview = false;
        },
        cancelFunc: (): void => {
          this.pending = false;
          this.cd.detectChanges();
        },
        text: {
          header: this.translateService.instant('SH.CHAT.RESPOND_REVIEW.HEADER'),
          body: this.translateService.instant('SH.CHAT.RESPOND_REVIEW.BODY'),
          submit: this.translateService.instant('SH.CHAT.RESPOND_REVIEW.YES'),
          cancel: this.translateService.instant('SH.CHAT.RESPOND_REVIEW.NO'),
          btnClass: 'primary',
        },
      },
    });
  }
}
