export function convertTimeFrom24hTo12h(time24h: string): string {
  const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!regex.test(time24h)) {
    console.warn('Warning: Invalid 24-hour time format');
    return time24h;
  }

  const [hour, minute] = time24h.split(':');

  let convertedHour = parseInt(hour);
  const amPmIndicator = convertedHour >= 12 ? 'PM' : 'AM';
  convertedHour = convertedHour % 12 || 12;

  return `${convertedHour}:${minute} ${amPmIndicator}`;
}

export function generateTimeArray(interval: 15 | 30, useAMPM = false): { label: string; value: string }[] {
  const parts = interval === 15 ? ['00', '15', '30', '45'] : ['00', '30'];
  const hours = [];

  for (let i = 0; i < 24; i++) {
    for (const part of parts) {
      const formattedHour = useAMPM ? formatHourWithAMPM(i, part) : format24Hour(i, part);
      hours.push({
        label: formattedHour,
        value: format24Hour(i, part),
      });
    }
  }

  return hours;
}

function formatHourWithAMPM(hour: number, part: string): string {
  const amPm = hour < 12 ? 'AM' : 'PM';
  const hour12Format = hour % 12 || 12;
  return `${formatHour(hour12Format)}:${part} ${amPm}`;
}

function format24Hour(hour: number, part: string): string {
  return `${formatHour(hour)}:${part}`;
}

function formatHour(hour: number): string {
  return hour < 10 ? '0' + hour : hour.toString();
}
