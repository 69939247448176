import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleStrategy } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FrontendUiModule } from '@smarthotel/angular-ui';
import { PoweredByComponent } from '@ui/common';

import { ComponentsModule } from '../components/components.module';
import { MenuGroupComponent } from '../components/layout/sidebar/menu-group/menu-group.component';
import { SidebarComponent } from '../components/layout/sidebar/sidebar.component';
import { SidebarBackdropComponent } from '../components/layout/sidebar-backdrop/sidebar-backdrop.component';
import { TopBarComponent } from '../components/layout/top-bar/top-bar.component';
import { TopNavigationComponent } from '../components/layout/top-navigation/top-navigation.component';
import { PageTitleStrategy } from '../core/page-title-strategy';
import { DirectivesModule } from '../directives/directives.module';
import { VisitorsConfiguratorModule } from '../modules/visitors-configurator/visitors-configurator.module';
import { VisitorsListModule } from '../modules/visitors-list/visitors-list.module';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ViewsRoutingModule } from './views-routing.module';

@NgModule({
  declarations: [DashboardViewComponent, NotFoundComponent],
  imports: [
    CommonModule,
    FrontendUiModule,
    ViewsRoutingModule,
    ReactiveFormsModule,
    VisitorsConfiguratorModule,
    TranslateModule,
    ComponentsModule,
    PoweredByComponent,
    TopBarComponent,
    SidebarComponent,
    MenuGroupComponent,
    TopNavigationComponent,
    SidebarBackdropComponent,
    DirectivesModule,
    VisitorsListModule,
  ],
  exports: [DashboardViewComponent],
  providers: [{ provide: TitleStrategy, useClass: PageTitleStrategy }],
})
export class ViewsModule {}
