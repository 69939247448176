import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DividerComponent {
  @Input() size: 1 | 2 | 3 | 4 | 5 = 1;

  @HostBinding('class')
  get thickness(): string {
    return `thickness-${this.size}`;
  }
}
