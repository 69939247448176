import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  readonly mobileBreakpoint = '768px';

  constructor(private breakpointObserver: BreakpointObserver) {}

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched(this.breakpoint('mobile'));
  }

  get isDesktop(): boolean {
    return this.breakpointObserver.isMatched(this.breakpoint('desktop'));
  }

  breakpoint(type: 'mobile' | 'desktop') {
    if (type === 'mobile') {
      return `(max-width: ${this.mobileBreakpoint})`;
    }
    return `(min-width: ${this.mobileBreakpoint})`;
  }
}
