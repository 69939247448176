import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteStorageService {
  private readonly routeKey = 'sh-last-route';

  constructor(private router: Router) {}

  startTracking(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      localStorage.setItem(this.routeKey, event.urlAfterRedirects);
    });
  }

  getLastRoute(): string | null {
    return localStorage.getItem(this.routeKey);
  }

  navigateToLastRoute(): void {
    const lastRoute = this.getLastRoute();
    if (lastRoute) {
      this.router.navigateByUrl(lastRoute);
    }
  }
}
