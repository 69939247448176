<div class="content">
  <svg-icon src="/assets/icons/icons-addons.svg"></svg-icon>
  <h2 [innerHTML]="'SH.PAYMENTS.NO_PROVIDER' | translate"></h2>
  <p [innerHTML]="'SH.PAYMENTS.NO_PROVIDER.DESCRIPTION' | translate"></p>
  <ui-button-rectangle
    (click)="onButtonClick()"
    size="m"
    type="primary"
    *ngIf="showButton"
    label="{{ 'SH.PAYMENTS.NO_PROVIDER.BUTTON' | translate }}"
  ></ui-button-rectangle>
</div>
