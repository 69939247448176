import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { ResponsiveService } from '../core/services/responsive.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[shIsBreakpoint]',
})
export class MobileBreakpointDirective implements OnDestroy {
  private subscription = new Subscription();

  @Input('shIsBreakpoint') set size(type: 'mobile' | 'desktop') {
    this.subscription.unsubscribe();
    this.subscription = this.breakpointObserver
      .observe(this.responsiveService.breakpoint(type))
      .subscribe(this.updateView);
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private cdr: ChangeDetectorRef,
    private responsiveService: ResponsiveService,
  ) {}

  updateView = ({ matches }: BreakpointState) => {
    if (matches && !this.vcRef.length) {
      this.vcRef.createEmbeddedView(this.templateRef);
    } else if (!matches && this.vcRef.length) {
      this.vcRef.clear();
    }
    this.cdr.detectChanges();
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
