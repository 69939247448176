import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ILanguage } from '@smarttypes/core';
import { IGuest } from '@smarttypes/hotel';
import { SelectCountryComponent } from '@ui/common/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LANGUAGES } from 'angular-v2-utils';
import { get } from 'lodash';
import { of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { MyProfileComponent } from '../my-profile';

const DEFAULT_LANGUAGE = 'en';

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    TranslateModule,
    AngularSvgIconModule,
    RouterModule,
    SelectCountryComponent,
    FormsModule,
    MyProfileComponent,
    CommonModule,
  ],
})
export class HeaderComponent implements OnInit, OnChanges {
  @HostBinding('class.alternative') @Input() alt = false;
  @Input() text?: string;
  @Input() languages?: string[] = [];
  @Input() logo?: string;
  @Input() hideLogo = false;
  @Input() noLogoInfo = true;
  @Input() currentLang!: string;
  @Input() isPreview = false;
  @Input() apiUrl!: string;
  @Input() logoPlaceholderText = 'No logo';
  @Input() showMyProfile = true;
  @Input() guest?: IGuest | null = null;
  @Output() langChange = new EventEmitter();
  @Output() logoLoaded = new EventEmitter();
  @Output() logoRemoved = new EventEmitter();
  @Output() logoClick = new EventEmitter();
  @Output() profileClick = new EventEmitter();
  availableLanguages: ILanguage[] = [];
  selectedLanguage!: string;
  @HostBinding('class.homepage-version') @Input() isHomepageVersion = false;

  constructor(
    protected cd: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    of(LANGUAGES)
      .pipe(
        map(languages => languages.filter(l => this.languages?.includes(l.iso639_1))),
        tap(languages => (this.availableLanguages = languages)),
        switchMap(() => this.route.queryParams),
      )
      .subscribe(params => {
        const forcedLang = get(params, 'language', null);
        const browserLang = this.translateService.getBrowserLang() as string;
        const savedLang = localStorage.getItem('sh-language') as string;
        if (!this.isPreview) {
          if (forcedLang && this.languages?.includes(forcedLang)) {
            this.selectedLanguage = forcedLang;
          } else if (savedLang && this.languages?.includes(savedLang)) {
            this.selectedLanguage = savedLang;
          } else if (browserLang && this.languages?.includes(browserLang)) {
            this.selectedLanguage = browserLang;
          } else {
            this.selectedLanguage = DEFAULT_LANGUAGE;
          }
          this.changeLang(this.selectedLanguage);
        } else {
          this.selectedLanguage = this.currentLang;
        }
      });
  }

  onLogoLoad(): void {
    this.logoLoaded.emit();
  }

  changeLang(event: any): void {
    // TODO: REFACTOR!!
    const lng = event?.name ? event?.iso639_1 : event;
    if (lng) {
      localStorage.setItem('sh-language', lng);
      this.translateService.setDefaultLang(lng);
      this.translateService.use(lng);
      this.cd.detectChanges();
      this.langChange.emit(lng);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentLang'] && changes['currentLang'].currentValue) {
      this.selectedLanguage = changes['currentLang'].currentValue;
    }

    if (changes['logo'] && changes['logo'].currentValue === '') {
      this.logoRemoved.emit();
    }
  }
}
