import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppVersion } from '@smarttypes/core';
import { interval, Observable, Subscription, tap } from 'rxjs';
import { concatMap, delay, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { AuthService } from './auth.service';
import { NotificationBarService, NotificationIdEnum } from './notification-bar.service';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService implements OnDestroy {
  private readonly checkInterval$ = interval(90000); // 1,5min
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly notificationBarService: NotificationBarService,
    private readonly translateService: TranslateService,
  ) {
    this.subscription.add(this.initializeAppVersionChecker().subscribe());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initializeAppVersionChecker(): Observable<IAppVersion> {
    return this.authService.$loggedIn.pipe(
      delay(1000),
      switchMap(() => this.checkAppVersionAndNotify()),
      switchMap(() => this.checkInterval$.pipe(concatMap(() => this.checkAppVersionAndNotify()))),
    );
  }

  private checkAppVersionAndNotify(): Observable<IAppVersion> {
    return this.getAppVersion().pipe(
      tap(appVersion => {
        if (this.isPending(appVersion)) {
          this.notificationBarService.hideNotification(NotificationIdEnum.NewVersion);
          this.notificationBarService.addNotification({
            id: NotificationIdEnum.UpdatePending,
            header: this.translateService.instant('SH.NEW_VERSION_PENDING.TITLE'),
            description: this.translateService.instant('SH.NEW_VERSION_PENDING.DESCRIPTION'),
            icon: 'icons-warning-filled',
            hierarchy: 'low',
            type: 'warning',
          });
        } else if (this.isChanged(appVersion)) {
          this.notificationBarService.hideNotification(NotificationIdEnum.UpdatePending);
          this.notificationBarService.addNotification({
            id: NotificationIdEnum.NewVersion,
            header: this.translateService.instant('SH.NEW_VERSION_REFRESH_PAGE'),
            icon: 'icons-info-filled',
            type: 'info',
          });
        } else {
          this.notificationBarService.hideNotification(NotificationIdEnum.UpdatePending);
          this.notificationBarService.hideNotification(NotificationIdEnum.NewVersion);
        }
      }),
    );
  }

  private isChanged(result: IAppVersion): boolean {
    const currentVer = environment.release;
    if (!currentVer || currentVer.includes('COMMIT_') || this.isPending(result)) {
      return false;
    }
    return currentVer !== result.current && (result?.previous ?? []).includes(currentVer);
  }

  private isPending(result: IAppVersion): boolean {
    return !!result.pendingNext;
  }

  private getAppVersion(): Observable<IAppVersion> {
    return this.httpService.request('get', `/app-version/version`) as Observable<IAppVersion>;
  }
}
