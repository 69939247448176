<ng-container [formGroup]="form">
  <ui-form
    *ngIf="showHeader"
    label="{{ header | translate }}"
    class="master-buttons"
    [class.hours-off]="!fg.get('active')?.value"
  >
    <ui-master-buttons name="active" formControlName="active"></ui-master-buttons>
  </ui-form>
  <div class="wrapper" *ngIf="fg.get('active')?.value">
    <ui-form label="{{ 'SH.VISITORS_PLACE.IS_OPEN24H' | translate }}" [required]="active" class="master-buttons">
      <ui-master-buttons name="is24h" formControlName="is24h"></ui-master-buttons>
    </ui-form>
    <ng-container *ngIf="!fg.get('is24h')?.value">
      <div class="opening-hours">
        <div class="opening-hours-group" formGroupName="workDays">
          <ui-form label="{{ 'SH.WORKING_DAYS' | translate }}" [required]="!eachDayEnabled">
            <ui-select
              type="ng-select-input-big ng-select-100"
              formControlName="from"
              [items]="hours"
              bindLabel="label"
              bindValue="value"
              [addTag]="true"
              [searchable]="true"
              [arrow]="true"
              [clearSelected]="true"
              placeholder="{{ 'SH.FROM' | translate }}"
              [locked]="eachDayEnabled"
            ></ui-select>
            <ui-error-message [control]="fg.get('workDays')?.get('from')"></ui-error-message>
          </ui-form>
          <ui-form label="&nbsp;">
            <ui-select
              type="ng-select-input-big ng-select-100"
              formControlName="to"
              [items]="hours"
              bindLabel="label"
              bindValue="value"
              [addTag]="true"
              [searchable]="true"
              [arrow]="true"
              [clearSelected]="true"
              placeholder="{{ 'SH.TO' | translate }}"
              [locked]="eachDayEnabled"
            ></ui-select>
            <ui-error-message [control]="fg.get('workDays')?.get('to')"></ui-error-message>
          </ui-form>
        </div>
        <div class="opening-hours-group" formGroupName="weekDays">
          <ui-form label="{{ 'SH.WEEKENDS' | translate }}" [required]="!eachDayEnabled">
            <ui-select
              type="ng-select-input-big ng-select-100"
              formControlName="from"
              [items]="hours"
              bindLabel="label"
              bindValue="value"
              [addTag]="true"
              [searchable]="true"
              [arrow]="true"
              [clearSelected]="true"
              placeholder="{{ 'SH.FROM' | translate }}"
              [locked]="eachDayEnabled"
            ></ui-select>
            <ui-error-message [control]="fg.get('weekDays')?.get('from')"></ui-error-message>
          </ui-form>
          <ui-form label="&nbsp;">
            <ui-select
              type="ng-select-input-big ng-select-100"
              formControlName="to"
              [items]="hours"
              bindLabel="label"
              bindValue="value"
              [addTag]="true"
              [searchable]="true"
              [arrow]="true"
              [clearSelected]="true"
              placeholder="{{ 'SH.TO' | translate }}"
              [locked]="eachDayEnabled"
            ></ui-select>
            <ui-error-message [control]="fg.get('weekDays')?.get('to')"></ui-error-message>
          </ui-form>
        </div>
      </div>
      <ui-checkbox
        id="eachDay"
        formControlName="eachDay"
        [value]="eachDayEnabled"
        text="{{ 'SH.VISITORS_PLACE.EACH_DAY_SEPARATELY' | translate }}"
      ></ui-checkbox>
      <div class="days" *ngIf="eachDayEnabled">
        <ng-container *ngFor="let day of days">
          <div class="day" [formGroupName]="day">
            <ui-checkbox
              [value]="dayEnabled(day)"
              [id]="day"
              formControlName="isOpen"
              [text]="translateDay(day)"
            ></ui-checkbox>
            <div class="d-flex">
              <ui-form class="w-100">
                <label *ngIf="dayEnabled(day)">{{ 'SH.OPEN_FROM' | translate }}<em>*</em></label>
                <label *ngIf="!dayEnabled(day)">{{ 'SH.CLOSED' | translate }}</label>
                <ui-select
                  type="ng-select-input-big w-100"
                  [items]="hours"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="from"
                  [addTag]="true"
                  [searchable]="true"
                  [arrow]="true"
                  placeholder="{{ 'SH.FROM' | translate }}"
                  [locked]="!dayEnabled(day)"
                  [clearSelected]="true"
                ></ui-select>
                <ui-error-message [control]="fg.get(day)?.get('from')"></ui-error-message>
              </ui-form>
              <ui-form class="w-100">
                <label *ngIf="dayEnabled(day)">{{ 'SH.OPEN_TO' | translate }}<em>*</em></label>
                <label *ngIf="!dayEnabled(day)">&nbsp;</label>
                <ui-select
                  type="ng-select-input-big"
                  [items]="hours"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="to"
                  [addTag]="true"
                  [searchable]="true"
                  [arrow]="true"
                  placeholder="{{ 'SH.TO' | translate }}"
                  [locked]="!dayEnabled(day)"
                  [clearSelected]="true"
                ></ui-select>
                <ui-error-message [control]="fg.get(day)?.get('to')"></ui-error-message>
              </ui-form>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ui-form label="{{ 'SH.VISITORS_PLACE.HOURS_INFO' | translate }}" *ngIf="additionalDesc">
      <input
        type="text"
        formInput
        id="openingHoursInfo"
        name="openingHoursInfo"
        formControlName="openingHoursInfo"
        placeholder="{{ 'SH.VISITORS_PLACE.HOURS_INFO_PLACEHOLDER' | translate }}"
      />
      <ui-error-message [control]="fg.get('openingHoursInfo')"></ui-error-message>
    </ui-form>
  </div>
</ng-container>
