<h4>{{ 'SH.RESERVATIONS' | translate }}</h4>
<div
  class="arrow-up arrow"
  *ngIf="showArrows"
  [class.arrow-up-disabled]="scrollItemPosition === 0"
  (click)="onScrollClick(navEnum.top)"
>
  <span>{{ 'SH.PAST_RESERVATIONS.COMING' | translate }}</span>
  <svg-icon src="/assets/icons/icons-arrow-up.svg"></svg-icon>
</div>
<div class="line"></div>
<div class="past-content" #content>
  <div class="reservations">
    <ui-pasts-visits-vertical-item
      [showArrows]="showArrows"
      (reservationClick)="onReservationClick($event)"
      [currentReservationId]="currentReservationId"
      *ngFor="let reservation of itemsToShow; let i = index"
      [reservation]="reservation"
      [visitor]="visitor"
    ></ui-pasts-visits-vertical-item>
  </div>
</div>
<div
  class="arrow-down arrow"
  [class.arrow-down-disabled]="scrollItemPosition + 6 >= reservations.length"
  *ngIf="showArrows"
  (click)="onScrollClick(navEnum.bottom)"
>
  <span>{{ 'SH.PAST_RESERVATIONS.PAST' | translate }}</span>
  <svg-icon src="/assets/icons/icons-arrow-down.svg"></svg-icon>
</div>
