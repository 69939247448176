export interface ICdnOptions {
  width?: number;
  quality?: number;
  contrast?: number;
  brightness?: number;
  sharpen?: number;
  gamma?: number;
  format?: string;
  dpr?: number;
}

export const CDN_DEFAULT_OPTIONS: ICdnOptions = {
  width: 960,
  quality: 70,
  contrast: 1.1,
  brightness: 1.05,
  sharpen: 1,
  gamma: 1.05,
  format: 'auto',
  dpr: 3,
};

export function convertUrlToCdnCgi(
  bucket: string | undefined,
  path: string | undefined,
  url: string | undefined,
  options?: ICdnOptions,
): string {
  if (!url) {
    return '';
  }
  const opt = Object.assign({}, CDN_DEFAULT_OPTIONS, options);
  if (!bucket || !path) {
    return url.startsWith('https://') ? url : `https://${url}`;
  }
  return `https://${bucket}/cdn-cgi/image/width=${opt.width},quality=${opt.quality},contrast=${opt.contrast},brightness=${opt.brightness},sharpen=${opt.sharpen},gamma=${opt.gamma},format=${opt.format},dpr=${opt.dpr}/${path}`;
}
