import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BetaBadgeComponent } from '@ui/common';
import { ConfirmModalComponent, JoinSmartchatModalComponent } from '@ui/common/modals';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsModalService } from 'ngx-bootstrap/modal';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'sh-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    RouterLinkWithHref,
    BetaBadgeComponent,
    TranslateModule,
    RouterLinkActive,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() label!: string;
  @Input() icon!: string;
  @Input() path!: string;
  @Input() unlockRequired?: boolean;
  @Input() allowForTrialUsers?: boolean;
  @Input() disabled?: boolean;
  @Input() counter?: number | null;
  @Input() totalCounter?: number | null;
  @Input() beta?: boolean;
  @Input() warning?: boolean | null;
  @Input() canDisplay?: boolean;

  constructor(
    private readonly modalService: BsModalService,
    private readonly translateService: TranslateService,
  ) {}

  get isChat(): boolean {
    return this.path === '/livechat';
  }

  get isAutomation(): boolean {
    return this.path === '/automation-settings';
  }

  onModalUpgrade(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (environment.ui.smartAutomation?.higherPlanRequired && this.isAutomation) {
      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          canClose: false,
          coverImage: '/assets/images/unlock-automation-cover@2x.jpg',
          text: {
            header: 'SH.ENABLE_AUTOMATION_MODAL.TITLE',
            body: 'SH.ENABLE_AUTOMATION_MODAL.DESCRIPTION',
            submit: 'SH.CONTACT_WITH_SALES',
            btnClass: 'primary',
            cancel: 'SH.CANCEL',
          },
          confirmFunc: () => {
            window.location.href = `mailto:${environment.salesEmail}`;
          },
        },
        class: 'modal-dialog-medium',
      });
    } else {
      this.modalService.show(JoinSmartchatModalComponent, {
        class: 'modal-smartchat',
      });
    }
  }

  onModalTrial(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.modalService.show(ConfirmModalComponent, {
      class: 'modal-dialog-default',
      ignoreBackdropClick: true,
      initialState: {
        text: {
          header: this.translateService.instant('SH.TEMPORARILY_LOCKED'),
          body: this.translateService.instant('SH.TEMPORARILY_LOCKED.DESCRIPTION'),
          blockButtons: true,
        },
      },
    });
  }

  onItemClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
