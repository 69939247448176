import { isSameDay } from 'date-fns';

export interface ItemsByDate<T> {
  date: Date;
  items: T[];
}

export function sortItemsbyDate<T>(items: T[], dateKey = 'createdAt'): ItemsByDate<T>[] {
  let currentElement: ItemsByDate<T>;
  return items.reduce((p: any, c: any) => {
    const date = new Date(c[dateKey]);
    if (currentElement && isSameDay(currentElement.date, date)) {
      currentElement.items.push(c);
    } else {
      currentElement = {
        date,
        items: [c],
      };
      p.push(currentElement);
    }
    return p;
  }, []);
}
