import { CommonModule } from '@angular/common';
import { Component, forwardRef, HostBinding, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IMediaFile } from '@smarttypes/core';
import { unset } from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import Quill from 'quill';
import { Mention, MentionBlot } from 'quill-mention';

import { FileLibraryComponent } from '../file-library';

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });

@Component({
  selector: 'ui-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WysiwygEditorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [TranslateModule, CommonModule, FormsModule, QuillEditorComponent, ReactiveFormsModule],
})
export class WysiwygEditorComponent implements ControlValueAccessor, OnInit {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() templateTop?: string | undefined;
  @Input() templateBottom?: string | undefined;
  @Input() mentions: string[] = [];
  @ViewChild(QuillEditorComponent, { static: false })
  editor?: QuillEditorComponent;
  loaded = false;
  value = '';
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective!: FormControlDirective;
  @Input() onlyMentions = false;
  @Input() toolbar = [
    ['bold', 'underline'],
    ['library', 'link'],
  ];
  editorConfig: QuillModules = {
    toolbar: {
      container: this.toolbar,
      handlers: {
        library: () => {
          this.openMediaLibrary();
        },
      },
    },
  };
  allowedFormats: string[] = [
    'bold',
    'italic',
    'underline',
    'link',
    'header',
    'list',
    'align',
    'direction',
    'image',
    'mention',
  ];

  constructor(
    private injector: Injector,
    private modalService: BsModalService,
  ) {}

  @HostBinding('class.has-placeholders') get placeholders(): boolean {
    return !!this.templateBottom && !!this.templateTop && !this.readonly;
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  ngOnInit() {
    if (this.mentions.length > 0) {
      this.editorConfig = this.quillMentionConfig();
    }
    this.loaded = true;
  }

  registerOnTouched(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: unknown): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(msg: string): void {
    this.value = msg;
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
    this.disabled = isDisabled;
  }

  openMediaLibrary() {
    const selectionIndex = this.editor?.quillEditor?.getSelection()?.index ?? 0;
    this.modalService
      .show(FileLibraryComponent, {
        class: 'modal-file-library',
        initialState: {
          library: true,
          acceptFiles: ['image/jpg', 'image/jpeg', 'image/png'],
        },
      })
      ?.content?.action?.subscribe((file: IMediaFile) => {
        const imageUrl = file.url?.startsWith('https://') ? file.url : `https://${file.url}`;
        this.editor?.quillEditor?.insertEmbed(selectionIndex, 'image', imageUrl);

        if (this.editor?.quillEditor?.root?.innerHTML) {
          this.control?.setValue(this.editor.quillEditor.root.innerHTML);
          this.control?.updateValueAndValidity();
          this.editor?.quillEditor?.setSelection(selectionIndex + 1, 0);
        }
      });
  }

  editorInit(quill: any) {
    // Workaround for not to import entire Quill library
    quill.clipboard.addMatcher('IMG', (node: HTMLElement, delta: any) => {
      delta?.ops?.forEach((e: any, index: number) => {
        if (e?.insert?.image?.includes('base64,')) {
          unset(delta, `ops.${index}.insert.image`);
        }
      });
      return delta;
    });
  }

  private quillMentionConfig(): QuillModules {
    return {
      toolbar: {
        container: this.onlyMentions
          ? ['mention']
          : [
              ['bold', 'underline', 'mention'],
              ['library', 'link'],
            ],
        handlers: {
          library: () => {
            this.openMediaLibrary();
          },
          mention: () => {
            (this.editor?.quillEditor?.getModule('mention') as Mention).openMenu('#');
          },
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ['#'],
        // showDenotationChar: false,
        dataAttributes: ['value'],
        onSelect: (item: any, insertItem: any) => {
          insertItem(item);
          const editor = this.editor?.quillEditor;
          editor?.insertText(editor?.getLength() - 1, ' ', 'user');
        },
        source: (searchTerm: any, renderList: any) => {
          const f = this.mentions.map(str => ({ id: str, value: str }));
          if (searchTerm.length === 0) {
            renderList(f);
          } else {
            const filtered = f.filter(item => {
              return item.value.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1;
            });
            renderList(filtered, searchTerm);
          }
        },
      },
    };
  }
}
