@if (!canDisplay && unlockRequired) {
  <div class="menu-item" (click)="onModalUpgrade($event)">
    <svg-icon *ngIf="icon" src="/assets/icons/{{ icon }}.svg" class="menu-item-icon"></svg-icon>
    <span class="menu-item-text">{{ label | translate }}</span>
    <svg-icon src="/assets/icons/icons-upgrade.svg" class="menu-item-locked"></svg-icon>
  </div>
} @else if (allowForTrialUsers) {
  <div class="menu-item" (click)="onModalTrial($event)">
    <svg-icon *ngIf="icon" src="/assets/icons/{{ icon }}.svg" class="menu-item-icon"></svg-icon>
    <span class="menu-item-text">{{ label | translate }}</span>
    <svg-icon src="/assets/icons/icons-lock.svg" class="menu-item-locked"></svg-icon>
  </div>
} @else {
  <a
    routerLink="{{ path }}"
    routerLinkActive="active"
    class="menu-item"
    [attr.data-intercom-target]="path.substring(1)"
    (click)="onItemClick($event)"
  >
    <svg-icon *ngIf="icon" src="/assets/icons/{{ icon }}.svg" class="menu-item-icon"></svg-icon>
    <span class="menu-item-text">{{ label | translate }}</span>
    <ui-beta-badge *ngIf="beta"></ui-beta-badge>
    <svg-icon *ngIf="warning" src="/assets/icons/icons-warning-filled.svg" class="menu-item-icon-warning"></svg-icon>
    <span class="menu-item-total-counter" *ngIf="totalCounter">
      <span class="separator">&#183;</span><svg-icon src="/assets/icons/icons-user.svg" *ngIf="isChat"></svg-icon
    ><span>{{ totalCounter }}</span>
    </span>
    <div class="menu-item-counter" *ngIf="counter">
      <span>{{ counter }}</span>
    </div>
  </a>
}
