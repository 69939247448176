import { CommunicationChannelEnum, ICommunicationChannelId } from '@smarttypes/core';
import { get } from 'lodash';

export function hasCommunicationChannel(
  obj: unknown,
  channel: CommunicationChannelEnum,
  path = 'communication.channelIds',
): boolean {
  return !!(get(obj, path, []) as ICommunicationChannelId[]).find(c => c.channel === channel);
}
