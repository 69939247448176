<div class="messages-container">
  <header>
    <h1>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL_EXPENSES.TITLE' | translate }}</h1>
    <ui-button-circle
      size="s"
      type="quaternary"
      icon="icons-export-medium"
      [loading]="downloading"
      [disabled]="downloading"
      (click)="export.emit()"
    ></ui-button-circle>
  </header>

  <div class="summary">
    <p>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.TOTAL_EXPENSES' | translate }}</p>

    <div class="expenses-info">
      <span class="expenses"> {{ allExpenses }} {{ currency }} </span>
    </div>
  </div>
  <table *ngIf="allExpenses" class="expenses-table">
    <thead>
    <tr>
      <th>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.FEATURE' | translate }}</th>
      <th>
          <span>
            {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHANNEL.SMS' | translate }}
          </span>
        <ui-tip placement="right-end" [offset]="[0, 10]">
          {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.EXPANSES.TIP.SMS' | translate }}
        </ui-tip>
      </th>
      <th>
          <span>
            {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.CHANNEL.WHATSAPP' | translate }}
          </span>
        <ui-tip placement="right-end" [offset]="[0, 10]">
          {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.EXPANSES.TIP.WHATSAPP' | translate }}
        </ui-tip>
      </th>
      <th>{{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.TOTAL' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of stats; let i = index">
      <ui-messages-sent-by-channel-expenses-item
        [stats]="item.stats"
        [channel]="item.channel"
        [currency]="currency"
        [tooltip]="getTooltipForItem(item)"
      ></ui-messages-sent-by-channel-expenses-item>
    </tr>
    </tbody>
  </table>
  <div class="empty" *ngIf="!allExpenses && !isLoading">
    {{ 'SH.ANALYTICS_VIEW.MESSAGES_SENT_BY_CHANNEL.EMPTY' | translate }}
  </div>
</div>
