<ng-select
  class="ng-select-sh ng-select-input-big ng-select-tags"
  [class.ng-select-emails]="emails"
  [clearable]="clearable"
  [searchable]="searchable"
  [multiple]="true"
  [items]="items"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [formControl]="control"
  [groupBy]="groupBy"
  [addTag]="addNew ? addTagFn : false"
  [readonly]="readonly"
  [maxSelectedItems]="maxSelectedItems"
  #select
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <img *ngIf="item.icon" src="/assets/icons/{{ item?.icon }}.svg" class="tags-icon" [alt]="item.name ?? item" />
    <span class="ng-value-label">{{ item.label ?? item | translate }}</span>
    <span class="ng-value-clear" (click)="clear(item)" aria-hidden="true">
      <svg-icon src="/assets/icons/icons-close-small.svg" class="arrow-icon"></svg-icon>
    </span>
  </ng-template>
  <ng-container *ngIf="addNew">
    <ng-template ng-tag-tmp let-search="searchTerm">
      <span class="ng-tag-label">Add tag: {{ search }}</span>
    </ng-template>
  </ng-container>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <img *ngIf="item?.icon" src="/assets/icons/{{ item?.icon }}.svg" class="tags-icon" [alt]="item.label" />
    <div class="ng-option-label">
      <span class="text-capitalize">{{ item.label ?? item | translate }}</span>
    </div>
    <svg-icon src="/assets/icons/icons-checkmark.svg" class="ng-option-checkmark"></svg-icon>
  </ng-template>
</ng-select>
