import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxChars',
  standalone: true,
})
export class MaxCharsPipe implements PipeTransform {
  transform(value: string | undefined, limit: number): string {
    if (!value) {
      return '';
    }

    return value.substring(0, limit) + (value.length > limit ? '...' : '');
  }
}
