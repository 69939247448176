import { Injectable } from '@angular/core';

const FILES: { [key: string]: string } = {
  new_message: '/assets/audio/new_message.mp3',
  notification: '/assets/audio/notification.mp3',
};

interface AudioElement {
  name: string;
  audio: HTMLAudioElement;
}

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private files: { [key: string]: AudioElement } = {};

  constructor() {
    Object.keys(FILES).forEach(key => {
      this.files[key] = {
        name: key,
        audio: new Audio(FILES[key]),
      };
    });
  }

  play(name: string) {
    if (Object.keys(this.files).includes(name)) {
      const player = this.files[name].audio;
      player.load();
      player.addEventListener('canplaythrough', () => {
        try {
          player.play();
        } catch (err) {
          console.log(err);
          return;
        }
      });
    }
  }
}
