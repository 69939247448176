import { EventEmitter, Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, Observable, share } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum HostListenerEventType {
  scroll = 'scroll',
  resize = 'resize',
  mousewheel = 'mousewheel',
  message = 'postMessage',
}

@Injectable({
  providedIn: 'root',
})
export class GlobalHostListenerService {
  private toggleEvent: EventEmitter<{ type: HostListenerEventType; event: Event | MessageEvent }> = new EventEmitter();

  $toggleEvent: Observable<{ type: HostListenerEventType; event: Event | MessageEvent }>;
  $scroll: Observable<Event>;
  $resize: Observable<Event>;
  $mousewheel: Observable<Event>;
  $postMessage: Observable<MessageEvent>;

  constructor() {
    console.log('Starting HostListenerService');

    this.$toggleEvent = this.toggleEvent.asObservable().pipe(share());
    this.$scroll = this.toggleEvent.asObservable().pipe(
      filter(e => e.type === HostListenerEventType.scroll),
      map(e => e.event),
      share(),
    );
    this.$resize = this.toggleEvent.asObservable().pipe(
      filter(e => e.type === HostListenerEventType.resize),
      map(e => e.event),
      share(),
    );
    this.$mousewheel = this.toggleEvent.asObservable().pipe(
      filter(e => e.type === HostListenerEventType.mousewheel),
      map(e => e.event),
      share(),
    );
    this.$postMessage = this.toggleEvent.asObservable().pipe(
      filter(e => e.type === HostListenerEventType.message),
      map(e => e.event as MessageEvent),
      share(),
    );

    fromEvent(document, 'scroll')
      .pipe()
      .subscribe(event =>
        this.toggleEvent.emit({
          type: HostListenerEventType.scroll,
          event,
        }),
      );
    fromEvent(document, 'resize')
      .pipe(debounceTime(300))
      .subscribe(event =>
        this.toggleEvent.emit({
          type: HostListenerEventType.resize,
          event,
        }),
      );
    fromEvent(document, 'mousewheel')
      .pipe()
      .subscribe(event =>
        this.toggleEvent.emit({
          type: HostListenerEventType.mousewheel,
          event,
        }),
      );

    fromEvent(window, 'message')
      .pipe()
      .subscribe(event =>
        this.toggleEvent.emit({
          type: HostListenerEventType.message,
          event,
        }),
      );
  }
}
