<button class="btn-block" type="button" (click)="onButtonClick()">
  <svg-icon src="/assets/icons/icons-plus-bold.svg"></svg-icon>
  <input
    type="text"
    placeholder="{{ 'SH.VISITORS_PLACE.ADD_SECTION' | translate }}"
    [(ngModel)]="title"
    #input
    (keyup.enter)="onSave()"
  />
  <span class="action" *ngIf="titleCounter > 0">
    <p class="counter">{{ titleCounter }}/{{ maxTitleLength }}</p>
    <ui-button-circle icon="icons-checkmark-small" color="green" (click)="onSave()"></ui-button-circle>
  </span>
</button>
