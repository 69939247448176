import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ICountry } from '@smarttypes/core';
import { Observable } from 'rxjs';

import { AddRoommateItemComponent } from '../add-roommate-item';

@Component({
  selector: 'ui-add-roommate',
  templateUrl: './add-roommate.component.html',
  styleUrls: ['./add-roommate.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, AddRoommateItemComponent],
})
export class AddRoommateComponent implements OnInit {
  formArray: FormArray = new FormArray<any>([]);
  @Input() countries?: ICountry[];
  @Output() roommateChange = new EventEmitter<FormArray>();
  @Input() canAddRoommate = true;

  get hasRoommates(): boolean {
    return !!this.formArray.controls.length;
  }

  get formChanged$(): Observable<unknown[]> {
    return this.formArray.valueChanges as Observable<unknown[]>;
  }

  ngOnInit() {
    if (!this.canAddRoommate) {
      this.onAddClick();
    }
  }

  onAddClick(): void {
    this.formArray?.push(
      new FormGroup({
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl(''),
        phone: new FormControl(''),
        email: new FormControl('', [Validators.email]),
      }),
    );

    this.roommateChange.emit(this.formArray);
  }

  onDeleteClick(index: number): void {
    this.formArray?.removeAt(index);

    this.roommateChange.emit(this.formArray);
  }
}
