import { animate, style, transition, trigger } from '@angular/animations';

export const ngIfDropdownOpenTrigger = trigger('ngIfDropdownOpen', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(-3px) scale(0.98)',
      visibility: 'hidden',
    }),
    animate(
      `.3s ease`,
      style({
        transform: 'translateY(0) scale(1)',
        opacity: 1,
        visibility: 'visible',
      }),
    ),
  ]),
]);
