import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ALL_FUNCTIONALITIES } from '@smarttypes/core';
import { isArray } from 'lodash';
import { map, take } from 'rxjs';
import { filter, timeout } from 'rxjs/operators';

import { CompanyService } from '../services/company.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyModuleGuard implements CanActivate {
  constructor(
    private readonly companyService: CompanyService,
    private readonly router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const module: ALL_FUNCTIONALITIES | ALL_FUNCTIONALITIES[] = route.data['module'];
    return this.companyService.$currentUser().pipe(
      filter(e => !!e),
      take(1),
      timeout(10000),
      map(e => {
        const companyModules = Object.keys(e?.modulesV2 ?? []);
        const viewModules = isArray(module) ? module : [module];
        if (viewModules.some(m => companyModules.includes(m))) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      }),
    );
  }
}
