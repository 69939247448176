<ui-modal-base
  header="{{ 'SH.COPY_CONTENT_TO' | translate }}"
  subtitle="{{ roomIds.length }} {{ 'SH.APARTMENTS' | translate }}"
  [canClose]="false"
>
  <div content>
    <div [formGroup]="form" class="select-all">
      <ui-checkbox
        [value]="false"
        formControlName="selectAll"
        text="{{ 'SH.ALL_APARTMENTS' | translate }}"
      ></ui-checkbox>
    </div>
    <div class="rooms-list" *ngIf="objects.length > 0">
      <ui-checkbox
        *ngFor="let obj of objects"
        [value]="getRoomControlAt(obj).value ?? false"
        [formControl]="getRoomControlAt(obj)"
        [text]="obj.label"
      ></ui-checkbox>
    </div>
    <ui-button-rectangle
      size="l"
      type="primary"
      label="{{ 'SH.SAVE' | translate }}"
      (click)="onSave()"
    ></ui-button-rectangle>
  </div>
</ui-modal-base>
