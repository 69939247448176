<div [formGroup]="formGroup">
  <div class="row">
    <ui-form class="col-6" [required]="true" label="{{ 'GA.FIRST_NAME' | translate }}" [id]="fieldId('firstName')">
      <input
        type="text"
        [id]="fieldId('firstName')"
        [name]="fieldId('firstName')"
        formInput
        formControlName="firstName"
      />
      <ui-error-message prefix="GA" [control]="formGroup.get('firstName')"></ui-error-message>
    </ui-form>
    <ui-form class="col-6" label="{{ 'GA.LAST_NAME' | translate }}" [id]="fieldId('lastName')">
      <input type="text" [id]="fieldId('lastName')" [name]="fieldId('lastName')" formInput formControlName="lastName" />
      <ui-error-message prefix="GA" [control]="formGroup.get('lastName')"></ui-error-message>
    </ui-form>
  </div>
  <ui-form>
    <ui-phone-number
      *ngIf="countries"
      [countries]="countries"
      [fieldId]="fieldId('phone')"
      label="{{ 'GA.ROOM_MATE.PHONE_NUMBER' | translate }}"
      [guestArea]="true"
      formControlName="phone"
      prefix="GA"
    ></ui-phone-number>
    <ui-error-message prefix="GA" [control]="formGroup.get('phone')"></ui-error-message>
  </ui-form>
  <ui-form [required]="false" label="{{ 'GA.EMAIL' | translate }}" [id]="fieldId('email')">
    <input type="text" [id]="fieldId('email')" [name]="fieldId('email')" formInput formControlName="email" />
    <ui-error-message prefix="GA" [control]="formGroup.get('email')"></ui-error-message>
  </ui-form>
</div>
