import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuestTypeEnum } from '@smarttypes/hotel';
import { MessageDirectionEnum, MessageItemMessageTypeEnum, MessageItemStatusEnum } from '@smarttypes/messages';
import { BehaviorSubject, map, Observable, Subscription, switchMap } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';

import { AudioService } from './audio.service';
import { IChangeRegistryObj, SseEventsService } from './sse-events.service';

@Injectable({
  providedIn: 'root',
})
export class CountersService {
  private counters: Record<string, BehaviorSubject<number>> = {
    livechat: new BehaviorSubject<number>(0),
    guests: new BehaviorSubject<number>(0),
  };
  private newMessage = false;
  private _sub?: Subscription;

  constructor(
    private readonly sseEventsService: SseEventsService,
    private readonly router: Router,
    private titleService: Title,
    private translateService: TranslateService,
    private readonly audioService: AudioService,
  ) {}

  shutDown() {
    this._sub?.unsubscribe();
    this._sub = undefined;
  }

  get livechat(): Observable<number> {
    return this.counters['livechat'].asObservable();
  }

  get guests(): Observable<number> {
    return this.counters['guests'].asObservable();
  }

  initCounters(): void {
    this._sub = new Subscription();
    this.createCounter(
      this.sseEventsService.$MessageItemCreate.pipe(
        filter(c => c?.lastObject?.status !== MessageItemStatusEnum.read),
        filter(c => !c?.lastObject?.tags?.includes('smartAi')),
        filter(
          c =>
            c?.lastObject?.direction === MessageDirectionEnum.incoming ||
            !!c?.lastObject?.messageType?.includes(MessageItemMessageTypeEnum.CTA),
        ),
      ),
      'livechat',
    );
    this.createCounter(
      this.sseEventsService.$GuestCreate.pipe(filter(c => c?.lastObject?.guestType !== GuestTypeEnum.apartmentOwner)),
      'guests',
    );
  }

  private createCounter<T>(obs: Observable<IChangeRegistryObj<T>>, type: string): void {
    this._sub?.add(
      obs
        .pipe(
          filter(created => !!created?.lastObject),
          tap(() => {
            this.counters[type].next(this.counters[type].value + 1);
            if (type === 'livechat') {
              this.newMessage = true;
              this.setPageTitle();
              this.audioService.play('new_message');
            }
          }),
          switchMap(() => this.router.events),
          filter(event => event instanceof NavigationEnd),
          delay(100),
          tap(() => this.setPageTitle()),
          map(() => {
            return this.router.isActive(`/${type}`, {
              paths: 'subset',
              queryParams: 'subset',
              fragment: 'ignored',
              matrixParams: 'ignored',
            });
          }),
          filter(c => c),
          tap(() => this.counters[type].next(0)),
          filter(() => type === 'livechat'),
          tap(() => {
            this.newMessage = false;
            this.setPageTitle();
          }),
        )
        .subscribe(),
    );
  }

  private setPageTitle() {
    const title = this.newMessage ? 'SH.NEW_MESSAGE' : 'SH.CHAT.TITLE';
    this.titleService.setTitle(`SmartHotel - ${this.translateService.instant(title)}`);
  }
}
