<ng-container *ngFor="let n of [1, 2, 3, 4, 5]; let last = last">
  <svg-icon
    [id]="n"
    (click)="setRate(n)"
    [class.checked]="isFilled(n)"
    [src]="starIcon(n)"
    (mouseenter)="onMouseEnter(n)"
    (mouseleave)="onMouseLeave()"
  ></svg-icon>
</ng-container>
