import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-request-icons',
  templateUrl: './request-icons.component.html',
  styleUrls: ['./request-icons.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
})
export class RequestIconsComponent {
  @Input() type?: string;
  iconNames = [
    { iconName: 'feedback', iconPath: 'icons-negative' },
    { iconName: 'standard', iconPath: 'icons-repository' },
    { iconName: 'services', iconPath: 'icons-valet' },
    { iconName: 'activities', iconPath: 'icons-run' },
    { iconName: 'external', iconPath: 'icons-external' },
    { iconName: 'breakfast', iconPath: 'icons-breakfast' },
    { iconName: 'housekeeping', iconPath: 'icons-housekeeping' },
    { iconName: 'foodAndBeverages', iconPath: 'icons-food-beverages' },
    { iconName: 'spa', iconPath: 'icons-spa' },
    { iconName: 'maintenance', iconPath: 'icons-configuration' },
    { iconName: 'voucher', iconPath: 'icons-voucher' },
  ];
}
