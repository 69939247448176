<ng-container *ngIf="html; else default">
  <p class="error-message" @showError *ngIf="errorMessage" [innerHtml]="errorMessage"></p>
</ng-container>
<ng-template #default>
  <div class="error-container" @showError *ngIf="icon && errorMessage; else noIcon">
    <svg-icon *ngIf="icon" src="/assets/icons/icons-exclamation-filled.svg"></svg-icon>
    <p class="error-message">{{ errorMessage | translate }}</p>
  </div>
  <ng-template #noIcon>
    <p class="error-message" @showError *ngIf="errorMessage">{{ errorMessage | translate }}</p>
  </ng-template>
</ng-template>
