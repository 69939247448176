import { IRoom } from '@smarttypes/hotel';
import { get } from 'lodash';

export function getApartmentName(room: IRoom, companyName: string): string {
  const apartmentName = get(room, 'apartmentInfo.additionalInfo.propertyName') as string | undefined;

  if (apartmentName) {
    return apartmentName.trim();
  }

  return companyName;
}
