import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WindowService } from '@smarthotel/angular-services';
import { IReservation } from '@smarttypes/hotel';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { MyProfileReservationsComponent } from '../my-profile-reservations/my-profile-reservations.component';

@Component({
  selector: 'ui-my-profile-modal',
  templateUrl: './my-profile-modal.component.html',
  styleUrls: ['./my-profile-modal.component.scss'],
  imports: [CommonModule, AngularSvgIconModule, TranslateModule, ButtonRectangleComponent],
  standalone: true,
})
export class MyProfileModalComponent {
  @Input() guestName?: string;
  @Input() reservations?: IReservation[];

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (
      event.target &&
      (event.target as HTMLElement).classList.contains('modal') &&
      (event.target as HTMLElement).classList.contains('fade')
    ) {
      this.dissmisBackground();
    }
  }

  get canShowReservations(): boolean {
    return (this.reservations?.length ?? 0) > 1;
  }

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private router: Router,
    private windowService: WindowService,
  ) {}

  onButtonClick(): void {
    this.modalService.show(MyProfileModalComponent, {
      class: 'modal-dialog-slideout',
    });
  }

  onCloseClick(): void {
    setTimeout(() => {
      this.dissmisBackground();
    }, 300);

    this.bsModalRef.hide();
  }

  //TODO: USE BASE MODAL FROM GA branch instead dissmissBackground
  private dissmisBackground(): void {
    const elements = this.windowService.document?.getElementsByClassName('modal-backdrop');

    if (elements?.length) {
      elements[0].classList.add('modal-backdrop-transition');
    }
  }

  onMyReservationsClick(): void {
    this.bsModalRef.hide();
    this.modalService.show(MyProfileReservationsComponent, {
      initialState: {
        reservations: this.reservations,
      },
      class: 'modal-reservations',
    });
  }

  onAddRoommateClick(): void {
    this.onCloseClick();
    this.router.navigate(['/add-roommate']);
  }
}
