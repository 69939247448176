import moment from 'moment/moment';

enum RangeType {
  lastMonth = 'last_month',
  currentMonth = 'current_month',
}

export function getSelectRanges(
  selectedDays: string,
  fromRangeValue: string[],
  dateFormat = 'YYYY-MM-DD',
  defaultValue = 30,
  initialStartDate = '',
  initialEndDate = '',
): { startDate: string; endDate: string } {
  if (fromRangeValue) {
    return {
      startDate: moment(fromRangeValue[0]).format(dateFormat),
      endDate: moment(fromRangeValue[1]).format(dateFormat),
    };
  }

  if (!initialStartDate && !initialEndDate) {
    return {
      startDate: moment().subtract(defaultValue, 'days').format(dateFormat),
      endDate: moment().format(dateFormat),
    };
  }

  if (selectedDays === RangeType.lastMonth) {
    return {
      startDate: moment().subtract(1, 'months').startOf('month').format(dateFormat),
      endDate: moment().subtract(1, 'months').endOf('month').format(dateFormat),
    };
  }

  if (selectedDays === RangeType.currentMonth) {
    return {
      startDate: moment().startOf('months').format(dateFormat),
      endDate: moment().endOf('months').format(dateFormat),
    };
  }

  if (selectedDays !== RangeType.currentMonth && selectedDays !== RangeType.lastMonth) {
    return {
      startDate: moment().subtract(selectedDays, 'days').format(dateFormat),
      endDate: moment().format(dateFormat),
    };
  }

  return {
    startDate: '',
    endDate: '',
  };
}

export const rangeItems = [
  {
    label: 'SH.ANALYTICS_VIEW.CURRENT_MONTH',
    value: 'current_month',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST_MONTH',
    value: 'last_month',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST30_DAYS',
    value: '30',
  },
  {
    label: 'SH.ANALYTICS_VIEW.LAST90_DAYS',
    value: '90',
  },
  {
    label: 'SH.ANALYTICS_VIEW.RANGE',
    value: 'range',
  },
];
