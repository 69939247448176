<div class="review-box">
  <div class="rates">
    <p class="message" *ngIf="rating?.Message">{{ rating?.Message }}</p>
    <div class="left-rates rates-section">
      <div class="rate">
        <p>{{ 'SH.REVIEW.ACCURACY' | translate }}</p>
        <ui-review-stars
          [canSet]="false"
          [small]="true"
          [rate]="rating?.CategoryRatings?.accuracy ?? 0"
        ></ui-review-stars>
      </div>
      <div class="rate">
        <p>{{ 'SH.REVIEW.CHECK_IN' | translate }}</p>
        <ui-review-stars
          [canSet]="false"
          [small]="true"
          [rate]="rating?.CategoryRatings?.checkin ?? 0"
        ></ui-review-stars>
      </div>
      <div class="rate">
        <p>{{ 'SH.REVIEW.CLEANLINESS' | translate }}</p>
        <ui-review-stars
          [canSet]="false"
          [small]="true"
          [rate]="rating?.CategoryRatings?.cleanliness ?? 0"
        ></ui-review-stars>
      </div>
    </div>
    <div class="right-rates rates-section">
      <div class="rate">
        <p>{{ 'SH.REVIEW.COMMUNICATION' | translate }}</p>
        <ui-review-stars
          [canSet]="false"
          [small]="true"
          [rate]="rating?.CategoryRatings?.communication ?? 0"
        ></ui-review-stars>
      </div>
      <div class="rate">
        <p>{{ 'SH.REVIEW.LOCATION' | translate }}</p>
        <ui-review-stars
          [canSet]="false"
          [small]="true"
          [rate]="rating?.CategoryRatings?.location ?? 0"
        ></ui-review-stars>
      </div>
      <div class="rate">
        <p>{{ 'SH.REVIEW.VALUE' | translate }}</p>
        <ui-review-stars [canSet]="false" [small]="true" [rate]="rating?.CategoryRatings?.value ?? 0"></ui-review-stars>
      </div>
    </div>
  </div>
  <div class="info">
    <p>{{ 'SH.REVIEW.RESPOND_TO_REVIEW' | translate }}</p>
    <ui-reply-to-review *ngIf="message" [message]="message" (review)="review.emit($event)"></ui-reply-to-review>
  </div>
</div>
