<form action="" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isReviewSubmited || !isExpired">
  <div class="review-box">
    <h1>{{ 'SH.REVIEW.RATE_HEADER' | translate }}</h1>
    <ng-container formGroupName="CategoryRatings">
      <div class="rate">
        <h2>{{ 'SH.REVIEW.CLEANLINESS' | translate }}</h2>
        <p>{{ 'SH.REVIEW.CLEANLINESS_QUESTION' | translate : { guest: guestName } }}</p>
        <ui-review-stars formControlName="cleanliness"></ui-review-stars>
        <ui-error-message [control]="form.get('CategoryRatings')?.get('cleanliness')"></ui-error-message>
      </div>

      <div class="rate">
        <h2>{{ 'SH.REVIEW.COMMUNICATION' | translate }}</h2>
        <p>{{ 'SH.REVIEW.COMMUNICATION_QUESTION' | translate : { guest: guestName } }}</p>
        <ui-review-stars formControlName="communication"></ui-review-stars>
        <ui-error-message [control]="form.get('CategoryRatings')?.get('communication')"></ui-error-message>
      </div>

      <div class="rate">
        <h2>{{ 'SH.REVIEW.RESPECT_HOUSE_RULES' | translate }}</h2>
        <p>{{ 'SH.REVIEW.RESPECT_HOUSE_RULES_QUESTION' | translate : { guest: guestName } }}</p>
        <ui-review-stars formControlName="hoursRule"></ui-review-stars>
        <ui-error-message [control]="form.get('CategoryRatings')?.get('hoursRule')"></ui-error-message>
      </div>
    </ng-container>

    <ui-form>
      <label for="reviewText">{{ 'SH.REVIEW.YOUR_REVIEW' | translate }}</label>
      <textarea id="reviewText" formControlName="Review" formInput></textarea>
      <ui-error-message [control]="form.get('Review')"></ui-error-message>
    </ui-form>

    <div class="bottom-section">
      <!--      <ui-checkbox [reverse]="true" formControlName="IsReviewRecommended" id="IsReviewRecommended" text="{{ 'SH.REVIEW.DO_YOU_RECOMMEND_GUEST' |translate }}"></ui-checkbox>-->
      <ui-checkbox
        formControlName="IsReviewRecommended"
        id="IsReviewRecommended"
        text="{{ 'SH.REVIEW.DO_YOU_RECOMMEND_GUEST' | translate }}"
      ></ui-checkbox>
      <ui-button-rectangle
        type="primary"
        size="m"
        [disabled]="disabled"
        [pending]="pending"
        label="{{ 'SH.REVIEW.SAVE' | translate }}"
      ></ui-button-rectangle>
    </div>
  </div>
</form>
<div *ngIf="isExpired">
  <div class="review-box">
    The time to submit the review (14 days) has ended. You have not received the review from the guest
  </div>
</div>
