<ui-inline-toast
  [toastInput]="toastTypes.warning"
  icon="icons-warning"
  *ngIf="errors?.length"
  [labels]="errors"
></ui-inline-toast>

<sh-uploader
  [pending]="uploadInProgress"
  [file]="file"
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
></sh-uploader>

<sh-button-rectangle
  [pending]="uploadInProgress"
  [disabled]="disabled"
  size="l"
  (click)="onImportClick()"
  label="{{ 'SH.VISITORS_IMPORT.IMPORT_ANYWAY' | translate }}"
  type="primary"
></sh-button-rectangle>
