import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAccount, ICompanyRole } from '@smarttypes/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { distinctUntilChanged, forkJoin, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { WelcomeModalComponent } from '../../components/welcome-modal/welcome-modal.component';
import { HttpService } from '../http/http.service';
import { AuthService } from './auth.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private currentUser$ = new ReplaySubject<IAccount>();
  private _accountSnapshot?: IAccount;

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
    private readonly companyService: CompanyService,
    private readonly modalService: BsModalService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly toast: ToastrService,
    private readonly translateService: TranslateService,
  ) {
    this.currentUser$.subscribe(user => {
      this._accountSnapshot = user;
    });
    this.authService.$loggedIn
      .pipe(
        filter(e => e),
        switchMap(() => forkJoin([this.me(), this.companyService.$currentUser()])),
      )
      .subscribe(([user, company]) => {
        this.currentUser$.next(user);
        this.checkUser(user, company);
      });
  }

  checkUser(account: IAccount, company: ICompanyRole) {
    if ((account?.status === 'notVerified' || !account?.emailConfirmed) && company?.role !== 'service') {
      const token: string = sessionStorage.getItem('token-verify') as string;

      if (token) {
        sessionStorage.removeItem('token-verify');
        this.verifyTokenEmail(token).subscribe(
          () => {
            this.toast.success(this.translateService.instant('SH.ACCOUNT.ACCOUNT_ACTIVATED'));
            this.router.navigate(['/']);
          },
          () => {
            this.modalService.show(WelcomeModalComponent, {
              class: 'modal-default modal-welcome',
              ignoreBackdropClick: true,
              initialState: {
                account,
              },
            });
          },
        );
      } else {
        this.modalService.show(WelcomeModalComponent, {
          class: 'modal-default modal-welcome',
          ignoreBackdropClick: true,
          initialState: {
            account,
          },
        });
      }
    }
  }

  currentUserValue(): IAccount | undefined {
    return this._accountSnapshot;
  }

  $currentUser(): Observable<IAccount> {
    return this.currentUser$.asObservable().pipe(
      filter(a => !!a),
      distinctUntilChanged(),
    ) as Observable<IAccount>;
  }

  me(): Observable<IAccount> {
    return this.httpService.get<IAccount>(`/core/account/me`).pipe(
      tap(rs => {
        if (rs) {
          this.currentUser$.next(rs);
        }
      }),
    );
  }

  changePassword(dto: { current: string; password: string }): Observable<void> {
    return this.httpService.patch<void>(`/core/account/change-password`, dto);
  }

  update(dto: Partial<IAccount>): Observable<IAccount> {
    return this.httpService.patch<IAccount>(`/core/account/me`, dto).pipe(
      tap(rs => {
        if (rs) {
          this.currentUser$.next(rs);
        }
      }),
    );
  }

  resendVerificationEmail(id?: string): Observable<void> {
    return this.httpService.post<void>(`/core/account/resend-verification-email${id ? '/' + id : ''}`, {});
  }

  verifyTokenEmail(token: string): Observable<void> {
    return this.httpService.post<void>(`/core/account/verify-token-email`, {
      token,
    });
  }
}
