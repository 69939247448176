import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform<T>(value: T[], fieldName: string, order: 'asc' | 'desc' = 'desc'): T[] {
    return orderBy(value ?? [], fieldName, order);
  }
}
