import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'sh-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() file: File | undefined;
  @Input() formats = '';
  @Input() pending = false;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef | undefined;
  @ViewChild('uploader', { static: false }) uploader: ElementRef | undefined;
  @Output() fileAdded: EventEmitter<any> = new EventEmitter();
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter();

  constructor(private renderer2: Renderer2) {}

  onFileDragOver(): void {
    this.renderer2.addClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDragLeave(): void {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDragDrop(): void {
    this.renderer2.removeClass(this.uploader?.nativeElement, 'drag-over');
  }

  onFileDropped(file: File[]): void {
    this.fileAdded.emit(file[0]);
    this.file = file[0];
  }

  fileBrowseHandler(file: File[]): void {
    this.fileAdded.emit(file[0]);
    this.file = file[0];
  }

  onFileRemove(): void {
    if (!this.pending) {
      this.file = undefined;
      this.fileRemoved.emit();
    }
  }
}
