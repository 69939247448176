export function downloadFile(data: ArrayBufferLike, name: string, type = 'application/pdf'): void {
  const arr = new Uint8Array(data);
  const downloadLink = document.createElement('a');
  const b = new Blob([arr], { type });
  downloadLink.href = window.URL.createObjectURL(b);
  downloadLink.setAttribute('download', name);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink?.parentNode?.removeChild(downloadLink);
}
