export enum CompanyStatusEnum {
  preliminaryTrial = 'preliminaryTrial',
  fullTrial = 'fullTrial',
  trialEnded = 'trialEnded',
  trialProlonged = 'trialProlonged',
  active = 'active',
  paused = 'paused',
  churn = 'churn',
  deleted = 'deleted',
}
