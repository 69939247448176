import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { isString } from 'lodash';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'sh-logo-desktop',
  templateUrl: './logo-desktop.component.html',
  styleUrls: ['./logo-desktop.component.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoDesktopComponent {
  poweredBy: string | boolean = environment.poweredBy;

  get extension(): string {
    if (isString(this.poweredBy) && (this.poweredBy as string).includes('.')) {
      return '';
    }
    return '.svg';
  }
}
