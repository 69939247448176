import { CommonModule } from '@angular/common';
import { Component, forwardRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ITag } from '@smarttypes/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subject } from 'rxjs';

@Component({
  selector: 'ui-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, NgSelectModule, AngularSvgIconModule, ReactiveFormsModule, TranslateModule],
})
export class TagsSelectComponent implements ControlValueAccessor, OnInit {
  @Input() items: { value: string; label: string }[] | ITag[] = [];
  @Input() bindValue!: string;
  @Input() bindLabel!: string;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() trackByFn?: () => void;
  @Input() change?: () => void;
  @Input() loading = false;
  @Input() searchable = true;
  @Input() typeToSearchText?: string;
  @Input() typeahead!: Subject<string>;
  @Input() groupBy = '';
  @Input() placeholder?: string;
  @Input() maxSelectedItems = 3;
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() readonly = false;
  @Input() icon?: string;
  @Input() type = 'ng-select-pill';
  @Input() arrow = true;
  @Input() addNew = true;
  @Input() autoFocus = false;
  @Input() emails = false;
  @ViewChild('select', { static: true }) select?: NgSelectComponent;
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective;
  private isRegister = false;

  constructor(private injector: Injector) {}

  get control() {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get iconPath(): string {
    if (this.icon) {
      return `/assets/icons/${this.icon}.svg`;
    }
    return '';
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  ngOnInit() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.select?.open();
        this.select?.focus();
      }, 350);
    }
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.isRegister = true;
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    if (this.isRegister) {
      this.formControlDirective?.valueAccessor?.writeValue(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState?.(isDisabled);
  }

  addTagFn(name: string) {
    return name;
  }
}
