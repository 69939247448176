import { get, isString } from 'lodash';

const checkPaths = ['reservation.room.apartmentInfo', 'room.apartmentInfo', 'apartmentInfo'];
const checLastPath = {
  in: 'checkInFrom',
  out: 'checkOutFrom',
};

export function getCheckInOutTime(
  guestOrRoomOrCompany: object[],
  inOrOut: 'in' | 'out' = 'in',
  extendPaths: string[] = [],
  defaultFrom = '12:00',
  defaultTo = '11:00',
): string {
  let checkInFrom = inOrOut === 'in' ? defaultFrom : defaultTo;
  guestOrRoomOrCompany.every(obj => {
    return [...(extendPaths || []), ...checkPaths.map(c => `${c}.${checLastPath[inOrOut]}`)].every(path => {
      const value = get(obj, path);
      if (isString(value) && value.trim().length === 5) {
        checkInFrom = value;
        return false;
      }
      return true;
    });
  });

  return checkInFrom;
}
