<div class="step">
  <div class="step-progress">
    <div class="step-bullet" [ngClass]="{ 'step-bullet-active': progress > 0 }"></div>
    <div class="step-line">
      <div class="step-indicator" [style.width.%]="progress"></div>
    </div>
    <div class="step-bullet" [ngClass]="{ 'step-bullet-active': progress === 100 }"></div>
  </div>
  <div class="step-labels">
    <div *ngIf="from">
      <div class="step-label">
        <span>{{ 'GA.CHECK_IN' | translate }}</span>
      </div>
      <div class="step-title">
        <p>{{ from | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>
      </div>
    </div>
    <div class="text-right" *ngIf="to">
      <div class="step-label">
        <span>{{ 'GA.CHECK_OUT' | translate }}</span>
      </div>
      <div class="step-title">
        <p>{{ to | localizedDate : 'EEE, d MMM y': 'UTC' }}</p>
      </div>
    </div>
  </div>
</div>
