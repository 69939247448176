<ng-container [formGroup]="fg">
  <ui-form [label]="label">
    <div class="wrapper">
      <ui-select
        [items]="availableOptions"
        formControlName="type"
        type="ng-select-input-big ng-select-100 ng-select-only-icon"
        bindLabel="label"
        bindValue="value"
        [clearSelected]="false"
      ></ui-select>
      <div class="form-input form-input-prefix">{{ prefix }}</div>
      <input type="text" formInput formControlName="value" class="form-input-path" autocomplete="off" />
      <div class="actions">
        <ui-button-circle size="m" type="circle" icon="icons-trash" (click)="remove.emit(index)"></ui-button-circle>
      </div>
    </div>
    <ui-error-message [control]="fg.get('value')"></ui-error-message>
  </ui-form>
</ng-container>
