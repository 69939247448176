import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'sh-logo-mobile',
  templateUrl: './logo-mobile.component.html',
  styleUrls: ['./logo-mobile.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoMobileComponent {}
