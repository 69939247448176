import { Injectable } from '@angular/core';
import { CompanyStatusEnum, CompanyTypeEnum, ICompany, ICompanyRole, RolesEnum } from '@smarttypes/core';
import { distinctUntilChanged, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http/http.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private current$ = new ReplaySubject<ICompanyRole>();
  private companyId!: string;
  private company!: ICompanyRole;
  timezone = 'Etc/UTC';

  constructor(
    private readonly httpService: HttpService,
    private readonly authService: AuthService,
  ) {
    this.authService.$loggedIn
      .pipe(
        filter(e => e),
        switchMap(() => this.me()),
      )
      .subscribe(result => {
        this.current$.next(result);
      });
  }

  $currentUser(): Observable<ICompanyRole> {
    return this.current$.asObservable().pipe(
      filter(a => !!a),
      distinctUntilChanged(),
      tap(company => {
        this.company = company;
        this.timezone = company?.timezone ?? 'Etc/UTC';
        this.companyId = company._id as string;
      }),
    ) as Observable<ICompanyRole>;
  }

  updateCompany(company: ICompanyRole) {
    this.current$.next(company);
  }

  me(): Observable<ICompanyRole> {
    return this.httpService.get<ICompanyRole>(`/core/company/me`).pipe(
      take(1),
      tap(rs => {
        if (rs) {
          this.current$.next(rs);
        }
      }),
    );
  }

  list(): Observable<ICompanyRole[]> {
    return this.httpService.request('get', `/core/company/list`) as Observable<ICompanyRole[]>;
  }

  roles(): Observable<(string | RolesEnum)[]> {
    return this.httpService.get<(string | RolesEnum)[]>(`/core/company/roles`);
  }

  update(dto: Partial<ICompanyRole>): Observable<ICompanyRole> {
    return this.httpService
      .request('patch', `/core/company`, {
        body: dto,
      })
      .pipe(
        tap(rs => {
          if (rs) {
            this.current$.next(Object.assign(dto, rs) as ICompanyRole);
          }
        }),
      );
  }

  verifyCompanyTokens() {
    if (this.authService.tokenCompanyId !== this.companyId) {
      window.location.reload();
    }
  }

  isCompanyTrial(company: ICompany): boolean {
    return company?.status === CompanyStatusEnum.preliminaryTrial || company?.status === CompanyStatusEnum.fullTrial;
  }

  get companyType(): CompanyTypeEnum {
    if (environment?.ui?.forceApartmentType) {
      return CompanyTypeEnum.apartments;
    }
    return this.company?.type as CompanyTypeEnum;
  }
}
