<td>
  <div class="widget-name">
    <ng-container *ngIf="widgetIcon">
      <svg-icon class="widget-icon" *ngIf="!isEmoji && isSvg" [src]="widgetIcon"></svg-icon>
      <img *ngIf="!isEmoji && !isSvg" class="widget-icon-img" [src]="widgetIcon" alt="widget icon" />
      <span *ngIf="isEmoji" class="widget-icon-emoji">{{ widgetIcon }}</span>
    </ng-container>
    {{ widget?.displayName ?? '' | translate }}
  </div>
</td>
<td class="bold td-margin" [class.negative]="(widget?.change || 0) < 0" [class.positive]="(widget?.change || 0) > 0">
  <span> {{ changeIsPositive ? '+' : '' }}{{ widget?.isPeriod ? widget?.change + '%' : '-' }} </span>
</td>
<td class="bold td-margin">{{ widget?.sum }}</td>
