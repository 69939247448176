import { CommonModule } from '@angular/common';
import { Component, ElementRef, forwardRef, HostBinding, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-review-stars',
  standalone: true,
  templateUrl: './review-stars.component.html',
  styleUrls: ['./review-stars.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReviewStarsComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, AngularSvgIconModule],
})
export class ReviewStarsComponent {
  @Input() rate: number | undefined;
  @Input() canSet = true;
  @Input() @HostBinding('class.small') small = false;
  @Input() @HostBinding('class.white') white = false;
  private elements: HTMLElement[] = [];

  constructor(private elRef: ElementRef) {}

  isFilled(rate?: number) {
    return (this.rate ?? 0) >= (rate ?? -1);
  }

  starIcon(rate?: number) {
    return `/assets/icons/icons-star2${this.isFilled(rate) ? '-filled' : ''}.svg`;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_: any) => {};

  setRate(rate: number): void {
    if (this.canSet) {
      this.rate = rate;
      this.removeHover();
      this.onChange(this.rate);
    }
  }

  onMouseEnter(rate: number): void {
    if (this.rate) {
      return;
    }

    // TODO: need refactor!
    this.elements = this.elRef.nativeElement.getElementsByTagName('svg-icon');

    if (this.elements) {
      [...this.elements].forEach((item: HTMLElement) => {
        if (parseInt(item.id) <= rate) {
          item.classList.add('hover');
        }
      });
    }
  }

  onMouseLeave(): void {
    this.removeHover();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(_: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.canSet = isDisabled;
  }

  writeValue(obj: any): void {
    this.rate = obj;
  }

  private removeHover(): void {
    if (this.elements) {
      [...this.elements].forEach((item: HTMLElement) => {
        item.classList.remove('hover');
      });
    }
  }
}
