import { IMediaFile } from '../../media-library';
import { IVaWidget } from '../va-widget.interface';

export type ICustomWidgetV1Item = Partial<IVaWidget>;

export interface ICustomWidgetV1Widget {
  widgets?: ICustomWidgetV1Item[];
}

export interface ICustomWidget {
  active: boolean;
  name: string;
  icon: string;
  id: string;
  position: number;
  title: string;
  subtitle?: string;
  place?: string;
  image?: IMediaFile[];
  description?: Record<string, string>;
  files?: Record<string, IMediaFile[]>;
  is24h?: boolean;
  eachDay?: boolean;
  weekFrom?: string;
  weekTo?: string;
  weekendFrom?: string;
  weekendTo?: string;
  days?: ICustomWidgetDays;
  widgetType: ICustomWidgetType;
  hoursInfo?: Record<string, string>;
  permalink?: string;
}

export interface ICustomWidgetDays {
  monday: boolean;
  mondayFrom: string;
  mondayTo: string;
  tuesday: boolean;
  tuesdayFrom: string;
  tuesdayTo: string;
  wednesday: boolean;
  wednesdayFrom: string;
  wednesdayTo: string;
  thursday: boolean;
  thursdayFrom: string;
  thursdayTo: string;
  friday: boolean;
  fridayFrom: string;
  fridayTo: string;
  saturday: boolean;
  saturdayFrom: string;
  saturdayTo: string;
  sunday: boolean;
  sundayFrom: string;
  sundayTo: string;
}

export enum ICustomWidgetType {
  OpeningHours,
  Pricing,
  Text,
}
