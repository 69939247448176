import { Component, HostBinding, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'ui-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  imports: [AngularSvgIconModule],
  standalone: true,
})
export class CheckComponent {
  @HostBinding('class.checked') @Input() checked!: boolean;
  @HostBinding('class.disabled') @Input() disabled = false;
}
