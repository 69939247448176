<h2>{{ 'SH.VISITORS_PLACE.GET_DIRECT_ACCESS' | translate }}</h2>
<ui-inline-toast
  icon="icons-warning-copy-4"
  [toastInput]="toastTypes.warning"
  *ngIf="needPublish"
  label="{{ 'SH.VISITORS_PLACE.PUBLISH_TO_ACTIVATE_URL' | translate }}"
></ui-inline-toast>
<ui-form label="{{ 'SH.VISITORS_PLACE.URL' | translate }}" [readonly]="!canSave">
  <div class="wrapper">
    <div class="form-input form-input-prefix">{{ url }}/</div>
    <input
      type="text"
      formInput
      [formControl]="permalinkControl"
      [readonly]="!canSave"
      class="form-input form-input-number"
    />
    <div class="links">
      <ui-button-rectangle
        *ngIf="canSave"
        [disabled]="inputDisabled"
        type="primary"
        size="s"
        label="{{ 'SH.SAVE' | translate }}"
        (click)="onSave()"
      ></ui-button-rectangle>
      <ng-container *ngIf="!canSave">
        <ui-button-circle
          type="quaternary"
          icon="icons-copy"
          [iconSmall]="true"
          (click)="copyLink()"
        ></ui-button-circle>
        <ui-button-circle
          type="quaternary"
          icon="icons-external"
          [iconSmall]="true"
          (click)="openLink()"
        ></ui-button-circle>
      </ng-container>
    </div>
  </div>
  <small class="form-text text-muted" *ngIf="inputDisabled && !canSave"
  ><strong class="important">{{ 'SH.IMPORTANT' | translate }}:</strong>
    {{ 'SH.VISITORS_PLACE.YOU_CAN_SAVE_ONCE' | translate }}</small
  >
</ui-form>
<div
  class="qr-code"
  (click)="onDownloadClick()"
  (mouseover)="hovering = true"
  (mouseout)="hovering = false"
  [class.disabled]="canSave || needPublish"
>
  <svg-icon class="icon" src="/assets/icons/icons-qr.svg"></svg-icon>
  <p>{{ 'SH.VISITORS_PLACE.DOWNLOAD_QR_CODE' | translate }}</p>
  <ui-button-circle
    type="quaternary"
    [loading]="downloading"
    icon="icons-download-small"
    [hover]="hovering"
  ></ui-button-circle>
</div>
