import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { FormComponent } from '@ui/common/forms';
import { InlineToastComponent, ToastTypes } from '@ui/features';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormControlDirective } from 'angular-v2-directives';
import { copyToClipboard } from 'angular-v2-utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'wg-form-widget-permalink',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonCircleComponent,
    FormsModule,
    AngularSvgIconModule,
    FormComponent,
    FormControlDirective,
    ReactiveFormsModule,
    ButtonRectangleComponent,
    InlineToastComponent,
  ],
  templateUrl: './form-widget-permalink.component.html',
  styleUrls: ['./form-widget-permalink.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWidgetPermalinkComponent implements OnChanges, OnInit {
  @Input() widgetId?: string | unknown;
  @Input() url?: string;
  @Input() needPublish = false;
  @Input() downloading = false;
  @Output() download: EventEmitter<string> = new EventEmitter<string>();
  @Output() save: EventEmitter<string> = new EventEmitter<string>();
  hovering = false;
  permalinkControl = new FormControl('');
  saved = false;
  toastTypes = ToastTypes;

  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
  ) {}

  get canSave(): boolean {
    return !this.saved && !this.widgetId;
  }

  get inputDisabled(): boolean {
    return !this.permalinkControl.value;
  }

  ngOnInit() {
    if (this.widgetId) {
      this.saved = true;
      this.permalinkControl.patchValue(this.widgetId as string);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.downloading = changes['downloading']?.currentValue ?? false;
  }

  openLink() {
    window.open(this.link(true), '_blank');
  }

  copyLink() {
    copyToClipboard(this.link(true));
    this.toastr.info(`${this.translateService.instant('SH.COPIED')}`);
  }

  onDownloadClick() {
    if (!this.canSave && !this.needPublish) {
      this.downloading = true;
      this.download.emit(this.link());
    }
  }

  onSave() {
    if (this.permalinkControl.value) {
      this.needPublish = true;
      this.saved = true;
      this.save.emit(this.permalinkControl.value);
    }
  }

  link(full = false): string {
    if (full) {
      return `https://${this.url}/${this.widgetId}`;
    }
    return `${this.url}/${this.widgetId}`;
  }
}
