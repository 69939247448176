import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
  file?: File;
  @Input() uploaderId?: string;
  @Output() fileAdded: EventEmitter<File> = new EventEmitter<File>();
  @Output() fileRemove: EventEmitter<File> = new EventEmitter<File>();
  loading = false;

  onFileChoose(files: File[]): void {
    this.file = files[0];
    this.loading = true;
    this.fileAdded.emit(files[0]);
  }

  onButtonClick(event: MouseEvent | TouchEvent): void {
    if (this.file) {
      event.preventDefault();
      event.stopPropagation();

      this.removeFile();
    }
  }

  removeFile(): void {
    this.fileRemove.emit(this.file);
    delete this.file;
  }
}
