<ng-container *ngIf="disabled">
  <ui-inline-toast
    icon="icons-warning-copy-4"
    [toastInput]="toastTypes.warning"
    label="{{ 'SH.VISITORS_IMPORT_APARTMENT.IMPORT_APARTMENT_TIP' | translate }}"
  ></ui-inline-toast>
  <div class="template-bar" (click)="onDownloadTemplateClick()">
    <div>
      <svg-icon class="excel-icon" src="/assets/icons/icons-color-excel.svg"></svg-icon>
      <span>{{ 'SH.VISITORS_IMPORT.DOWNLOAD_TEMPLATE' | translate }}</span>
    </div>
    <svg-icon class="file-ico" src="/assets/icons/icons-download2.svg"></svg-icon>
  </div>
</ng-container>
<sh-uploader
  [pending]="uploadInProgress"
  [file]="file"
  [formats]="formats"
  (fileAdded)="onFileAdded($event)"
  (fileRemoved)="onFileRemoved()"
></sh-uploader>

<sh-button-rectangle
  [pending]="uploadInProgress"
  [disabled]="disabled || uploadInProgress"
  size="l"
  (click)="onImportClick()"
  label="{{ (!file ? 'SH.VISITORS_IMPORT.UPLOAD' : 'SH.VISITORS_IMPORT.IMPORT') | translate }}"
  type="primary"
></sh-button-rectangle>
