import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { ImportStatusEnum } from '@smarttypes/core';
import { untilDestroyed } from 'angular-v2-utils';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval, Subscription, switchMap, tap } from 'rxjs';

import { environment } from '../../../../environments/environment';
import {
  IApartmentStatusResponse,
  IStatusResponse,
  VisitorsImportService,
} from '../../../services/visitors-import.service';

export enum ImportApartmentTypeEnum {
  guests,
  apartments,
}

@Component({
  selector: 'sh-visitors-import-modal-apartment',
  templateUrl: './visitors-import-modal-apartment.component.html',
  styleUrls: ['./visitors-import-modal-apartment.component.scss'],
})
export class VisitorsImportModalApartmentComponent implements OnDestroy {
  file: File | undefined;
  importedSuccess = 0;
  updatedSuccess = 0;
  importedFailed = 0;
  removedSuccess = 0;
  addedRooms = 0;
  uploadFinished = false;
  uploadInProgress = false;
  importId = '';
  checkIntervalTime = environment?.visitorsImportConfig?.checkIntervalTime ?? 5000;
  timeoutTime = environment?.visitorsImportConfig?.timeoutTime ?? 60000;
  isImportedSuccess?: boolean;
  isImportedFinished?: boolean;
  response?: IStatusResponse & IApartmentStatusResponse;
  @Input() importType = ImportApartmentTypeEnum.guests;
  private interval$: Subscription = new Subscription();
  private timeout?: ReturnType<typeof setTimeout>;

  constructor(
    private bsModalRef: BsModalRef,
    private visitorsImportService: VisitorsImportService,
  ) {}

  get isImportGuests(): boolean {
    return this.importType === ImportApartmentTypeEnum.guests;
  }

  get fileName(): string {
    return this.file?.name || '';
  }

  get isStatusCompleted(): boolean {
    return this.response?.importStatus === ImportStatusEnum.completed;
  }

  get isStatusAway(): boolean {
    return this.response?.importStatus === ImportStatusEnum.importAnyway;
  }

  get isStatusFailed(): boolean {
    return this.response?.importStatus === ImportStatusEnum.failed;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickTarget = event.target as HTMLElement;
    const clickedOnBackdrop = clickTarget?.localName === 'modal-container';

    if (clickedOnBackdrop && !this.uploadInProgress) {
      this.bsModalRef.hide();
    }
  }

  runImportProcedure(): void {
    this.signUrl();
  }

  onFileRemoved(): void {
    delete this.file;
    this.isImportedSuccess = false;
    this.importedSuccess = 0;
    this.updatedSuccess = 0;
    this.importedFailed = 0;
    this.removedSuccess = 0;
    this.importId = '';
    this.isImportedFinished = false;
    this.uploadInProgress = false;
    this.interval$.unsubscribe();
  }

  onFileAdded(file: File): void {
    this.file = file;
  }

  onImportClick(importAnyway = false): void {
    if (importAnyway) {
      this.uploadInProgress = true;
      this.import('', true);

      return;
    }

    // @TODO: add error handling
    // @TODO: refactor whole import procedure
    this.runImportProcedure();
  }

  onCloseClick(): void {
    if (!this.uploadInProgress) {
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy() {
    this.interval$.unsubscribe();
  }

  private signUrl(): void {
    const fileName = this.file?.name;

    if (fileName) {
      this.uploadInProgress = true;

      this.visitorsImportService
        .signUrl(fileName)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this.startUpload(data.signed, data.url);
        });
    }
  }

  private startUpload(signed: string, url: string): void {
    this.visitorsImportService
      .upload(signed, this.file)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.import(url);
      });
  }

  private import(url: string, importAnyway = false): void {
    if (this.isImportGuests) {
      this.visitorsImportService
        .import(url, this.importId, importAnyway)
        .pipe(untilDestroyed(this))
        .subscribe(data => {
          this.importId = data.importId;
          this.runImportTimers();
        });

      return;
    }

    this.visitorsImportService
      .importApartments(url, this.importId, importAnyway)
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        this.importId = data.importId;
        this.runImportTimers(importAnyway);
      });
  }

  private runImportTimers(importAnyway = false): void {
    this.interval$ = interval(this.checkIntervalTime)
      .pipe(
        switchMap(() => this.visitorsImportService.status(this.importId)),
        tap(status => {
          const pending = [ImportStatusEnum.inProgrss];
          if (importAnyway) {
            pending.push(ImportStatusEnum.importAnyway);
          }
          if (
            status.importStatus === ImportStatusEnum.completed ||
            status.importStatus === ImportStatusEnum.partlyCompleted
          ) {
            this.isImportedSuccess = true;
            this.importedSuccess = status.numOfCreatedDocuments;
            this.updatedSuccess = status.numOfUpdatedDocuments;
            this.importedFailed = status.numOfUnImportedDocuments;
            this.removedSuccess = status.numOfRemovedDocuments;
            this.addedRooms = status.numOfAddedRooms as number;
            this.disableCheckStatus(status);
          } else {
            this.isImportedSuccess = false;
            if (!pending.includes(status.importStatus)) {
              this.disableCheckStatus(status);
            }
          }
        }),
      )
      .subscribe();

    this.timeout = setTimeout(() => {
      if (!this.isImportedSuccess) {
        this.bsModalRef.hide();
      }
    }, this.timeoutTime);
  }

  private disableCheckStatus(status: IStatusResponse & IApartmentStatusResponse) {
    this.interval$.unsubscribe();
    this.isImportedFinished = true;
    this.uploadInProgress = false;
    this.response = status;
    clearTimeout(this.timeout as ReturnType<typeof setTimeout>);
  }
}
