<div [ngSwitch]="attachment?.type">
  <audio controls *ngSwitchCase="attachmentsType.audio">
    <div>{{ attachment?.title }}</div>
    <source [src]="attachment?.path" />
    {{ 'Your browser does not support the audio element.' | translate }}
  </audio>

  <a target="_blank" [href]="attachment?.path | linkProtocol" *ngSwitchCase="attachmentsType.fallback">{{
    attachment?.title
  }}</a>

  <a
    class="file"
    download
    [href]="attachment?.path | linkProtocol"
    target="_blank"
    *ngSwitchCase="attachmentsType.file"
  >
    <svg-icon src="/assets/icons/icons-pdf.svg" *ngIf="isPdf; else fileIcon"></svg-icon>
    <ng-template #fileIcon>
      <svg-icon src="/assets/icons/icons-file.svg"></svg-icon>
    </ng-template>
    <p>{{ attachment?.title }}</p>
  </a>

  <a [href]="attachment?.path | linkProtocol" target="_blank" rel="nofollow" *ngSwitchCase="attachmentsType.image">
    <img [src]="attachment?.path | linkProtocol" alt="" />
  </a>

  <video controls *ngSwitchCase="attachmentsType.video">
    <div>{{ attachment?.title }}</div>
    <source [src]="attachment?.path | linkProtocol" />
    {{ 'Your browser does not support the video element.' | translate }}
  </video>

  <a target="_blank" [href]="getGoogleMapsLinkUrl(attachment?.coordinates)" *ngSwitchCase="attachmentsType.location">
    <img class="map" [src]="getGoogleMapsStaticUrl(attachment?.coordinates)" alt="" />
    <div>{{ attachment?.title }}</div>
  </a>
</div>
